export const FirebaseConfig = {
	"projectId": "twasalawasal18",
	"appId": "1:320914084391:web:69e691e4f848ec06ef7402",
	"databaseURL": "https://twasalawasal18-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "twasalawasal18.appspot.com",
	"locationId": "europe-west",
	"apiKey": "AIzaSyBX62rPnP28sZy9tCYvSxZlOPN4iujueoY",
	"authDomain": "twasalawasal18.firebaseapp.com",
	"messagingSenderId": "320914084391",
	"measurementId": "G-QTGELV6CGL"
};