import React from "react";

const privacyArStyles = {
  container: {
    direction: "rtl",
    textAlign: "right",
    overflowWrap: "break-word", // To prevent text overflow
  },
};

export default function PrivacyAr() {
  return (
    <div style={privacyArStyles.container}>
      <h1>سياسة الخصوصية</h1>
      <p>آخر تحديث: 18 يوليو 2022</p>
      <p>
        تصف سياسة الخصوصية هذه سياساتنا وإجراءاتنا املتعلقة بجمع معلوماتك
        واستخدامها والكشف عنها عند استخدامك للخدمة وتخبرك
      </p>
      <p>بحقوق&nbsp;الخصوصية الخاصة بك وكيف يحميك القانون.</p>
      <p>
        نستخدم بياناتك الشخصية لتقديم الخدمة وتحسينها. وباستخدام الخدمة، فإنك
        توافق على جمع واستخدام املعلومات وف ًقا لسياسة
      </p>
      <p>الخصوصية هذه.</p>
      <h1>التفسير والتعريفات</h1>
      <h2>التفسيرات</h2>
      <p>
        بعض الكلمات التي يتم فيها كتابة االحرف بأحرف عريضة لها معاني محددة وف
        ًقا للشروط ويجب أن يكون&nbsp;للتعريفات التالية نفس املعنى بغض
      </p>
      <p>النظر عما إذا كانت تظهر بصيغة املفرد أو الجمع:</p>
      <h2>التعريفات</h2>
      <p>ألغراض سياسة الخصوصية هذه:</p>
      <p>
        الحسابيعني حسا ًبا فري ًدا تم إنشاؤه لك للوصول&nbsp;إلى خدمتنا أو أجزاء
        من خدمتنا.
      </p>
      <p>
        الشركة(املشار إليها باسم "الشركة" أو "نحن" أو "لنا" في هذه االتفاقية)
        إلى <strong> شركةاألولىلخدمات الشحن </strong> ، مسقط، عذيبة ص. ب
      </p>
      <p>.470</p>
      <p>
        الشركةالتابعةتعني الكيان الذي يتحكم أو يتحكم فيه أو يخضع لسيطرة مشتركة
        مع أحد األطراف، بينما تعني "السيطرة" ملكية
      </p>
      <p>
        50٪&nbsp;أو&nbsp;أكثر&nbsp;من&nbsp;األسهم&nbsp;أو&nbsp;حقوق&nbsp;امللكية&nbsp;أو&nbsp;األوراق&nbsp;املالية&nbsp;األخرى&nbsp;التي&nbsp;يحق&nbsp;لها&nbsp;التصويت&nbsp;النتخاب&nbsp;أعضاء&nbsp;مجلس&nbsp;اإلدارة&nbsp;أو&nbsp;سلطة
      </p>
      <p>إدارة&nbsp;أخرى.</p>
      <p>
        التطبيقيعني البرنامج الذي توفره الشركة الذي تقوم بتنزيله على أي جهاز
        إلكتروني، ُيسمى "( <strong> توصل-أوصل </strong> .)"
      </p>
      <p>
        ملفاتتعريفاالرتباط
        &nbsp;هي&nbsp;ملفات&nbsp;صغيرة&nbsp;يتم&nbsp;وضعها&nbsp;على&nbsp;جهاز&nbsp;الكمبيوتر&nbsp;أو&nbsp;الجهاز&nbsp;املحمول&nbsp;أو&nbsp;أي&nbsp;جهاز&nbsp;آخر&nbsp;بواسطة&nbsp;موقع&nbsp;ويب،
      </p>
      <p>
        وتحتوي&nbsp;على تفاصيل محفوظات االستعراض الخاصة بك على هذا املوقع من بين
        استخداماته العديدة.
      </p>
      <p>الدولةتشير إلى: سلطنة عمان</p>
      <p>
        الجهاز
        &nbsp;يعني&nbsp;أي&nbsp;جهاز&nbsp;يمكنه&nbsp;الوصول&nbsp;إلى&nbsp;الخدمة&nbsp;مثل&nbsp;جهاز&nbsp;حاسوب&nbsp;أو&nbsp;هاتف&nbsp;محمول&nbsp;أو&nbsp;جهاز&nbsp;لوحي&nbsp;رقمي..
      </p>
      <p>البيانات الشخصيةهي أي معلومات تتعلق بفرد محدد أو يمكن التعرف عليه.</p>
      <p>
        الخدمةتشير إلى التطبيق أو املوقع اإللكتروني أو كليهما. مقدمالخدمةيعني أي
        شخص طبيعي أو اعتباري&nbsp;يقوم بمعالجة البيانات نيابة عن الشركة. يشير
        إلى شركات أو أفراد تابعين لجهات
        خارجية&nbsp;توظفهم&nbsp;الشركة&nbsp;لتسهيل&nbsp;الخدمة،&nbsp;لتقديم&nbsp;الخدمة&nbsp;نيابة&nbsp;عن&nbsp;الشركة،&nbsp;ألداء&nbsp;الخدمات&nbsp;املتعلقة&nbsp;بالخدمة&nbsp;أو&nbsp;ملساعدة&nbsp;الشركة&nbsp;في
      </p>
      <p>تحليل كيفية استخدام الخدمة.</p>
      <p>
        خدمةالوسائطاالجتماعيةالطرفالثالث
        &nbsp;تشير&nbsp;للجهات&nbsp;الخارجية&nbsp;إلى&nbsp;أي&nbsp;موقع&nbsp;ويب&nbsp;أو&nbsp;أي&nbsp;موقع&nbsp;شبكة&nbsp;اجتماعية&nbsp;يمكن&nbsp;للمستخدم
      </p>
      <p>
        من خالله تسجيل الدخول&nbsp;أو إنشاء حساب الستخدام الخدمة .بيانات
        االستخدامتشير إلى البيانات التي يتم جمعها تلقائ ًيا، إما الناتجة عن
        استخدام الخدمة أو من البنية التحتية للخدمة نفسها
      </p>
      <p>(على&nbsp;سبيل&nbsp;املثال،&nbsp;مدة&nbsp;زيارة&nbsp;الصفحة)</p>
      <p>موقع الويبإلى تواصل أوصل، ويمكن الوصول&nbsp;إليه من:</p>
      <p>https://www.twasalawasal.com</p>
      <p>
        أنتتعني الفرد الذي يصل إلى الخدمة أو يستخدمها، أو الشركة، أو الكيان
        القانوني اآلخر الذي يقوم هذا الفرد بالوصول&nbsp;إلى الخدمة
      </p>
      <p>أو استخدامها نيابة عنه، حسب االقتضاء</p>
      <h1>جمع واستخدام بياناتك الشخصية</h1>
      <h2>أنواع البيانات املجمعة</h2>
      <h3>البيانات الشخصية</h3>
      <p>
        أثناء استخدام خدمتنا، قد نطلب منك تزويدنا بمعلومات تعريف شخصية معينة
        يمكن استخدامها لالتصال بك أو التعرف عليك. وقد تتضمن
      </p>
      <p>معلومات التعريف الشخصية، على سبيل املثال ال الحصر:</p>
      <p>املوقع االلكتروني االسم األول&nbsp;واالسم االخير</p>
      <p>
        رقم&nbsp;الهاتف&nbsp;العنوان، الوالية، املقاطعة، الرمز البريدي / الرمز
        البريدي، املدينة
      </p>
      <p>بيانات االستخدام</p>
      <h3>بيانات االستخدام</h3>
      <p>يتم جمع بيانات االستخدام تلقائ ًيا عند استخدام الخدمة.</p>
      <p>
        قد&nbsp;تتضمن&nbsp;بيانات&nbsp;االستخدام&nbsp;معلومات&nbsp;مثل&nbsp;عنوان&nbsp;بروتوكول&nbsp;اإلنترنت&nbsp;الخاص&nbsp;بجهازك&nbsp;(مثل&nbsp;عنوان&nbsp;)IP،&nbsp;ونوع&nbsp;املتصفح،&nbsp;وإصدار&nbsp;املتصفح،&nbsp;وصفحات&nbsp;الخدمة&nbsp;التي&nbsp;تزورها،&nbsp;ووقت&nbsp;وتاريخ&nbsp;زيارتك،&nbsp;والوقت&nbsp;الذي&nbsp;تقضيه&nbsp;في&nbsp;تلك&nbsp;الصفحات،&nbsp;والجهاز&nbsp;الفريد&nbsp;املعرفات&nbsp;والبيانات&nbsp;التشخيصية
      </p>
      <p>األخرى.</p>
      <p>
        عندما تصل إلى الخدمة عن طريق أو من خالل جهاز محمول، يجوز لنا جمع معلومات
        معينة تلقائ ًيا، بما في ذلك، على سبيل املثال ال الحصر، نوع
        الجهاز&nbsp;املحمول&nbsp;الذي&nbsp;تستخدمه،&nbsp;واملعرف&nbsp;الفريد&nbsp;لجهازك&nbsp;املحمول،&nbsp;وعنوان&nbsp;IP&nbsp;لجهازك&nbsp;املحمول،&nbsp;وهاتفك&nbsp;املحمول&nbsp;نظام&nbsp;التشغيل،&nbsp;ونوع&nbsp;متصفح
      </p>
      <p>
        اإلنترنت على الهاتف املحمول&nbsp;الذي تستخدمه، ومعرفات الجهاز الفريدة
        وبيانات التشخيص األخرى.
      </p>
      <p>
        قد نقوم أيضا بجمع املعلومات التي يرسلها متصفحك عندما تزور خدمتنا أو
        عندما تصل إلى الخدمة عن طريق أو من خالل جهاز محمول.
      </p>
      <h3>معلومات عن الطرف الثالث من خدمات وسائل التواصل االجتماعي</h3>
      <p>
        سمحت&nbsp;لك&nbsp;الشركة&nbsp;بإنشاء&nbsp;حساب&nbsp;وتسجيل&nbsp;الدخول&nbsp;الستخدام&nbsp;الخدمة&nbsp;من&nbsp;خالل&nbsp;خدمات&nbsp;الوسائط&nbsp;االجتماعية&nbsp;التالية&nbsp;للجهات&nbsp;الخارجية:
      </p>
      <ul>
        <li>جوجل</li>
        <li>فيسبوك</li>
        <li>تويتر</li>
        <li>لنكد إن</li>
      </ul>
      <p>
        إذا قررت التسجيل من خالل خدمة وسائط اجتماعية لطرف ثالث أو منحنا حق
        الوصول&nbsp;إليها بطريقة أخرى، فيجوز لنا جمع البيانات الشخصية
        املرتبطة&nbsp;بالفعل&nbsp;بحساب&nbsp;خدمة&nbsp;الوسائط&nbsp;االجتماعية&nbsp;للجهة&nbsp;الخارجية،&nbsp;مثل&nbsp;اسمك&nbsp;وعنوان&nbsp;بريدك&nbsp;اإللكتروني&nbsp;وأنشطتك&nbsp;أو&nbsp;قائمة&nbsp;جهات&nbsp;االتصال
      </p>
      <p>الخاصة بك املرتبطة بهذا الحساب.</p>
      <p>
        قد&nbsp;يكون&nbsp;لديك&nbsp;أيضا&nbsp;خيار&nbsp;مشاركة&nbsp;معلومات&nbsp;إضافية&nbsp;مع&nbsp;الشركة&nbsp;من&nbsp;خالل&nbsp;حساب&nbsp;خدمة&nbsp;الوسائط&nbsp;االجتماعية&nbsp;للجهة&nbsp;الخارجية.&nbsp;إذا&nbsp;اخترت&nbsp;تقديم
      </p>
      <p>
        هذه املعلومات والبيانات الشخصية، أثناء التسجيل أو غير ذلك، فأنت تمنح
        الشركة اإلذن الستخدامها ومشاركتها وتخزينها بطريقة تتفق مع
      </p>
      <p>سياسة الخصوصية هذه.</p>
      <h3>ملعلومات التي تم جمعها أثناء استخدام التطبيق</h3>
      <p>
        أثناء استخدام تطبيق توصل أوصل، ومن أجل االستفادة من ميزات تطبيقنا، قد
        نجمع، بعد الحصول&nbsp;على إذن مسبق منك:
      </p>
      <p>
        معلومات بخصوص موقعك
        الصور&nbsp;واملعلومات&nbsp;األخرى&nbsp;من&nbsp;كاميرا&nbsp;جهازك&nbsp;ومكتبة&nbsp;الصور
      </p>
      <p>
        نستخدم هذه املعلومات لالستفادة من ميزات خدمتنا، ولتحسين وتخصيص خدمة
        للعمالء. قد يتم تحميل املعلومات على خوادم الشركة و / أو
      </p>
      <p>
        خادم&nbsp;مزود&nbsp;الخدمة&nbsp;أو&nbsp;قد&nbsp;يتم&nbsp;تخزينها&nbsp;ببساطة&nbsp;على&nbsp;جهازك.
      </p>
      <p>
        يمكنك&nbsp;تمكين&nbsp;أو&nbsp;تعطيل&nbsp;الوصول&nbsp;إلى&nbsp;هذه&nbsp;املعلومات&nbsp;في&nbsp;أي&nbsp;وقت،&nbsp;من&nbsp;خالل&nbsp;إعدادات&nbsp;جهازك.
      </p>
      <h3>تقنيات&nbsp;التتبع&nbsp;وملفات&nbsp;تعريف&nbsp;االرتباط</h3>
      <p>
        نحن&nbsp;نستخدم&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;وتقنيات&nbsp;التتبع&nbsp;املماثلة،&nbsp;لتتبع&nbsp;النشاط&nbsp;على&nbsp;خدمتنا&nbsp;وتخزين&nbsp;معلومات&nbsp;معينة.&nbsp;تقنيات&nbsp;التتبع&nbsp;املستخدمة:&nbsp;هي&nbsp;إشارات&nbsp;وعالمات&nbsp;ونصوص&nbsp;لجمع&nbsp;املعلومات&nbsp;وتتبعها&nbsp;ولتحسين&nbsp;خدمتنا&nbsp;وتحليلها.&nbsp;قد&nbsp;تشمل&nbsp;التقنيات&nbsp;التي&nbsp;نستخدمها
      </p>
      <p>ما يلي:</p>
      <ul>
        <li>
          <strong> ملفات تعريف اال </strong> <strong> ر </strong>{" "}
          <strong> ت </strong> <strong> ب </strong> <strong> ا </strong>{" "}
          <strong> ط </strong> <strong> أ </strong> <strong> و </strong>{" "}
          <strong> م </strong> <strong> ل </strong> <strong> ف </strong>{" "}
          <strong> ا </strong> <strong> ت </strong> <strong> ت </strong>{" "}
          <strong> ع </strong> <strong> ر </strong> <strong> ي </strong>{" "}
          <strong> ف </strong> <strong> ا </strong> <strong> ال </strong>{" "}
          <strong> ر </strong> <strong> ت </strong> <strong> ب </strong>{" "}
          <strong> ا </strong> <strong> ط </strong> <strong> ل </strong>{" "}
          <strong> ل </strong> <strong> م </strong> <strong> ت </strong>{" "}
          <strong> ص </strong> <strong> ف </strong> <strong> ح </strong>{" "}
          .&nbsp;ملف&nbsp;تعريف&nbsp;االرتباط&nbsp;هو&nbsp;ملف&nbsp;صغير&nbsp;يوضع&nbsp;على&nbsp;جهازك.&nbsp;يمكنك&nbsp;توجيه
        </li>
      </ul>
      <p>
        متصفحك&nbsp;لرفض&nbsp;جميع&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;أو&nbsp;لإلشارة&nbsp;إلى&nbsp;وقت&nbsp;إرسال&nbsp;ملف&nbsp;تعريف&nbsp;االرتباط.&nbsp;ومع&nbsp;ذلك،&nbsp;إذا&nbsp;كنت&nbsp;ال&nbsp;تقبل&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط،&nbsp;فقد&nbsp;ال&nbsp;تتمكن&nbsp;من&nbsp;استخدام&nbsp;بعض&nbsp;أجزاء&nbsp;خدمتنا.&nbsp;وما&nbsp;لم&nbsp;تقم&nbsp;بتعديل&nbsp;إعدادات&nbsp;املستعرض&nbsp;الخاص&nbsp;بك&nbsp;الذي&nbsp;تم
      </p>
      <p>
        ضبطه&nbsp;مسبقا&nbsp;لرفض&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط،&nbsp;وخدمتنا&nbsp;تستخدم&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط{" "}
        <strong> . </strong>
      </p>
      <ul>
        <li>
          <strong> ملفات تعريف اال </strong> <strong> ر </strong>{" "}
          <strong> ت </strong> <strong> ب </strong> <strong> ا </strong>{" "}
          <strong> ط </strong> <strong> ف </strong> <strong> ا </strong>{" "}
          <strong> ل </strong> <strong> ش </strong>{" "}
          .&nbsp;قد&nbsp;تستخدم&nbsp;بعض&nbsp;ميزات&nbsp;خدمتنا&nbsp;كائنات&nbsp;محلية&nbsp;مخزنة&nbsp;أو&nbsp;ملفات&nbsp;تعريف&nbsp;ارتباط{" "}
          <strong> فال </strong> <strong> ش </strong> &nbsp;لجمع&nbsp;وتخزين
        </li>
      </ul>
      <p>
        معلومات&nbsp;حول&nbsp;تفضيالتك&nbsp;أو&nbsp;نشاطك&nbsp;السابقة&nbsp;على&nbsp;خدمتنا.&nbsp;ال&nbsp;تتم&nbsp;إدارة&nbsp;ملفات&nbsp;تعريف&nbsp;ارتباط&nbsp;الفالش&nbsp;بواسطة&nbsp;نفس&nbsp;إعدادات
      </p>
      <p>
        املتصفح&nbsp;مثل&nbsp;تلك&nbsp;املستخدمة&nbsp;مللفات&nbsp;تعريف&nbsp;االرتباط&nbsp;الخاصة&nbsp;باملتصفح.&nbsp;ملزيد&nbsp;من&nbsp;املعلومات&nbsp;حول&nbsp;كيفية&nbsp;حذف&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;فالش،&nbsp;يرجى&nbsp;قراءة&nbsp;"أين&nbsp;يمكنني&nbsp;تغيير&nbsp;اإلعدادات&nbsp;لتعطيل&nbsp;أو&nbsp;حذف&nbsp;الكائنات&nbsp;املشتركة&nbsp;املحلية؟"&nbsp;متاح&nbsp;على
      </p>
      <h3>
        https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-
      </h3>
      <p>
        flash.html#main_Where_can_I_change_the_settings_for_disabled
        or_deleting_local_shared_objects_
      </p>
      <ul>
        <li>
          <strong> إشا </strong> <strong> ر </strong> <strong> ا </strong>{" "}
          <strong> ت </strong> <strong> ا </strong> <strong> ل </strong>{" "}
          <strong> و </strong> <strong> ي </strong> <strong> ب </strong>{" "}
          .&nbsp;قد&nbsp;تحتوي&nbsp;أقسام&nbsp;معينة&nbsp;من&nbsp;خدمتنا&nbsp;ورسائل&nbsp;البريد&nbsp;اإللكتروني&nbsp;الخاصة&nbsp;بنا&nbsp;على&nbsp;ملفات&nbsp;إلكترونية&nbsp;صغيرة&nbsp;ُتعرف&nbsp;باسم
        </li>
      </ul>
      <p>
        إشارات&nbsp;الويب <strong> ( </strong>{" "}
        &nbsp;يشار&nbsp;إليها&nbsp;أيضا&nbsp;باسم&nbsp;(جيف)&nbsp;الواضحة&nbsp;أو&nbsp;عالمات&nbsp;البيكسل&nbsp;أو&nbsp;صور&nbsp;(جيف)&nbsp;أحادية&nbsp;البيكسل{" "}
        <strong> ) </strong> التي&nbsp;تسمح&nbsp;للشركة،
      </p>
      <p>
        على&nbsp;سبيل&nbsp;املثال،&nbsp;بإحصاء&nbsp;املستخدمين&nbsp;الذين&nbsp;زاروا&nbsp;تلك&nbsp;الصفحات&nbsp;أو&nbsp;فتح&nbsp;بري&nbsp;ًدا&nbsp;إلكترون&nbsp;ًيا&nbsp;وإلحصائيات&nbsp;مواقع&nbsp;الويب&nbsp;األخرى&nbsp;ذات
      </p>
      <p>
        الصلة (على سبيل املثال، تسجيل شعبية قسم معين والتحقق من سالمة النظام
        والخادم <strong> . </strong> )
      </p>
      <p>
        يمكن&nbsp;أن&nbsp;تكون&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;"ثابتة"&nbsp;أو&nbsp;"لحظية."&nbsp;تظل&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;الدائمة&nbsp;على&nbsp;جهاز&nbsp;الحاسوب&nbsp;الشخص&nbsp;ي&nbsp;أو&nbsp;الجهاز&nbsp;املحمول&nbsp;الخاص&nbsp;بك&nbsp;عندما&nbsp;تكون&nbsp;غير&nbsp;متصل&nbsp;باإلنترنت،&nbsp;بينما&nbsp;يتم&nbsp;حذف&nbsp;ملفات&nbsp;تعريف&nbsp;ارتباط&nbsp;اللحظية&nbsp;بمجرد&nbsp;إغالق&nbsp;متصفح&nbsp;الويب&nbsp;الخاص&nbsp;بك.&nbsp;تعرف&nbsp;على
      </p>
      <p>
        املزيد&nbsp;حول&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;على&nbsp;مقالة&nbsp;موقع&nbsp;سياسات&nbsp;الخصوصية&nbsp;على&nbsp;الويب.
      </p>
      <p>
        نستخدم&nbsp;ك&nbsp;ًال&nbsp;من&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;الخاصة&nbsp;اللحظية&nbsp;وملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;الدائمة&nbsp;لألغراض&nbsp;املوضحة&nbsp;أدناه:
      </p>
      <h3>ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;الضرورية&nbsp;/&nbsp;األساسية</h3>
      <p>النوع:&nbsp;ملفات&nbsp;تعريف&nbsp;ارتباط&nbsp;اللحظية</p>
      <p>بإدارة:&nbsp;توصل&nbsp;أوصل</p>
      <p>
        الغرض:&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;هذه&nbsp;ضرورية&nbsp;لتزويدك&nbsp;بالخدمات&nbsp;املتاحة&nbsp;من&nbsp;خالل&nbsp;املوقع&nbsp;ولتمكينك&nbsp;من&nbsp;استخدام&nbsp;بعض&nbsp;ميزاته.&nbsp;وتساعد&nbsp;في&nbsp;مصادقة&nbsp;املستخدمين&nbsp;ومنع&nbsp;االستخدام&nbsp;االحتيالي&nbsp;لحسابات&nbsp;املستخدمين.&nbsp;بدون&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;هذه،&nbsp;ال&nbsp;يمكن&nbsp;توفير
      </p>
      <p>
        الخدمات&nbsp;التي&nbsp;طلبتها،&nbsp;ونحن&nbsp;نستخدم&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;هذه&nbsp;فقط&nbsp;لتزويدك&nbsp;بهذه&nbsp;الخدمات.
      </p>
      <h3>
        سياسة&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;/&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;بقبول&nbsp;اإلشعار
      </h3>
      <p>النوع:&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;الدائمة</p>
      <p>بإدارة:&nbsp;توصل&nbsp;أوصل</p>
      <p>
        الغرض:&nbsp;تحدد&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;هذه&nbsp;ما&nbsp;إذا&nbsp;كان&nbsp;املستخدمون&nbsp;قد&nbsp;وافقوا&nbsp;على&nbsp;استخدام&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;على&nbsp;موقع&nbsp;الويب.
      </p>
      <h3>ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;الوظيفية</h3>
      <p>النوع:&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;الدائمة</p>
      <p>
        بإدارة:&nbsp;توصل&nbsp;أوصل&nbsp;لغرض:&nbsp;تسمح&nbsp;لنا&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;هذه&nbsp;بتذكر&nbsp;االختيارات&nbsp;التي&nbsp;تقوم&nbsp;بها&nbsp;عند&nbsp;استخدام&nbsp;موقع&nbsp;الويب،&nbsp;مثل&nbsp;تذكر&nbsp;تفاصيل&nbsp;تسجيل&nbsp;الدخول&nbsp;أو&nbsp;تفضيالت&nbsp;اللغة.&nbsp;الغرض&nbsp;من&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;هذه&nbsp;هو&nbsp;تزويدك&nbsp;بتجربة&nbsp;شخصية&nbsp;أكثر&nbsp;وتجنب&nbsp;االضطرار&nbsp;إلى&nbsp;إعادة
      </p>
      <p>
        إدخال تفضيالتك في كل مرة تستخدم فيها املوقع.{" "}
        <strong> ملفات تعريف االرتباطللتتبعواألداء </strong>{" "}
        النوع:&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;الدائمة
      </p>
      <p>
        بإدارة:&nbsp;طرف&nbsp;ثالث&nbsp;الغرض:&nbsp;ُتستخدم&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;هذه&nbsp;لتتبع&nbsp;املعلومات&nbsp;حول&nbsp;حركة&nbsp;املرور&nbsp;إلى&nbsp;موقع&nbsp;الويب&nbsp;وكيفية&nbsp;استخدام&nbsp;املستخدمين
      </p>
      <p>
        للموقع.&nbsp;قد&nbsp;تحددك&nbsp;املعلومات&nbsp;التي&nbsp;يتم&nbsp;جمعها&nbsp;عبر&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;هذه&nbsp;بشكل&nbsp;مباشر&nbsp;أو&nbsp;غير&nbsp;مباشر&nbsp;كزائر&nbsp;فردي.&nbsp;وذلك&nbsp;ألن&nbsp;املعلومات
        التي يتم جمعها عادة ما تكون&nbsp;مرتبطة بمعرف مستعار مرتبط بالجهاز الذي
        تستخدمه للوصول&nbsp;إلى موقع الويب. وقد
      </p>
      <p>
        ضا الختبار صفحات أو ميزات جديدة أو وظائف جديدة ملوقع الويب ملعرفة كيفية
        تفاعل
      </p>
      <p>نستخدم&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;هذه&nbsp;أي</p>
      <p>مستخدمينا معها.</p>
      <p>
        ملزيد&nbsp;من&nbsp;املعلومات&nbsp;حول&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;التي&nbsp;نستخدمها&nbsp;وخياراتك&nbsp;فيما&nbsp;يتعلق&nbsp;بملفات&nbsp;تعريف&nbsp;االرتباط،&nbsp;يرجى&nbsp;زيارة&nbsp;سياسة&nbsp;ملفات&nbsp;تعريف
      </p>
      <p>
        االرتباط&nbsp;أو&nbsp;قسم&nbsp;ملفات&nbsp;تعريف&nbsp;االرتباط&nbsp;في&nbsp;سياسة&nbsp;الخصوصية&nbsp;الخاصة&nbsp;بنا.
      </p>
      <h2>استخدام بياناتك الشخصية</h2>
      <p>يجوز للشركة استخدام البيانات الشخصية لألغراض التالية:</p>
      <p>
        لتوفيروصيانةالخدمة ، بما في ذلك مراقبة االستخدام <strong> . </strong>
      </p>
      <p>
        إلدارةحسابك:
        &nbsp;إلدارة&nbsp;تسجيلك&nbsp;كمستخدم&nbsp;للخدمة.&nbsp;يمكن&nbsp;أن&nbsp;تمنحك&nbsp;البيانات&nbsp;الشخصية&nbsp;التي&nbsp;تقدمها&nbsp;إمكانية&nbsp;الوصول&nbsp;إلى&nbsp;الوظائف
      </p>
      <p>املختلفة للخدمة املتاحة لك كمستخدم مسجل.</p>
      <p>
        لتنفيذالعقد:التطوير واالمتثال والتعهد بعقد الخدمات التي اشتريتها أو أي
        عقد آخر معنا من خالل الخدمة .لالتصالبك:لالتصال بك عن طريق البريد
        اإللكتروني، أو املكاملات الهاتفية، أو الرسائل النصية القصيرة، أو غيرها
        من أشكال
        االتصاالت&nbsp;اإللكترونية&nbsp;املماثلة،&nbsp;مثل&nbsp;اإلخطارات&nbsp;الفورية&nbsp;لتطبيقات&nbsp;الهاتف&nbsp;املحمول&nbsp;فيما&nbsp;يتعلق&nbsp;بالتحديثات&nbsp;أو&nbsp;االتصاالت&nbsp;اإلعالمية
      </p>
      <p>
        املتعلقة&nbsp;بالوظائف&nbsp;أو&nbsp;املنتجات&nbsp;أو&nbsp;الخدمات&nbsp;املتعاقد&nbsp;عليها،&nbsp;بما&nbsp;في&nbsp;ذلك&nbsp;التحديثات&nbsp;األمنية،&nbsp;عند&nbsp;الضرورة&nbsp;أو&nbsp;املعقول&nbsp;لتنفيذها
        &nbsp;{" "}
        <strong>
          {" "}
          لتزويدكباألخباروالعروضالخاصةواملعلوماتالعامةحول&nbsp;السلع والخدمات
          والتحديثات واألحداث األخرى&nbsp;التي نقدمها والتي
        </strong>
      </p>
      <p>
        تشبه تلك التي اشتريتها بالفعل أو استفسرت عنها ما لم تكن قد اخترت عدم
        تلقي هذه املعلومات <strong> . </strong>
      </p>
      <p>إدارةحجوزاتك: &nbsp;وإدارة&nbsp;الحجوزات&nbsp;ومراقبتها</p>
      <p>
        بالنسبةلعمليات نقل األعمال:قد نستخدم معلوماتك لتقييم، أو إجراء عملية
        اندماج، أو تصفية، أو إعادة هيكلة، أو إعادة
      </p>
      <p>
        تنظيم، أو حل، أو بيع، أو نقل آخر لبعض، أو كل أصولنا، سواء كان ذلك كمنشأة
        مستمرة أو كجزء من اإلفالس أو التصفية، أو
      </p>
      <p>
        إجراء مشابه، حيث تكون&nbsp;البيانات الشخصية التي نحتفظ بها عن مستخدمي
        خدمتنا من بين األصول&nbsp;املنقولة .ألغراضأخرى:قد نستخدم معلوماتك ألغراض
        أخرى، مثل تحليل البيانات، وتحديد اتجاهات االستخدام، وتحديد فعالية
        حمالتنا
      </p>
      <p>
        الترويجية ولتقييم وتحسين خدماتنا، وتسويقنا، وتجربتك <strong> . </strong>
      </p>
      <p>
        قد&nbsp;نشارك&nbsp;معلوماتك&nbsp;الشخصية&nbsp;في&nbsp;املواقف&nbsp;التالية:
      </p>
      <ul>
        <li>
          <strong> مع مزودي الخدمة </strong> <strong> : </strong>{" "}
          &nbsp;قد&nbsp;نشارك&nbsp;معلوماتك&nbsp;الشخصية&nbsp;مع&nbsp;مزودي&nbsp;الخدمة&nbsp;ملراقبة&nbsp;وتحليل&nbsp;استخدام&nbsp;خدمتنا،&nbsp;ملعالجة&nbsp;الدفع،&nbsp;لالتصال
        </li>
      </ul>
      <p>
        بك <strong> . </strong>
      </p>
      <ul>
        <li>
          <strong> لنقل األعمال </strong> <strong> : </strong> يجوز لنا مشاركة
          معلوماتك الشخصية أو نقلها فيما يتعلق أو أثناء املفاوضات بشأن أي
          اندماج، أو بيع أصول
        </li>
      </ul>
      <p>
        الشركة، أو تمويل، أو االستحواذ على كل أو جزء من أعمالنا إلى شركة أخرى{" "}
        <strong> . </strong>
      </p>
      <ul>
        <li>
          <strong> مع الشركات التابعة </strong> <strong> : </strong>{" "}
          &nbsp;قد&nbsp;نشارك&nbsp;معلوماتك&nbsp;مع&nbsp;الشركات&nbsp;التابعة&nbsp;لنا،&nbsp;وفي&nbsp;هذه&nbsp;الحالة&nbsp;سنطلب&nbsp;من&nbsp;تلك&nbsp;الشركات&nbsp;التابعة&nbsp;احترام&nbsp;سياسة
        </li>
      </ul>
      <p>
        الخصوصية&nbsp;هذه.&nbsp;تشمل&nbsp;الشركات&nbsp;التابعة&nbsp;شركتنا&nbsp;األم&nbsp;وأي&nbsp;شركات&nbsp;فرعية&nbsp;أخرى&nbsp;أو&nbsp;شركاء&nbsp;في&nbsp;املشاريع&nbsp;املشتركة&nbsp;أو&nbsp;شركات&nbsp;أخرى
      </p>
      <p>
        نسيطر عليها أو تخضع لسيطرة مشتركة معنا <strong> . </strong>
      </p>
      <ul>
        <li>
          <strong> مع شركاء األعمال </strong> <strong> : </strong>{" "}
          &nbsp;قد&nbsp;نشارك&nbsp;معلوماتك&nbsp;مع&nbsp;شركائنا&nbsp;في&nbsp;العمل&nbsp;لنقدم&nbsp;لك&nbsp;منتجات&nbsp;أو&nbsp;خدمات&nbsp;أو&nbsp;عروض&nbsp;ترويجية&nbsp;معينة{" "}
          <strong> . </strong>
        </li>
      </ul>
      <ul>
        <li>
          <strong> مع مستخدمين آخرين </strong>{" "}
          :&nbsp;عندما&nbsp;تشارك&nbsp;معلومات&nbsp;شخصية&nbsp;أو&nbsp;تتفاعل&nbsp;بطريقة&nbsp;أخرى&nbsp;في&nbsp;املناطق&nbsp;العامة&nbsp;مع&nbsp;مستخدمين&nbsp;آخرين،&nbsp;فقد&nbsp;يتم
        </li>
      </ul>
      <p>
        عرض هذه املعلومات من قبل جميع املستخدمين ويمكن توزيعها عل ًنا في الخارج.
        إذا تفاعلت مع مستخدمين آخرين أو قمت بالتسجيل من خالل خدمة وسائط اجتماعية
        لطرف ثالث، فقد ترى&nbsp;جهات االتصال الخاصة بك على خدمة الوسائط
        االجتماعية التابعة لجهة
        خارجية&nbsp;اسمك&nbsp;وملفك&nbsp;التعريفي&nbsp;وصورك&nbsp;ووصف&nbsp;نشاطك.&nbsp;وباملثل،&nbsp;سيتمكن&nbsp;املستخدمون&nbsp;اآلخرون&nbsp;من&nbsp;عرض&nbsp;أوصاف&nbsp;نشاطك
      </p>
      <p>
        والتواصل معك وعرض ملفك الشخص ي <strong> . </strong>
      </p>
      <ul>
        <li>
          <strong> بمو افقتك </strong> <strong> : </strong> يجوز لنا الكشف عن
          معلوماتك الشخصية ألي غرض آخر بموافقتك <strong> . </strong>
        </li>
      </ul>
      <h2>االحتفاظ ببياناتك الشخصية</h2>
      <p>
        ستحتفظ&nbsp;الشركة&nbsp;ببياناتك&nbsp;الشخصية&nbsp;فقط&nbsp;طاملا&nbsp;كان&nbsp;ذلك&nbsp;ضرورًيا&nbsp;لألغراض&nbsp;املنصوص&nbsp;عليها&nbsp;في&nbsp;سياسة&nbsp;الخصوصية&nbsp;هذه.&nbsp;سنحتفظ&nbsp;ببياناتك&nbsp;الشخصية&nbsp;ونستخدمها&nbsp;بالقدر&nbsp;الالزم&nbsp;لالمتثال&nbsp;اللتزاماتنا&nbsp;القانونية&nbsp;(على&nbsp;سبيل&nbsp;املثال،&nbsp;إذا&nbsp;كنا&nbsp;مطالبين&nbsp;باالحتفاظ&nbsp;ببياناتك&nbsp;لالمتثال&nbsp;للقوانين
      </p>
      <p>
        املعمول&nbsp;بها)، وحل النزاعات، وفرض اتفاقياتنا وسياساتنا القانونية.
      </p>
      <p>
        ستحتفظ&nbsp;الشركة&nbsp;أيضا&nbsp;ببيانات&nbsp;االستخدام&nbsp;ألغراض&nbsp;التحليل&nbsp;الداخلي.&nbsp;يتم&nbsp;االحتفاظ&nbsp;ببيانات&nbsp;االستخدام&nbsp;عمو&nbsp;ًما&nbsp;لفترة&nbsp;زمنية&nbsp;أقصر،&nbsp;إال&nbsp;في&nbsp;حالة
      </p>
      <p>
        استخدام&nbsp;هذه&nbsp;البيانات&nbsp;لتعزيز&nbsp;األمان&nbsp;أو&nbsp;لتحسين&nbsp;وظائف&nbsp;خدمتنا،&nbsp;أو&nbsp;نحن&nbsp;ملزمون&nbsp;قانوًنا&nbsp;باالحتفاظ&nbsp;بهذه&nbsp;البيانات&nbsp;لفترات&nbsp;زمنية&nbsp;أطول.
      </p>
      <h2>نقل بياناتك الشخصية</h2>
      <p>
        تتم معالجة معلوماتك، بما في ذلك البيانات الشخصية، في مكاتب تشغيل الشركة
        وفي أي أماكن أخرى&nbsp;حيث توجد األطراف املشاركة في املعالجة. وهذا يعني
        أنه قد يتم نقل هذه املعلومات إلى - واالحتفاظ بها - على أجهزة الحاسوب
        املوجودة خارج واليتك، أو مقاطعتك، أو بلدك، أو أي والية
      </p>
      <p>
        قضائية حكومية أخرى&nbsp;حيث قد تختلف قوانين حماية البيانات عن تلك
        املوجودة في واليتك القضائية.
      </p>
      <p>
        موافقتك على سياسة الخصوصية هذه متبوعة بتقديمك لهذه املعلومات يمثل
        موافقتك على هذا النقل.
        ستتخذ&nbsp;الشركة&nbsp;جميع&nbsp;الخطوات&nbsp;الالزمة&nbsp;بشكل&nbsp;معقول&nbsp;لضمان&nbsp;التعامل&nbsp;مع&nbsp;بياناتك&nbsp;بشكل&nbsp;آمن&nbsp;ووف&nbsp;ًقا&nbsp;لسياسة&nbsp;الخصوصية&nbsp;هذه&nbsp;ولن&nbsp;يتم&nbsp;نقل
      </p>
      <p>
        بياناتك الشخصية إلى منظمة أو دولة ما لم تكن هناك ضوابط كافية في مكانها
        بما في ذلك أمن بياناتك ومعلوماتك الشخصية األخرى.
      </p>
      <h2>اإلفصاح عن بياناتك الشخصية</h2>
      <h3>املعامالت&nbsp;التجارية</h3>
      <p>
        إذا&nbsp;كانت&nbsp;الشركة&nbsp;متورطة&nbsp;في&nbsp;عملية&nbsp;دمج&nbsp;أو&nbsp;استحواذ&nbsp;أو&nbsp;بيع&nbsp;أصول،&nbsp;فقد&nbsp;يتم&nbsp;نقل&nbsp;بياناتك&nbsp;الشخصية.&nbsp;سنقدم&nbsp;إشعا&nbsp;ًرا&nbsp;قبل&nbsp;نقل&nbsp;بياناتك&nbsp;الشخصية
      </p>
      <p>وإخضاعها لسياسة خصوصية مختلفة.</p>
      <h3>تطبيق القانون</h3>
      <p>طلب منها ذلك بموجب القانون&nbsp;أو استجابة لطلبات صحيحة من</p>
      <p>في ظل ظروف معينة، قد ُيطلب من الشركة الكشف عن بياناتك الشخصية إذا</p>
      <p>قبل السلطات العامة (على سبيل املثال، محكمة أو وكالة حكومية.)</p>
      <h3>املتطلبات القانونية األخرى</h3>
      <p>
        قد تفصح الشركة عن بياناتك الشخصية بحسن نية أن هذا اإلجراء ضروري&nbsp;من
        أجل:
      </p>
      <ul>
        <li>المتثال اللتزام قانوني</li>
        <li>حماية والدفاع عن حقوقأو ممتلكات الشركة</li>
        <li>منع أو التحقيق في أي مخالفات محتملة تتعلق بالخدمة</li>
        <li>حماية السالمة الشخصية ملستخدمي الخدمة أو الجمهور</li>
        <li>الحماية من املسؤولية القانونية</li>
      </ul>
      <h2>أمن بياناتك الشخصية</h2>
      <p>
        يعد أمان بياناتك الشخصية أم ًرا مه ًما بالنسبة لنا، ولكن تذكر أنه ال
        توجد طريقة نقل عبر اإلنترنت أو طريقة تخزين إلكتروني آمنة بنسبة 100٪.
      </p>
      <p>
        بينما&nbsp;نسعى&nbsp;جاهدين&nbsp;الستخدام&nbsp;وسائل&nbsp;مقبولة&nbsp;تجارًيا&nbsp;لحماية&nbsp;بياناتك&nbsp;الشخصية،&nbsp;ال&nbsp;يمكننا&nbsp;ضمان&nbsp;أمنها&nbsp;املطلق.
      </p>
      <h1>معلومات مفصلة عن معالجة بيانتك الشخصية</h1>
      <p>
        قد&nbsp;يتمكن&nbsp;مقدمو&nbsp;الخدمة&nbsp;الذين&nbsp;نستخدمهم&nbsp;من&nbsp;الوصول&nbsp;إلى&nbsp;بياناتك&nbsp;الشخصية.&nbsp;يقوم&nbsp;بائعو&nbsp;الجهات&nbsp;الخارجية&nbsp;بجمع&nbsp;وتخزين&nbsp;واستخدام&nbsp;ومعالجة
      </p>
      <p>
        ونقل املعلومات حول&nbsp;نشاطك على خدمتنا وف ًقا لسياسات الخصوصية الخاصة
        بهم.
      </p>
      <h2>تحليالت</h2>
      <p>
        قد&nbsp;نستخدم&nbsp;مزودي&nbsp;خدمات&nbsp;تابعين&nbsp;لجهات&nbsp;خارجية&nbsp;وطرف&nbsp;ثالث&nbsp;ملراقبة&nbsp;وتحليل&nbsp;استخدام&nbsp;خدمتنا.
      </p>
      <h3>تحليالت جوجل</h3>
      <p>
        تحليالت جوجل هي خدمة تحليالت الويب التي تقدمها جوجل والتي تتعقب حركة
        مرور موقع الويب وتبلغ عنها. تستخدم جوجل البيانات التي تم جمعها لتتبع
        ومراقبة استخدام خدمتنا. تتم مشاركة هذه البيانات مع خدمات جوجل األخرى. قد
        تستخدم جوجل
      </p>
      <p>
        البيانات التي تم جمعها لتهيئة إعالنات شبكة اإلعالنات الخاصة بها
        وتخصيصها. يمكنك إلغاء االشتراك في جعل نشاطك على الخدمة متا ًحا لبرنامج
        تحليالت جوجل عن طريق تثبيت وظيفة املتصفح اإلضافية
      </p>
      <p>
        لتعطيل التتبع في تحليالت جوجل .تمنع الوظيفة اإلضافية تحليالت جوجل
        dc.js)&nbsp;(ga.js&nbsp;JavaScript&nbsp;و&nbsp;analytics.js&nbsp;و&nbsp;من&nbsp;مشاركة&nbsp;املعلومات&nbsp;مع&nbsp;تحليالت&nbsp;جوجل&nbsp;حول&nbsp;نشاط&nbsp;الزيارات.&nbsp;يمكنك&nbsp;إلغاء&nbsp;االشتراك&nbsp;في&nbsp;بعض&nbsp;ميزات&nbsp;تحليالت&nbsp;جوجل&nbsp;من&nbsp;خالل&nbsp;إعدادات&nbsp;جهازك&nbsp;املحمول،&nbsp;مثل&nbsp;إعدادات&nbsp;اإلعالن&nbsp;على&nbsp;جهازك&nbsp;أو&nbsp;باتباع&nbsp;اإلرشادات&nbsp;املقدمة&nbsp;من
      </p>
      <p>
        جوجل في سياسة الخصوصية الخاصة بهاhttps://policies.google.com/privacy :
      </p>
      <p>
        ملزيد&nbsp;من&nbsp;املعلومات&nbsp;حول&nbsp;ممارسات&nbsp;الخصوصية&nbsp;الخاصة&nbsp;بـ&nbsp;جوجل،&nbsp;يرجى&nbsp;زيارة&nbsp;صفحة&nbsp;الويب&nbsp;الخاصة&nbsp;بخصوصية&nbsp;وسياسة&nbsp;جوجل:
      </p>
      <p>https://policies.google.com/privacy</p>
      <h2>املدفوعات</h2>
      <p>
        قد&nbsp;نقدم&nbsp;منتجات&nbsp;و&nbsp;/&nbsp;أو&nbsp;خدمات&nbsp;مدفوعة&nbsp;ضمن&nbsp;الخدمة.&nbsp;في&nbsp;هذه&nbsp;الحالة،&nbsp;قد&nbsp;نستخدم&nbsp;خدمات&nbsp;الجهات&nbsp;الخارجية&nbsp;ملعالجة&nbsp;الدفع&nbsp;(مثل&nbsp;معالجات
      </p>
      <p>الدفع.)</p>
      <p>
        لن&nbsp;نقوم&nbsp;بتخزين&nbsp;أو&nbsp;جمع&nbsp;تفاصيل&nbsp;بطاقة&nbsp;الدفع&nbsp;الخاصة&nbsp;بك.&nbsp;يتم&nbsp;تقديم&nbsp;هذه&nbsp;املعلومات&nbsp;مباشرًة&nbsp;إلى&nbsp;معالجي&nbsp;الدفع&nbsp;التابعين&nbsp;لجهات&nbsp;خارجية&nbsp;والذين&nbsp;يخضع
        استخدامهم ملعلوماتك الشخصية لسياسة الخصوصية الخاصة بهم. تلتزم معالجات
        الدفع هذه باملعايير التي حددتها PCI-DSS كما يديرها
        مجلس&nbsp;معايير&nbsp;أمانPCI&nbsp;،&nbsp;وهو&nbsp;جهد&nbsp;مشترك&nbsp;لعالمات&nbsp;تجارية&nbsp;مثل&nbsp;فيزا&nbsp;وماستركارد&nbsp;وتساعد&nbsp;متطلبات&nbsp;PCI-DSS&nbsp;على&nbsp;ضمان&nbsp;املعالجة&nbsp;اآلمنة&nbsp;ملعلومات
      </p>
      <p>الدفع</p>
      <p>
        مدفوعاتمتجرأبلداخلالتطبيقيمكن االطالع على سياسة الخصوصية الخاصة بهم على
        https://www.apple.com/legal/privacy/en-ww/
      </p>
      <p>
        مدفوعاتمتجرجوجلبالييمكن االطالع على سياسة الخصوصية الخاصة بهم على
        https://www.google.com/policies/privacy/
      </p>
      <p>
        بايباليمكن االطالع على سياسة الخصوصية الخاصة بهم على
        https://www.paypal.com/webapps/mpp/ua/privacy-full
      </p>
      <h1>خصوصية االطفال</h1>
      <p>
        ال تخاطب خدمتنا أي شخص يقل عمره عن 13 عا ًما، فنحن ال نجمع عن قصد
        معلومات تعريف شخصية من أي شخص يقل عمره عن 13 عا ًما. إذا كنت أحد
        الوالدين أو وص ًيا وأنت تعلم أن طفلك قد زودنا ببيانات شخصية، من فضلك
        اتصل بنا. إذا علمنا أننا قد جمعنا بيانات شخصية
      </p>
      <p>
        من&nbsp;أي&nbsp;شخص&nbsp;يقل&nbsp;عمره&nbsp;عن&nbsp;13&nbsp;عا&nbsp;ًما&nbsp;دون&nbsp;التحقق&nbsp;من&nbsp;موافقة&nbsp;الوالدين،&nbsp;فإننا&nbsp;نتخذ&nbsp;خطوات&nbsp;إلزالة&nbsp;هذه&nbsp;املعلومات&nbsp;من&nbsp;خوادمنا..
      </p>
      <p>
        إذا احتجنا إلى االعتماد على املوافقة كأساس قانوني ملعالجة معلوماتك وطلب
        بلدك موافقة أحد الوالدين، فقد نطلب موافقة والديك قبل أن
      </p>
      <p>نجمع هذه املعلومات ونستخدمها.</p>
      <h1>روابط املواقع االخرى</h1>
      <p>
        قد&nbsp;تحتوي&nbsp;خدمتنا&nbsp;على&nbsp;روابط&nbsp;ملواقع&nbsp;أخرى&nbsp;ال&nbsp;نقوم&nbsp;بتشغيلها.&nbsp;إذا&nbsp;قمت&nbsp;بالنقر&nbsp;فوق&nbsp;ارتباط&nbsp;جهة&nbsp;خارجية،&nbsp;فسيتم&nbsp;توجيهك&nbsp;إلى&nbsp;موقع&nbsp;الطرف&nbsp;الثالث.
      </p>
      <p>
        ننصحك&nbsp;بشدة&nbsp;بمراجعة&nbsp;سياسة&nbsp;الخصوصية&nbsp;لكل&nbsp;موقع&nbsp;تزوره.
      </p>
      <p>
        ليس&nbsp;لدينا&nbsp;أي&nbsp;سيطرة&nbsp;وال&nbsp;نتحمل&nbsp;أي&nbsp;مسؤولية&nbsp;عن&nbsp;املحتوى&nbsp;أو&nbsp;سياسات&nbsp;الخصوصية&nbsp;أو&nbsp;املمارسات&nbsp;الخاصة&nbsp;بأي&nbsp;مواقع&nbsp;أو&nbsp;خدمات&nbsp;تابعة&nbsp;لجهات
      </p>
      <p>خارجية.</p>
      <h1>التغيرات على سياسة الخصوصية</h1>
      <p>
        قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت آلخر. سنخطرك بأي تغييرات
        عن طريق نشر سياسة الخصوصية الجديدة على هذه
      </p>
      <p>الصفحة.</p>
      <p>
        سنخبرك&nbsp;عبر&nbsp;البريد&nbsp;اإللكتروني&nbsp;و&nbsp;/&nbsp;أو&nbsp;إشعا&nbsp;ًرا&nbsp;بار&nbsp;ًزا&nbsp;على&nbsp;"خدمتنا"&nbsp;،&nbsp;قبل&nbsp;أن&nbsp;يصبح&nbsp;التغيير&nbsp;سارًيا&nbsp;وتحديث&nbsp;تاريخ&nbsp;"آخر&nbsp;تحديث"&nbsp;في&nbsp;الجزء&nbsp;العلوي&nbsp;من
      </p>
      <p>سياسة الخصوصية هذه.</p>
      <p>
        ُينصح بمراجعة سياسة الخصوصية هذه بشكل دوري ملعرفة أي تغييرات.
        تسري&nbsp;التغييرات التي تطرأ على سياسة الخصوصية هذه عند نشرها
      </p>
      <p>على هذه الصفحة.</p>
      <h1>االتصال بنا</h1>
      <p>
        إذا كان لديك أي أسئلة حول&nbsp;سياسة الخصوصية هذه، يمكنك االتصال بنا:
      </p>
      <ul>
        <li>على البريد االلكتروني للشركة: info@twasalawasal.com</li>
      </ul>
      <br />
      <br />
      <br />
    </div>
  );
}
