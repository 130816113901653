
import React from "react";

export default function TermsAr() {
  return (
    <div>
<p>سياسة الخصوصية</p>
<p>آخر تحديث: ٦ مايو ٢٠٢٣</p>
<p>تصف سياسة الخصوصية هذه سياساتنا وإجراءاتنا المتعلقة بجمع معلوماتك واستخدامها والكشف عنها عند استخدامك للخدمة وتخبرك بحقوق الخصوصية الخاصة بك وكيف يحميك القانون.</p>
<p>نستخدم بياناتك الشخصية لتقديم الخدمة وتحسينها. وباستخدام الخدمة، فإنك توافق على جمع واستخدام المعلومات وفقًا لسياسة الخصوصية هذه.</p>
<h1></h1>
<h1>التفسير والتعريفات</h1>
<h1>التفسيرات</h1>
<p>بعض الكلمات التي يتم فيها كتابة الاحرف بأحرف عريضة لها معاني محددة وفقًا للشروط ويجب أن يكون للتعريفات التالية نفس المعنى بغض النظر عما إذا كانت تظهر بصيغة المفرد أو الجمع:</p>
<h1>التعريفات</h1>
<p>لأغراض سياسة الخصوصية هذه:</p>
<p>الحساب يعني حسابًا فريدًا تم إنشاؤه لك للوصول إلى خدمتنا أو أجزاء من خدمتنا.</p>
<p>الشركة (المشار إليها باسم &quot;الشركة&quot; أو &quot;نحن&quot; أو &quot;لنا&quot; في هذه الاتفاقية) إلى شركة الأولى لخدمات الشحن، مسقط، عذيبة ص. ب 470.</p>
<p>الشركة التابعة تعني الكيان الذي يتحكم أو يتحكم فيه أو يخضع لسيطرة مشتركة مع أحد الأطراف، بينما تعني &quot;السيطرة&quot; ملكية 50٪ أو أكثر من الأسهم أو حقوق الملكية أو الأوراق المالية الأخرى التي يحق لها التصويت لانتخاب أعضاء مجلس الإدارة أو سلطة إدارة أخرى.</p>
<p>التطبيق يعني البرنامج الذي توفره الشركة الذي تقوم بتنزيله على أي جهاز إلكتروني، يُسمى (&quot;توصل- أوصل&quot;).</p>
<p>ملفات تعريف الارتباط هي ملفات صغيرة يتم وضعها على جهاز الكمبيوتر أو الجهاز المحمول أو أي جهاز آخر بواسطة موقع ويب، وتحتوي على تفاصيل محفوظات الاستعراض الخاصة بك على هذا الموقع من بين استخداماته العديدة.</p>
<p>الدولة تشير إلى: سلطنة عمان</p>
<p>الجهاز يعني أي جهاز يمكنه الوصول إلى الخدمة مثل جهاز حاسوب أو هاتف محمول أو جهاز لوحي رقمي..</p>
<p>البيانات الشخصية هي أي معلومات تتعلق بفرد محدد أو يمكن التعرف عليه.</p>
<p>الخدمة تشير إلى التطبيق أو الموقع الإلكتروني أو كليهما.</p>
<p>مقدم الخدمة يعني أي شخص طبيعي أو اعتباري يقوم بمعالجة البيانات نيابة عن الشركة. يشير إلى شركات أو أفراد تابعين لجهات خارجية توظفهم الشركة لتسهيل الخدمة، لتقديم الخدمة نيابة عن الشركة، لأداء الخدمات المتعلقة بالخدمة أو لمساعدة الشركة في تحليل كيفية استخدام الخدمة.</p>
<p>خدمة الوسائط الاجتماعية الطرف الثالث تشير للجهات الخارجية إلى أي موقع ويب أو أي موقع شبكة اجتماعية يمكن للمستخدم من خلاله تسجيل الدخول أو إنشاء حساب لاستخدام الخدمة.</p>
<p>بيانات الاستخدام تشير إلى البيانات التي يتم جمعها تلقائيًا، إما الناتجة عن استخدام الخدمة أو من البنية التحتية للخدمة نفسها (على سبيل المثال، مدة زيارة الصفحة).</p>
<p>موقع الويب إلى تواصل أوصل، ويمكن الوصول إليه من:</p>
<h1></h1>
<p>أنت تعني الفرد الذي يصل إلى الخدمة أو يستخدمها، أو الشركة، أو الكيان القانوني الآخر الذي يقوم هذا الفرد بالوصول إلى الخدمة أو استخدامها نيابة عنه، حسب الاقتضاء.</p>
<h1></h1>
<p>جمع واستخدام بياناتك الشخصية</p>
<p>أنواع البيانات المجمعة</p>
<p>البيانات الشخصية</p>
<p>أثناء استخدام خدمتنا، قد نطلب منك تزويدنا بمعلومات تعريف شخصية معينة يمكن استخدامها للاتصال بك أو التعرف عليك. وقد تتضمن معلومات التعريف الشخصية، على سبيل المثال لا الحصر:</p>
<p>الموقع الالكتروني</p>
<p>الاسم الأول والاسم الاخير</p>
<p>رقم الهاتف</p>
<p>العنوان، الولاية، المقاطعة، الرمز البريدي / الرمز البريدي، المدينة</p>
<p>بيانات الاستخدام</p>
<p>بيانات الاستخدام</p>
<p>يتم جمع بيانات الاستخدام تلقائيًا عند استخدام الخدمة.</p>
<p>قد تتضمن بيانات الاستخدام معلومات مثل عنوان بروتوكول الإنترنت الخاص بجهازك (مثل عنوان IP)، ونوع المتصفح، وإصدار المتصفح، وصفحات الخدمة التي تزورها، ووقت وتاريخ زيارتك، والوقت الذي تقضيه في تلك الصفحات، والجهاز الفريد المعرفات والبيانات التشخيصية الأخرى.</p>
<p>عندما تصل إلى الخدمة عن طريق أو من خلال جهاز محمول، يجوز لنا جمع معلومات معينة تلقائيًا، بما في ذلك، على سبيل المثال لا الحصر، نوع الجهاز المحمول الذي تستخدمه، والمعرف الفريد لجهازك المحمول، وعنوان IP لجهازك المحمول، وهاتفك المحمول نظام التشغيل، ونوع متصفح الإنترنت على الهاتف المحمول الذي تستخدمه، ومعرفات الجهاز الفريدة وبيانات التشخيص الأخرى.</p>
<p>قد نقوم أيضًا بجمع المعلومات التي يرسلها متصفحك عندما تزور خدمتنا أو عندما تصل إلى الخدمة عن طريق أو من خلال جهاز محمول.</p>
<h1></h1>
<p>معلومات عن الطرف الثالث من خدمات وسائل التواصل الاجتماعي</p>
<p>سمحت لك الشركة بإنشاء حساب وتسجيل الدخول لاستخدام الخدمة من خلال خدمات الوسائط الاجتماعية التالية للجهات الخارجية:</p>
<p>جوجل</p>
<p>فيسبوك</p>
<p>تويتر</p>
<p>لنكد إن</p>
<p>واتس آب</p>
<p>إذا قررت التسجيل من خلال خدمة وسائط اجتماعية لطرف ثالث أو منحنا حق الوصول إليها بطريقة أخرى، فيجوز لنا جمع البيانات الشخصية المرتبطة بالفعل بحساب خدمة الوسائط الاجتماعية للجهة الخارجية، مثل اسمك وعنوان بريدك الإلكتروني وأنشطتك أو قائمة جهات الاتصال الخاصة بك المرتبطة بهذا الحساب.</p>
<p>قد يكون لديك أيضًا خيار مشاركة معلومات إضافية مع الشركة من خلال حساب خدمة الوسائط الاجتماعية للجهة الخارجية. إذا اخترت تقديم هذه المعلومات والبيانات الشخصية، أثناء التسجيل أو غير ذلك، فأنت تمنح الشركة الإذن لاستخدامها ومشاركتها وتخزينها بطريقة تتفق مع سياسة الخصوصية هذه.</p>
<p>لمعلومات التي تم جمعها أثناء استخدام التطبيق</p>
<p>أثناء استخدام تطبيق توصل أوصل، ومن أجل الاستفادة من ميزات تطبيقنا، قد نجمع، بعد الحصول على إذن مسبق منك:</p>
<p>معلومات بخصوص موقعك</p>
<p>الصور والمعلومات الأخرى من كاميرا جهازك ومكتبة الصور</p>
<p>نستخدم هذه المعلومات للاستفادة من ميزات خدمتنا، ولتحسين وتخصيص خدمة للعملاء. قد يتم تحميل المعلومات على خوادم الشركة و / أو خادم مزود الخدمة أو قد يتم تخزينها ببساطة على جهازك.</p>
<p>يمكنك تمكين أو تعطيل الوصول إلى هذه المعلومات في أي وقت، من خلال إعدادات جهازك.</p>
<p>تقنيات التتبع وملفات تعريف الارتباط</p>
<p>نحن نستخدم ملفات تعريف الارتباط وتقنيات التتبع المماثلة، لتتبع النشاط على خدمتنا وتخزين معلومات معينة.</p>
<p>تقنيات التتبع المستخدمة: هي إشارات وعلامات ونصوص لجمع المعلومات وتتبعها ولتحسين خدمتنا وتحليلها. قد تشمل التقنيات التي نستخدمها ما يلي:</p>
<p>ملفات تعريف الارتباط أو ملفات تعريف الارتباط للمتصفح. ملف تعريف الارتباط هو ملف صغير يوضع على جهازك. يمكنك توجيه متصفحك لرفض جميع ملفات تعريف الارتباط أو للإشارة إلى وقت إرسال ملف تعريف الارتباط. ومع ذلك، إذا كنت لا تقبل ملفات تعريف الارتباط، فقد لا تتمكن من استخدام بعض أجزاء خدمتنا. وما لم تقم بتعديل إعدادات المستعرض الخاص بك الذي تم ضبطه مسبقا لرفض ملفات تعريف الارتباط، وخدمتنا تستخدم ملفات تعريف الارتباط.</p>
<p>ملفات تعريف الارتباط فلاش. قد تستخدم بعض ميزات خدمتنا كائنات محلية مخزنة أو ملفات تعريف ارتباط فلاش لجمع وتخزين معلومات حول تفضيلاتك أو نشاطك السابقة على خدمتنا. لا تتم إدارة ملفات تعريف ارتباط الفلاش بواسطة نفس إعدادات المتصفح مثل تلك المستخدمة لملفات تعريف الارتباط الخاصة بالمتصفح. لمزيد من المعلومات حول كيفية حذف ملفات تعريف الارتباط فلاش، يرجى قراءة &quot;أين يمكنني تغيير الإعدادات لتعطيل أو حذف الكائنات المشتركة المحلية؟&quot; متاح على</p>
<p>https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabled__or_deleting_local_shared_objects_</p>
<p>إشارات الويب. قد تحتوي أقسام معينة من خدمتنا ورسائل البريد الإلكتروني الخاصة بنا على ملفات إلكترونية صغيرة تُعرف باسم إشارات الويب (يشار إليها أيضًا باسم (جيف) الواضحة أو علامات البيكسل أو صور (جيف) أحادية البيكسل) التي تسمح للشركة، على سبيل المثال، بإحصاء المستخدمين الذين زاروا تلك الصفحات أو فتح بريدًا إلكترونيًا ولإحصائيات مواقع الويب الأخرى ذات الصلة (على سبيل المثال، تسجيل شعبية قسم معين والتحقق من سلامة النظام والخادم).</p>
<p>يمكن أن تكون ملفات تعريف الارتباط &quot;ثابتة&quot; أو &quot;لحظية&quot;. تظل ملفات تعريف الارتباط الدائمة على جهاز الحاسوب الشخصي أو الجهاز المحمول الخاص بك عندما تكون غير متصل بالإنترنت، بينما يتم حذف ملفات تعريف ارتباط اللحظية بمجرد إغلاق متصفح الويب الخاص بك. تعرف على المزيد حول ملفات تعريف الارتباط على مقالة موقع سياسات الخصوصية على الويب.</p>
<p>نستخدم كلاً من ملفات تعريف الارتباط الخاصة اللحظية وملفات تعريف الارتباط الدائمة للأغراض الموضحة أدناه:</p>
<p>ملفات تعريف الارتباط الضرورية / الأساسية</p>
<p>النوع: ملفات تعريف ارتباط اللحظية</p>
<p>بإدارة: توصل أوصل</p>
<p>الغرض: ملفات تعريف الارتباط هذه ضرورية لتزويدك بالخدمات المتاحة من خلال الموقع ولتمكينك من استخدام بعض ميزاته. وتساعد في مصادقة المستخدمين ومنع الاستخدام الاحتيالي لحسابات المستخدمين. بدون ملفات تعريف الارتباط هذه، لا يمكن توفير الخدمات التي طلبتها، ونحن نستخدم ملفات تعريف الارتباط هذه فقط لتزويدك بهذه الخدمات.</p>
<p>سياسة ملفات تعريف الارتباط / ملفات تعريف الارتباط بقبول الإشعار</p>
<p>النوع: ملفات تعريف الارتباط الدائمة</p>
<p>بإدارة: توصل أوصل</p>
<p>الغرض: تحدد ملفات تعريف الارتباط هذه ما إذا كان المستخدمون قد وافقوا على استخدام ملفات تعريف الارتباط على موقع الويب.</p>
<p>ملفات تعريف الارتباط الوظيفية</p>
<p>النوع: ملفات تعريف الارتباط الدائمة</p>
<p>بإدارة: توصل أوصل</p>
<p>الغرض: تسمح لنا ملفات تعريف الارتباط هذه بتذكر الاختيارات التي تقوم بها عند استخدام موقع الويب، مثل تذكر تفاصيل تسجيل الدخول أو تفضيلات اللغة. الغرض من ملفات تعريف الارتباط هذه هو تزويدك بتجربة شخصية أكثر وتجنب الاضطرار إلى إعادة إدخال تفضيلاتك في كل مرة تستخدم فيها الموقع.</p>
<p>ملفات تعريف الارتباط للتتبع والأداء</p>
<p>النوع: ملفات تعريف الارتباط الدائمة</p>
<p>بإدارة: طرف ثالث</p>
<p>الغرض: تُستخدم ملفات تعريف الارتباط هذه لتتبع المعلومات حول حركة المرور إلى موقع الويب وكيفية استخدام المستخدمين للموقع. قد تحددك المعلومات التي يتم جمعها عبر ملفات تعريف الارتباط هذه بشكل مباشر أو غير مباشر كزائر فردي. وذلك لأن المعلومات التي يتم جمعها عادة ما تكون مرتبطة بمعرف مستعار مرتبط بالجهاز الذي تستخدمه للوصول إلى موقع الويب. وقد نستخدم ملفات تعريف الارتباط هذه أيضًا لاختبار صفحات أو ميزات جديدة أو وظائف جديدة لموقع الويب لمعرفة كيفية تفاعل مستخدمينا معها.</p>
<p>لمزيد من المعلومات حول ملفات تعريف الارتباط التي نستخدمها وخياراتك فيما يتعلق بملفات تعريف الارتباط، يرجى زيارة سياسة ملفات تعريف الارتباط أو قسم ملفات تعريف الارتباط في سياسة الخصوصية الخاصة بنا.</p>
<p>استخدام بياناتك الشخصية</p>
<p>يجوز للشركة استخدام البيانات الشخصية للأغراض التالية:</p>
<p>لتوفير وصيانة الخدمة، بما في ذلك مراقبة الاستخدام.</p>
<p>لإدارة حسابك: لإدارة تسجيلك كمستخدم للخدمة. يمكن أن تمنحك البيانات الشخصية التي تقدمها إمكانية الوصول إلى الوظائف المختلفة للخدمة المتاحة لك كمستخدم مسجل.</p>
<p>لتنفيذ العقد: التطوير والامتثال والتعهد بعقد الخدمات التي اشتريتها أو أي عقد آخر معنا من خلال الخدمة.</p>
<p>للاتصال بك: للاتصال بك عن طريق البريد الإلكتروني، أو المكالمات الهاتفية، أو الرسائل النصية القصيرة، أو غيرها من أشكال الاتصالات الإلكترونية المماثلة، مثل الإخطارات الفورية لتطبيقات الهاتف المحمول فيما يتعلق بالتحديثات أو الاتصالات الإعلامية المتعلقة بالوظائف أو المنتجات أو الخدمات المتعاقد عليها، بما في ذلك التحديثات الأمنية، عند الضرورة أو المعقول لتنفيذها.</p>
<p>لتزويدك بالأخبار والعروض الخاصة والمعلومات العامة حول السلع والخدمات والتحديثات والأحداث الأخرى التي نقدمها والتي تشبه تلك التي اشتريتها بالفعل أو استفسرت عنها ما لم تكن قد اخترت عدم تلقي هذه المعلومات.</p>
<p>إدارة حجوزاتك: وإدارة الحجوزات ومراقبتها.</p>
<p>بالنسبة لعمليات نقل الأعمال: قد نستخدم معلوماتك لتقييم، أو إجراء عملية اندماج، أو تصفية، أو إعادة هيكلة، أو إعادة تنظيم، أو حل، أو بيع، أو نقل آخر لبعض، أو كل أصولنا، سواء كان ذلك كمنشأة مستمرة أو كجزء من الإفلاس أو التصفية، أو إجراء مشابه، حيث تكون البيانات الشخصية التي نحتفظ بها عن مستخدمي خدمتنا من بين الأصول المنقولة.</p>
<p>لأغراض أخرى: قد نستخدم معلوماتك لأغراض أخرى، مثل تحليل البيانات، وتحديد اتجاهات الاستخدام، وتحديد فعالية حملاتنا الترويجية ولتقييم وتحسين خدماتنا، وتسويقنا، وتجربتك.</p>
<h1></h1>
<p>قد نشارك معلوماتك الشخصية في المواقف التالية:</p>
<p>مع مزودي الخدمة: قد نشارك معلوماتك الشخصية مع مزودي الخدمة لمراقبة وتحليل استخدام خدمتنا، لمعالجة الدفع، للاتصال بك.</p>
<p>لنقل الأعمال: يجوز لنا مشاركة معلوماتك الشخصية أو نقلها فيما يتعلق أو أثناء المفاوضات بشأن أي اندماج، أو بيع أصول الشركة، أو تمويل، أو الاستحواذ على كل أو جزء من أعمالنا إلى شركة أخرى.</p>
<p>مع الشركات التابعة: قد نشارك معلوماتك مع الشركات التابعة لنا، وفي هذه الحالة سنطلب من تلك الشركات التابعة احترام سياسة الخصوصية هذه. تشمل الشركات التابعة شركتنا الأم وأي شركات فرعية أخرى أو شركاء في المشاريع المشتركة أو شركات أخرى نسيطر عليها أو تخضع لسيطرة مشتركة معنا.</p>
<p>مع شركاء الأعمال: قد نشارك معلوماتك مع شركائنا في العمل لنقدم لك منتجات أو خدمات أو عروض ترويجية معينة.</p>
<p>مع مستخدمين آخرين: عندما تشارك معلومات شخصية أو تتفاعل بطريقة أخرى في المناطق العامة مع مستخدمين آخرين، فقد يتم عرض هذه المعلومات من قبل جميع المستخدمين ويمكن توزيعها علنًا في الخارج. إذا تفاعلت مع مستخدمين آخرين أو قمت بالتسجيل من خلال خدمة وسائط اجتماعية لطرف ثالث، فقد ترى جهات الاتصال الخاصة بك على خدمة الوسائط الاجتماعية التابعة لجهة خارجية اسمك وملفك التعريفي وصورك ووصف نشاطك. وبالمثل، سيتمكن المستخدمون الآخرون من عرض أوصاف نشاطك والتواصل معك وعرض ملفك الشخصي.</p>
<p>بموافقتك: يجوز لنا الكشف عن معلوماتك الشخصية لأي غرض آخر بموافقتك.</p>
<p>الاحتفاظ ببياناتك الشخصية</p>
<p>ستحتفظ الشركة ببياناتك الشخصية فقط طالما كان ذلك ضروريًا للأغراض المنصوص عليها في سياسة الخصوصية هذه. سنحتفظ ببياناتك الشخصية ونستخدمها بالقدر اللازم للامتثال لالتزاماتنا القانونية (على سبيل المثال، إذا كنا مطالبين بالاحتفاظ ببياناتك للامتثال للقوانين المعمول بها)، وحل النزاعات، وفرض اتفاقياتنا وسياساتنا القانونية.</p>
<p>ستحتفظ الشركة أيضًا ببيانات الاستخدام لأغراض التحليل الداخلي. يتم الاحتفاظ ببيانات الاستخدام عمومًا لفترة زمنية أقصر، إلا في حالة استخدام هذه البيانات لتعزيز الأمان أو لتحسين وظائف خدمتنا، أو نحن ملزمون قانونًا بالاحتفاظ بهذه البيانات لفترات زمنية أطول.</p>
<p>نقل بياناتك الشخصية</p>
<p>تتم معالجة معلوماتك، بما في ذلك البيانات الشخصية، في مكاتب تشغيل الشركة وفي أي أماكن أخرى حيث توجد الأطراف المشاركة في المعالجة. وهذا يعني أنه قد يتم نقل هذه المعلومات إلى - والاحتفاظ بها - على أجهزة الحاسوب الموجودة خارج ولايتك، أو مقاطعتك، أو بلدك، أو أي ولاية قضائية حكومية أخرى حيث قد تختلف قوانين حماية البيانات عن تلك الموجودة في ولايتك القضائية.</p>
<p>موافقتك على سياسة الخصوصية هذه متبوعة بتقديمك لهذه المعلومات يمثل موافقتك على هذا النقل.</p>
<p>ستتخذ الشركة جميع الخطوات اللازمة بشكل معقول لضمان التعامل مع بياناتك بشكل آمن ووفقًا لسياسة الخصوصية هذه ولن يتم نقل بياناتك الشخصية إلى منظمة أو دولة ما لم تكن هناك ضوابط كافية في مكانها بما في ذلك أمن بياناتك ومعلوماتك الشخصية الأخرى.</p>
<p>الإفصاح عن بياناتك الشخصية</p>
<h1></h1>
<p>المعاملات التجارية</p>
<p>إذا كانت الشركة متورطة في عملية دمج أو استحواذ أو بيع أصول، فقد يتم نقل بياناتك الشخصية. سنقدم إشعارًا قبل نقل بياناتك الشخصية وإخضاعها لسياسة خصوصية مختلفة.</p>
<p>تطبيق القانون</p>
<p>في ظل ظروف معينة، قد يُطلب من الشركة الكشف عن بياناتك الشخصية إذا طُلب منها ذلك بموجب القانون أو استجابة لطلبات صحيحة من قبل السلطات العامة (على سبيل المثال، محكمة أو وكالة حكومية).</p>
<p>المتطلبات القانونية الأخرى</p>
<p>قد تفصح الشركة عن بياناتك الشخصية بحسن نية أن هذا الإجراء ضروري من أجل:</p>
<p>لامتثال لالتزام قانوني</p>
<p>حماية والدفاع عن حقوق أو ممتلكات الشركة</p>
<p>منع أو التحقيق في أي مخالفات محتملة تتعلق بالخدمة</p>
<p>حماية السلامة الشخصية لمستخدمي الخدمة أو الجمهور</p>
<p>الحماية من المسؤولية القانونية</p>
<p>أمن بياناتك الشخصية</p>
<p>يعد أمان بياناتك الشخصية أمرًا مهمًا بالنسبة لنا، ولكن تذكر أنه لا توجد طريقة نقل عبر الإنترنت أو طريقة تخزين إلكتروني آمنة بنسبة 100٪. بينما نسعى جاهدين لاستخدام وسائل مقبولة تجاريًا لحماية بياناتك الشخصية، لا يمكننا ضمان أمنها المطلق.</p>
<p>معلومات مفصلة عن معالجة بيانتك الشخصية</p>
<p>قد يتمكن مقدمو الخدمة الذين نستخدمهم من الوصول إلى بياناتك الشخصية. يقوم بائعو الجهات الخارجية بجمع وتخزين واستخدام ومعالجة ونقل المعلومات حول نشاطك على خدمتنا وفقًا لسياسات الخصوصية الخاصة بهم.</p>
<p>تحليلات</p>
<p>قد نستخدم مزودي خدمات تابعين لجهات خارجية وطرف ثالث لمراقبة وتحليل استخدام خدمتنا.</p>
<p>تحليلات جوجل</p>
<h1></h1>
<p>تحليلات جوجل هي خدمة تحليلات الويب التي تقدمها جوجل والتي تتعقب حركة مرور موقع الويب وتبلغ عنها. تستخدم جوجل البيانات التي تم جمعها لتتبع ومراقبة استخدام خدمتنا. تتم مشاركة هذه البيانات مع خدمات جوجل الأخرى. قد تستخدم جوجل البيانات التي تم جمعها لتهيئة إعلانات شبكة الإعلانات الخاصة بها وتخصيصها.</p>
<p>يمكنك إلغاء الاشتراك في جعل نشاطك على الخدمة متاحًا لبرنامج تحليلات جوجل عن طريق تثبيت وظيفة المتصفح الإضافية لتعطيل التتبع في تحليلات جوجل. تمنع الوظيفة الإضافية تحليلات جوجل</p>
<p>JavaScript (ga.js dc.js) و analytics.js و من مشاركة المعلومات مع تحليلات جوجل حول نشاط الزيارات. يمكنك إلغاء الاشتراك في بعض ميزات تحليلات جوجل من خلال إعدادات جهازك المحمول، مثل إعدادات الإعلان على جهازك أو باتباع الإرشادات المقدمة من جوجل في سياسة الخصوصية الخاصة بها:</p>
<h1></h1>
<p>لمزيد من المعلومات حول ممارسات الخصوصية الخاصة بـ جوجل، يرجى زيارة صفحة الويب الخاصة بخصوصية وسياسة جوجل:</p>
<h1></h1>
<h1></h1>
<h1></h1>
<h1></h1>
<p>المدفوعات</p>
<p>قد نقدم منتجات و / أو خدمات مدفوعة ضمن الخدمة. في هذه الحالة، قد نستخدم خدمات الجهات الخارجية لمعالجة الدفع (مثل معالجات الدفع).</p>
<p>لن نقوم بتخزين أو جمع تفاصيل بطاقة الدفع الخاصة بك. يتم تقديم هذه المعلومات مباشرةً إلى معالجي الدفع التابعين لجهات خارجية والذين يخضع استخدامهم لمعلوماتك الشخصية لسياسة الخصوصية الخاصة بهم. تلتزم معالجات الدفع هذه بالمعايير التي حددتها PCI-DSS كما يديرها مجلس معايير أمان PCI، وهو جهد مشترك لعلامات تجارية مثل فيزا وماستركارد وتساعد متطلبات PCI-DSS على ضمان المعالجة الآمنة لمعلومات الدفع</p>
<p>مدفوعات متجر أبل داخل التطبيق</p>
<p>يمكن الاطلاع على سياسة الخصوصية الخاصة بهم على</p>
<h1></h1>
<p>مدفوعات متجر جوجل بلاي</p>
<p>يمكن الاطلاع على سياسة الخصوصية الخاصة بهم على</p>
<h1></h1>
<p>باي بال</p>
<p>يمكن الاطلاع على سياسة الخصوصية الخاصة بهم على</p>
<h1></h1>
<p>خصوصية الاطفال</p>
<p>لا تخاطب خدمتنا أي شخص يقل عمره عن 13 عامًا، فنحن لا نجمع عن قصد معلومات تعريف شخصية من أي شخص يقل عمره عن 13 عامًا. إذا كنت أحد الوالدين أو وصيًا وأنت تعلم أن طفلك قد زودنا ببيانات شخصية، من فضلك اتصل بنا. إذا علمنا أننا قد جمعنا بيانات شخصية من أي شخص يقل عمره عن 13 عامًا دون التحقق من موافقة الوالدين، فإننا نتخذ خطوات لإزالة هذه المعلومات من خوادمنا..</p>
<p>إذا احتجنا إلى الاعتماد على الموافقة كأساس قانوني لمعالجة معلوماتك وطلب بلدك موافقة أحد الوالدين، فقد نطلب موافقة والديك قبل أن نجمع هذه المعلومات ونستخدمها.</p>
<p>روابط المواقع الاخرى</p>
<p>قد تحتوي خدمتنا على روابط لمواقع أخرى لا نقوم بتشغيلها. إذا قمت بالنقر فوق ارتباط جهة خارجية، فسيتم توجيهك إلى موقع الطرف الثالث. ننصحك بشدة بمراجعة سياسة الخصوصية لكل موقع تزوره.</p>
<p>ليس لدينا أي سيطرة ولا نتحمل أي مسؤولية عن المحتوى أو سياسات الخصوصية أو الممارسات الخاصة بأي مواقع أو خدمات تابعة لجهات خارجية.</p>
<p>التغيرات على سياسة الخصوصية</p>
<p>قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت لآخر. سنخطرك بأي تغييرات عن طريق نشر سياسة الخصوصية الجديدة على هذه الصفحة.</p>
<p>سنخبرك عبر البريد الإلكتروني و / أو إشعارًا بارزًا على &quot;خدمتنا&quot; ، قبل أن يصبح التغيير ساريًا وتحديث تاريخ &quot;آخر تحديث&quot; في الجزء العلوي من سياسة الخصوصية هذه.</p>
<p>يُنصح بمراجعة سياسة الخصوصية هذه بشكل دوري لمعرفة أي تغييرات. تسري التغييرات التي تطرأ على سياسة الخصوصية هذه عند نشرها على هذه الصفحة.</p>
<h1></h1>
<p>الاتصال بنا</p>
<p>إذا كان لديك أي أسئلة حول سياسة الخصوصية هذه، يمكنك الاتصال بنا:</p>
<p>على البريد الالكتروني للشركة: info@twasalawasal.com</p>
<h1></h1>
    </div>
  );
}
