import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Paper, Typography } from "@material-ui/core";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
/*global google*/

import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from "common";
import { useTranslation } from "react-i18next";

import greenpin from "../assets/img/pickgreen3x.png";
import redpin from "../assets/img/dropred3x.png";
import carpin from "../assets/img/track_Car.png";

const TrackRide = () => {
  const { api } = useContext(FirebaseContext);
  const { fetchBooking } = api;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const [bookingId, setBookingId] = useState(null);
  const [mapcenter, setMapCenter] = useState([]);
  const [locations, setLocations] = useState([]);
  const [message, setMessage] = useState();
  const bookingdata = useSelector((state) => state.bookingsingledata);
  const trackingdata = useSelector((state) => state.locationdata);

  let { id } = useParams();
  if (!bookingId) setBookingId(id);

  useEffect(() => {
    if (bookingId) {
      dispatch(fetchBooking(bookingId));
    }
  }, [dispatch, fetchBooking, bookingId]);

  useEffect(() => {
    if (bookingdata.booking) {
      let booking = bookingdata.booking;
      let locs = [];
      locs.push({
        id: "1",
        lat: booking.pickup.lat,
        lng: booking.pickup.lng,
        name: booking.customer_name,
        address: booking.pickup.add,
        icon: greenpin,
      });
      locs.push({
        id: "3",
        lat: booking.drop.lat,
        lng: booking.drop.lng,
        name: booking.deliveryPerson,
        address: "",
        icon: redpin,
      });

      if (trackingdata.coords) {
        locs.push({
          id: "2",
          lat: trackingdata.coords.lat,
          lng: trackingdata.coords.lng,
          name: booking.driver_name,
          address: "",
          icon: carpin,
        });
      }
      setMapCenter({ lat: booking.pickup.lat, lng: booking.pickup.lng });
      setLocations(locs);
    } else if (bookingdata.error && bookingdata.error.msg) {
      setMessage(bookingdata.error.msg);
    }
  }, [bookingdata.booking, trackingdata.coords, bookingdata.error]);

  const Map = withGoogleMap((props) => (
    <GoogleMap defaultZoom={10} defaultCenter={props.mapcenter}>
      {props.locations.map((marker) => (
        <Marker
          position={{ lat: marker.lat, lng: marker.lng }}
          key={marker.id}
          icon={{
            url: marker.icon,
          }}
          title={marker.name}
          content={marker.address}
        ></Marker>
      ))}
    </GoogleMap>
  ));

  return bookingdata.loading ? (
    <CircularLoading />
  ) : (
    <div>
      {locations.length > 0 ? (
        <Paper>
          <Map
            mapcenter={mapcenter}
            locations={locations}
            loadingElement={<div style={{ height: `100vh` }} />}
            containerElement={<div style={{ height: `100vh` }} />}
            mapElement={<div style={{ height: `100vh` }} />}
          />
        </Paper>
      ) : (
        <Typography
          variant="h6"
          style={{ marginTop: "45vh", color: "#FF0000", textAlign: "center" }}
        >
          {message}
        </Typography>
      )}
    </div>
  );
};

export default TrackRide;
