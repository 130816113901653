/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import styles from "assets/jss/material-kit-react/views/componentsSections/downloadStyle.js";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function SectionDownload() {
  const settings = useSelector(state => state.settingsdata.settings);
  const classes = useStyles();
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';
  return (
    <div className={classes.section}>
    <GridContainer>
      <GridItem xs={12} className={"CommitmentHd"}>
        <h2>{t('our_commitment')}</h2>
      </GridItem>
    </GridContainer>

    <GridContainer spacing={{ xs: 2, md: 3, lg: 6 }}>
      <GridItem xs={12} sm={6} lg={3}>
        <div className={"CommitItem"}>
          <div className={"CommitItIcon"}>
            <img src={require("assets/img/icon-safety.png").default} />
          </div>
          <div className={"CommitItBody"}>
            <h3>{t('product_safety')}</h3>
            <p>{t('product_safety_desc')}</p>
          </div>
        </div>
      </GridItem>
      <GridItem xs={12} sm={6} lg={3}>
        <div className={"CommitItem"}>
          <div className={"CommitItIcon"}>
            <img src={require("assets/img/icon-purse.png").default} />
          </div>
          <div className={"CommitItBody"}>
            <h3>{t('pocket_friendly')}</h3>
            <p>{t('pocket_friendly_desc')}</p>
          </div>
        </div>
      </GridItem>
      <GridItem xs={12} sm={6} lg={3}>
        <div className={"CommitItem"}>
          <div className={"CommitItIcon"}>
            <img src={require("assets/img/icon-delivery.png").default} />
          </div>
          <div className={"CommitItBody"}>
            <h3>{t('fastest_delivery')}</h3>
            <p>{t('fastest_delivery_desc')}</p>
          </div>
        </div>
      </GridItem>
      <GridItem xs={12} sm={6} lg={3}>
        <div className={"CommitItem"}>
          <div className={"CommitItIcon"}>
            <img src={require("assets/img/icon-tracking.png").default} />
          </div>
          <div className={"CommitItBody"}>
            <h3>{t('easy_tracking')}</h3>
            <p>{t('easy_tracking_desc')}</p>
          </div>
        </div>
      </GridItem>
    </GridContainer>
  </div>
  );
}
