/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const settings = useSelector((state) => state.settingsdata.settings);
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  let history = useHistory();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();

  return (
    <div>
      <GridContainer justify='center'>
        <GridItem xs={12} lg={7}>
          <GridContainer justify='center'>
            <GridItem sm={6}>
              <div className={"FooterLogoCover"}>
                <div className={"FooterLogo"}>
                  <img src={require("assets/img/logo.png").default} className={['h-auto logo']} />
                </div>
                {settings && settings.address ? (
                  <div className={['FooterAddress']}>
                    <h3>{t("address")}</h3>
                    <p>
                      <i className={['fas fa-map-marker-alt']}></i> {settings.Address}
                    </p>
                  </div>
                ) : null}
              </div>
            </GridItem>
            <GridItem sm={6} className={"Col-ft-Contact"}>
              {settings && settings.contact_email ? (
                <div className={"FtAddress"}>
                  <h3>{t("quick_contact")}</h3>
                  <ul>
                    <li>
                      <i className={['fas fa-envelope']}></i> <a href={`mailto:${settings.contact_email}`}>{settings.contact_email}</a>
                    </li>
                  </ul>
                  <br />
                </div>
              ) : null}
              <div className={"FtTime"}>
                <h3>{t("working_hours")}</h3>
                <p>
                  <i className={['far fa-clock']}></i> {t("available_request")}
                </p>
              </div>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} lg={5}>
          <ul className={"FooterNav"}>
            <li> <a style={{cursor:'pointer'}} onClick={(e) => { e.preventDefault(); history.push('/home') }} > {t('home')} </a> </li>
            <li> <a style={{cursor:'pointer'}} onClick={(e) => { e.preventDefault(); history.push('/about-us') }} > {t('about')} </a> </li>
          </ul>
          <h3 className={"FooterTag"}>{t("footer_tag")}</h3>
          {settings ? (
            <ul className={"FooterSocial"}>
              {settings.FacebookHandle !== "" ? (
                <li>
                  <a href={settings.FacebookHandle} target='_blank'>
                    <i className={['fab fa-facebook-f']}></i>
                  </a>
                </li>
              ) : null}
              {settings.InstagramHandle !== "" ? (
                <li>
                  <a href={settings.InstagramHandle} target='_blank'>
                    <i className={['fab fa-instagram']}></i>
                  </a>
                </li>
              ) : null}
              {settings.TwitterHandle !== "" ? (
                <li>
                  <a href={settings.TwitterHandle} target='_blank'>
                    <i className={['fab fa-twitter']}></i>
                  </a>
                </li>
              ) : null}
            </ul>
          ) : null}
        </GridItem>
      </GridContainer>
      <GridContainer className={"BottomSec"}>
        <GridItem lg={4}>
          {settings && settings.CompanyName ? (
            <div className={"Copyright"}>
              &copy; {1900 + new Date().getYear() + " "} | {settings.CompanyName} <br /> {t("CopyrightText")}
            </div>
          ) : null}
        </GridItem>
        <GridItem lg={8}>
          <ul className={"BottomLink"}>
            <li> <a style={{cursor:'pointer'}} onClick={(e) => { e.preventDefault(); history.push('/privacy-policy') }} > {t('privacy')} </a> </li>
            <li> <a style={{cursor:'pointer'}} onClick={(e) => { e.preventDefault(); history.push('/terms-conditions') }} > {t('terms')} </a> </li>
          </ul>
        </GridItem>
      </GridContainer>
    </div>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
