import React,{ useState, useEffect, useContext, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';

export default function Users() {
  const { api } = useContext(FirebaseContext);
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    addUser,
    editUser,
    deleteUser,
    checkUserExists,
    fetchUsersOnce,
    SendSMSMsg
  } = api;
  const [data, setData] = useState([]);
  const [cars, setCars] = useState({});
  const staticusers = useSelector(state => state.usersdata.staticusers);
  const cartypes = useSelector(state => state.cartypes);
  const auth = useSelector(state => state.auth);
  const settings = useSelector(state => state.settingsdata.settings);
  const dispatch = useDispatch();
  const loaded = useRef(false);

  useEffect(()=>{
      dispatch(fetchUsersOnce());
  },[dispatch,fetchUsersOnce]);

  useEffect(()=>{
    if(staticusers){
        setData(staticusers.filter(user => user.usertype ==='driver' && ((user.fleetadmin === auth.info.uid && auth.info.profile.usertype === 'fleetadmin')|| auth.info.profile.usertype === 'admin')));
    }else{
      setData([]);
    }
    loaded.current = true;
  },[staticusers,auth.info.profile.usertype,auth.info.uid]);

  useEffect(()=>{
    if(cartypes.cars){
        let obj =  {};
        cartypes.cars.map((car)=> obj[car.name]=car.name)
        setCars(obj);
    }
  },[cartypes.cars]);

  const columns = [
    { title: t('createdAt'), field: 'createdAt', editable: 'never', defaultSort: 'desc', render: rowData => rowData.createdAt ? moment(rowData.createdAt).format('lll') : null },
    { title: t('firstName'), field: 'firstName', initialEditValue: '' },
    { title: t('middleName'), field: 'middleName', initialEditValue: '' },
    { title: t('thirdName'), field: 'thirdName', initialEditValue: '' },
    { title: t('familyName'), field: 'familyName', initialEditValue: '' },
    { title: t('email'), field: 'email', editable: 'onAdd', render: rowData => settings.AllowCriticalEditsAdmin ? rowData.email : "Hidden for Demo" },
    { title: t('mobile'), field: 'mobile', editable: 'onAdd', initialEditValue: '+968', render: rowData => settings.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo" },
    { title: t('idCard'), field: 'idCard', initialEditValue: '' },
    { title: t('idCardExpiry'), field: 'idCardExpiry', initialEditValue: '' },
    { title: t('licenseNumber'), field: 'licenseNumber', initialEditValue: '' },
    { title: t('licenseExpiry'), field: 'licenseExpiry', initialEditValue: '' },
    { title: t('vehicleMake'), field: 'vehicleMake', initialEditValue: '' },
    { title: t('vehicleModel'), field: 'vehicleModel', initialEditValue: '' },
    { title: t('vehicleManufacture'), field: 'vehicleManufacture', initialEditValue: '' },
    { title: t('vehicleNumber'), field: 'vehicleNumber', initialEditValue: '' },
    { title: t('vehicleExpiry'), field: 'vehicleExpiry', initialEditValue: '' },
    { title: t('carType'), field: 'carType', lookup: cars },
    { title: t('account_approve'), field: 'approved', type: 'boolean', initialEditValue: true },
    { title: t('driver_active'), field: 'driverActiveStatus', type: 'boolean', initialEditValue: true },
    { title: t('th_vehicleRegistrationImageF'), render: rowData => rowData.registrationImage ? <a href={rowData.registrationImage} target='_blank' rel="noreferrer"><img alt='Mulkiya' src={rowData.registrationImage} style={{ width: 100 }} /></a> : null, editable: 'never' },
    { title: t('th_vehicleRegistrationImageB'), render: rowData => rowData.registrationBackImage ? <a href={rowData.registrationBackImage} target='_blank' rel="noreferrer"><img alt='Mulkiya' src={rowData.registrationBackImage} style={{ width: 100 }} /></a> : null, editable: 'never' },
    { title: t('th_drivingLicenseImageF'),  render: rowData => rowData.licenseImage ? <a href={rowData.licenseImage} target='_blank' rel="noreferrer"><img alt='License' src={rowData.licenseImage} style={{ width: 100 }} /></a> : null, editable: 'never' },
    { title: t('th_drivingLicenseImageB'),  render: rowData => rowData.licenseBackImage ? <a href={rowData.licenseBackImage} target='_blank' rel="noreferrer" ><img alt='License' src={rowData.licenseBackImage} style={{ width: 100 }} /></a> : null, editable: 'never' },
    { title: t('th_idCardImageF'), render: rowData => rowData.idCardImage ? <a href={rowData.idCardImage} target='_blank' rel="noreferrer"><img alt='ID Card' src={rowData.idCardImage} style={{ width: 100 }} /></a> : null, editable: 'never' },
    { title: t('th_idCardImageB'), render: rowData => rowData.idCardBackImage ? <a href={rowData.idCardBackImage} target='_blank' rel="noreferrer"><img alt='ID Card' src={rowData.idCardBackImage} style={{ width: 100 }} /></a> : null, editable: 'never' },
    { title: t('th_RopClearance'), render: rowData => rowData.ropClearanceCertificate ? <a href={rowData.ropClearanceCertificate} target='_blank' rel="noreferrer"><img alt='ROP Clearance' src={rowData.ropClearanceCertificate} style={{ width: 100 }} /></a> : null, editable: 'never' },
    { title: t('profile_image'), field: 'profile_image', render: rowData => rowData.profile_image ? <a href={rowData.profile_image} target='_blank' rel="noreferrer"><img alt='Profile' src={rowData.profile_image} style={{ width: 100 }} /></a> : null, editable: 'never' },
    { title: t('wallet_balance'), field: 'walletBalance', type: 'numeric', editable: 'never', initialEditValue: 0 },
    { title: t('you_rated_text'), render: rowData => <span>{rowData.ratings ? rowData.ratings.userrating : "0"}</span> },
    { title: t('signup_via_referral'), field: 'signupViaReferral', editable: 'never' },
    { title: t('referralId'), field: 'referralId', editable: 'never', initialEditValue: '' },
    { title: t('bankName'), field: 'bankName', initialEditValue: '' },
    { title: t('bankCode'), field: 'bankCode', initialEditValue: '' },
    { title: t('bankAccount'), field: 'bankAccount', initialEditValue: '' },
    { title: t('other_info'), field: 'other_info', initialEditValue: '' }
  ];

  return (
    !loaded.current? <CircularLoading/>:
    <MaterialTable
      title={t('drivers')}
      columns={columns}
      style={{direction:isRTL ==='rtl'?'rtl':'ltr'}}
      data={data}
      options={{
        exportButton: settings.AllowCriticalEditsAdmin,
        sorting: true,
      }}
      localization={{body:{
        addTooltip: (t('add')),
        deleteTooltip: (t('delete')),
        editTooltip: (t('edit')),
        emptyDataSourceMessage: (
          (t('blank_message'))
      ),
      editRow: {
        deleteText: (t('delete_message')),
        cancelTooltip: (t('cancel')),
        saveTooltip: (t('save'))
          },
        },
        toolbar: {
          searchPlaceholder: (t('search')),
          exportTitle: (t('export')),
        },
        header: {
          actions: (t('actions'))
      },
      pagination: {
        labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
        labelRowsSelect: (t('rows')),
        firstTooltip: (t('first_page_tooltip')),
        previousTooltip: (t('previous_page_tooltip')),
        nextTooltip: (t('next_page_tooltip')),
        lastTooltip: (t('last_page_tooltip'))
      },
      }}
      editable={{
        onRowAdd: newData =>
        new Promise((resolve,reject) => {
          setTimeout(() => {
            checkUserExists(newData).then((res) => {
              if (res.users && res.users.length > 0) {
                alert(t('user_exists'));
                reject();
              }
              else if(res.error){
                alert(t('email_or_mobile_issue'));
                reject();
              }
              else{
                newData['createdByAdmin'] = true;
                newData['usertype'] = 'driver';
                newData['createdAt'] = new Date().toISOString();
                newData['referralId'] = newData.firstName.toLowerCase() + Math.floor(1000 + Math.random() * 9000).toString();
                let role = auth.info.profile.usertype;
                if(role === 'fleetadmin'){
                  newData['fleetadmin'] = auth.info.uid;
                }
                dispatch(addUser(newData));
                dispatch(fetchUsersOnce());
                resolve();
              }
            });
          }, 600);
        }),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(editUser(oldData.id,newData));
              if(oldData.approved === false && newData.approved === true){
                SendSMSMsg(newData.mobile,"Hi "+newData.firstName +", Your Account is Approved. Please login from App to accept rides.");
              }
              dispatch(fetchUsersOnce());
            }, 600);
          }),
        onRowDelete: oldData =>
          settings.AllowCriticalEditsAdmin?
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(deleteUser(oldData.id));
              dispatch(fetchUsersOnce());
            }, 600);
          })
          :
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              alert(t('demo_mode'));
            }, 600);
          })
          ,
      }}
    />
  );
}
