import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function TermsConditions(props) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const settings = useSelector((state) => state.settingsdata.settings);
  const { ...rest } = props;
  return (
    <div>
      <Header
        color='transparent'
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 50,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/header-back.jpg").default} />
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <h1 className="successHeading">{t("success")}</h1>
          <div className={'description'} style={{ textAlign: "center" }}>
          {t("success_registration_message")}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
