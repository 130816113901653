/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import styles from "assets/jss/material-kit-react/views/componentsSections/downloadStyle.js";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function SectionDownload() {
  const settings = useSelector(state => state.settingsdata.settings);
  const classes = useStyles();
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';
  return (
    <div className={classes.section}>
      <GridContainer className={"mx-0"}>
        <GridItem xs={12} className={"MadeEasyHd"}>
          <h6>{t('steps')}</h6>
          <h2>{t('shipping_made_easy')}</h2>
        </GridItem>
      </GridContainer>
      <div className={"MadeEasyItem"}>
        <GridContainer spacing={0} className={"mx-0"}>
          <GridItem xs={12} sm={12} md={6} className={"px-0"}>
            <div className={"MadeEasyItImg"}>
              <img src={require("assets/img/shipping-1.jpg").default} />
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} className={"px-0"}>
            <div className={"MadeEasyItBody"}>
              <div className={"count"}>1</div>
              <h2>{t('schedule_a_pickup')}</h2>
              <ul>
                <li>{t('schedule_a_pickup1')}</li>
                <li>{t('schedule_a_pickup2')}</li>
                <li>{t('schedule_a_pickup3')}</li>
              </ul>
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <div className={"MadeEasyItem"}>
        <GridContainer spacing={0} className={"mx-0"}>
          <GridItem xs={12} sm={12} md={6} className={"px-0"}>
            <div className={"MadeEasyItImg"}>
              <img src={require("assets/img/shipping-2.jpg").default} />
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} className={"px-0"}>
            <div className={"MadeEasyItBody"}>
              <div className={"count"}>2</div>
              <h2>{t('doorstep_pickup')}</h2>
              <ul>
                <li>{t('doorstep_pickup1')}</li>
                <li>{t('doorstep_pickup2')}</li>
              </ul>
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <div className={"MadeEasyItem"}>
        <GridContainer spacing={0} className={"mx-0"}>
          <GridItem xs={12} sm={12} md={6} className={"px-0"}>
            <div className={"MadeEasyItImg"}>
              <img src={require("assets/img/shipping-3.jpg").default} />
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} className={"px-0"}>
            <div className={"MadeEasyItBody"}>
              <div className={"count"}>3</div>
              <h2>{t('real_time_tracking')}</h2>
              <ul>
                <li>{t('real_time_tracking1')}</li>
                <li>{t('real_time_tracking2')}</li>
              </ul>
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <div className={"MadeEasyItem"}>
        <GridContainer spacing={0} className={"mx-0"}>
          <GridItem xs={12} sm={12} md={6} className={"px-0"}>
            <div className={"MadeEasyItImg"}>
              <img src={require("assets/img/shipping-4.jpg").default} />
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} className={"px-0"}>
            <div className={"MadeEasyItBody"}>
              <div className={"count"}>4</div>
              <h2>{t('quick_package_delivery')}</h2>
              <ul>
                <li>{t('quick_package_delivery1')}</li>
              </ul>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
