import React, { useState, useEffect, useContext, useCallback } from "react";
import MaterialTable from "material-table";
import { useDispatch, useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { FirebaseContext } from "common";

export default function DriverEarning() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const dispatch = useDispatch();
  const { api } = useContext(FirebaseContext);
  const driverPaymentWeeks = useSelector(
    (state) => state.driversPaymentWeeks // Make sure this path reflects your Redux state
  );
  const { editDriverPaymentWeek, addToWallet } = api;
  const handlePaymentProcessed = (driverUId, weekStart, driverShare) => {
    dispatch(editDriverPaymentWeek(driverUId, weekStart));
    dispatch(addToWallet(driverUId, -driverShare));
  };

  let renderPaymentButton = (rowData) => {
    const paymentWeeks = driverPaymentWeeks?.paymentWeeks;
    if (paymentWeeks) {
      const isPaymentProcessed =
        paymentWeeks[rowData.driverUId] &&
        paymentWeeks[rowData.driverUId][rowData.startOfWeek] === true;

      return (
        <Button
          onClick={() =>
            handlePaymentProcessed(
              rowData.driverUId,
              rowData.startOfWeek,
              rowData.driverShare
            )
          }
          disabled={isPaymentProcessed}
        >
          {isPaymentProcessed ? "Payment Processed" : "Process Payment"}
        </Button>
      );
    } else {
      return <div></div>;
    }
  };

  const monthlyColumns = [
    {
      title: t("year"),
      field: "year",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 40 : null },
    },
    {
      title: t("months"),
      field: "monthsName",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 40 : null },
    },
    {
      title: t("driver_name"),
      field: "driverName",
      cellStyle: {
        paddingRight: isRTL === "rtl" ? "10%" : null,
        textAlign: isRTL === "rtl" ? "right" : null,
      },
      headerStyle: {
        paddingRight: isRTL === "rtl" ? "10%" : null,
        textAlign: isRTL === "rtl" ? "right" : null,
      },
    },
    {
      title: t("booking_count"),
      field: "total_rides",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 40 : null },
    },
    {
      title: t("vehicle_reg_no"),
      field: "driverVehicleNo",
      cellStyle: {
        paddingRight: isRTL === "rtl" ? "10%" : null,
        textAlign: isRTL === "rtl" ? "right" : null,
      },
      headerStyle: {
        paddingRight: isRTL === "rtl" ? "10%" : null,
        textAlign: isRTL === "rtl" ? "right" : null,
      },
    },
    {
      title: t("earning_amount"),
      field: "driverShare",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 40 : null },
    },
  ];

  let weeklyColumns = [
    {
      title: t("year"),
      field: "year",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 40 : null },
    },
    {
      title: t("Week Start"),
      field: "startOfWeek",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 40 : null },
    },
    {
      title: t("driver_name"),
      field: "driverName",
      cellStyle: {
        paddingRight: isRTL === "rtl" ? "10%" : null,
        textAlign: isRTL === "rtl" ? "right" : null,
      },
      headerStyle: {
        paddingRight: isRTL === "rtl" ? "10%" : null,
        textAlign: isRTL === "rtl" ? "right" : null,
      },
    },
    {
      title: t("booking_count"),
      field: "total_rides",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 40 : null },
    },
    {
      title: t("vehicle_reg_no"),
      field: "driverVehicleNo",
      cellStyle: {
        paddingRight: isRTL === "rtl" ? "10%" : null,
        textAlign: isRTL === "rtl" ? "right" : null,
      },
      headerStyle: {
        paddingRight: isRTL === "rtl" ? "10%" : null,
        textAlign: isRTL === "rtl" ? "right" : null,
      },
    },
    {
      title: t("earning_amount"),
      field: "driverShare",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 40 : null },
    },
    {
      title: t("action"),
      field: "action",
      render: renderPaymentButton,
    },
  ];

  const [data, setData] = useState([]);
  const [viewMode, setViewMode] = useState("monthly");
  const [currentColumns, setCurrentColumns] = useState(monthlyColumns);

  const driverearningdata = useSelector((state) => state.driverearningdata);

  useEffect(() => {
    if (viewMode === "monthly") {
      setCurrentColumns(monthlyColumns);
      if (driverearningdata.driverearnings?.driverearningsMonthly) {
        setData(driverearningdata.driverearnings.driverearningsMonthly);
      }
    } else if (viewMode === "weekly") {
      setCurrentColumns(weeklyColumns);
      if (driverearningdata.driverearnings?.driverearningsWeekly) {
        setData(driverearningdata.driverearnings.driverearningsWeekly);
      }
    }
  }, [viewMode, driverearningdata.driverearnings, driverPaymentWeeks]);

  return (
    <div>
      {driverearningdata.loading ? (
        <CircularLoading />
      ) : (
        <div>
          <div style={{ marginBottom: "20px" }}>
            <label>
              <input
                type="radio"
                value="monthly"
                checked={viewMode === "monthly"}
                onChange={() => setViewMode("monthly")}
              />
              {t("monthly")}
            </label>
            <label style={{ marginLeft: "10px" }}>
              <input
                type="radio"
                value="weekly"
                checked={viewMode === "weekly"}
                onChange={() => setViewMode("weekly")}
              />
              {t("weekly")}
            </label>
          </div>
          <MaterialTable
            title={t("driver_earning")}
            columns={currentColumns}
            data={data}
            style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
            options={{
              exportButton: true,
              grouping: true,
            }}
            localization={{
              toolbar: {
                searchPlaceholder: t("search"),
                exportTitle: t("export"),
              },
              pagination: {
                labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
                labelRowsSelect: t("rows"),
                firstTooltip: t("first_page_tooltip"),
                previousTooltip: t("previous_page_tooltip"),
                nextTooltip: t("next_page_tooltip"),
                lastTooltip: t("last_page_tooltip"),
              },
            }}
          />
        </div>
      )}
    </div>
  );
}
