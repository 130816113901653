import React from "react";

export default function TermsEn() {
  return (
    <div>
      <p>Terms and Conditions</p>
      <p>Last updated: May 12th, 2023</p>
      <p>
        Please read these terms and conditions carefully before using Our
        Service.
      </p>
      <h1>Interpretation and Definitions</h1>
      <h3>Interpretation</h3>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <h3>Definitions</h3>
      <p>For the purposes of these Terms and Conditions:</p>
      <p>
        Application means the software program provided by the Company
        downloaded by You on any electronic device, named TWASAL AWASAL
      </p>
      <p>
        Application Store means the digital distribution service operated and
        developed by Apple Inc. (Apple App Store) or Google Inc. (Google Play
        Store) in which the Application has been downloaded.
      </p>
      <p>
        Company (referred to as either &quot;the Company&quot;, &quot;We&quot;,
        &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to The First
        Cargo Service SPC, Muscat, Uthaiba P.O. Box 470.
      </p>
      <p>
        Affiliate means an entity that controls, is controlled by or is under
        common control with a party, where &quot;control&quot; means ownership
        of 50% or more of the shares, equity interest or other securities
        entitled to vote for election of directors or other managing authority.
      </p>
      <p>
        Account means a unique account created for You to access our Service or
        parts of our Service.
      </p>
      <p>Country refers to: Oman</p>
      <p>
        Content refers to content such as text, images, or other information
        that can be posted, uploaded, linked to or otherwise made available by
        You, regardless of the form of that content.
      </p>
      <p>
        Device means any device that can access the Service such as a computer,
        a cellphone or a digital tablet.
      </p>
      <p>Goods refer to the items offered for sale on the Service.</p>
      <p>
        In-app Purchase refers to the purchase of a product, item, service or
        Subscription made through the Application and subject to these Terms and
        Conditions and/or the Application Store&#x27;s own terms and conditions.
      </p>
      <p>Booking mean a request by You to purchase Delivery Service from Us.</p>
      <p>
        Promotions refer to contests, sweepstakes or other promotions offered
        through the Service.
      </p>
      <p>Service refers to the Application or the Website or both.</p>
      <p>
        Subscriptions refer to the services or access to the Service offered on
        a subscription basis by the Company to You.
      </p>
      <p>
        Terms and Conditions (also referred as &quot;Terms&quot;) mean these
        Terms and Conditions that form the entire agreement between You and the
        Company regarding the use of the Service.
      </p>
      <p>
        Third-party Social Media Service means any services or content
        (including data, information, products or services) provided by a
        third-party that may be displayed, included or made available by the
        Service.
      </p>
      <p>Website refers to TWASAL AWASAL, accessible from</p>
      <p>
        You means the individual accessing or using the Service, or the company,
        or other legal entity on behalf of which such individual is accessing or
        using the Service, as applicable.
      </p>
      <h1>Acknowledgment</h1>
      <p>
        These are the Terms and Conditions governing the use of this Service and
        the agreement that operates between You and the Company. These Terms and
        Conditions set out the rights and obligations of all users regarding the
        use of the Service.
      </p>
      <p>
        Your access to and use of the Service is also conditioned on Your
        acceptance of and compliance with the Privacy Policy of the Company. Our
        Privacy Policy describes Our policies and procedures on the collection,
        use and disclosure of Your personal information when You use the
        Application or the Website and tells You about Your privacy rights and
        how the law protects You. Please read Our Privacy Policy carefully
        before using Our Service.
      </p>
      <h3>USERS</h3>
      <p>
        Your access to and use of the Service is conditioned on Your acceptance
        of and compliance with these Terms and Conditions. These Terms and
        Conditions apply to all visitors, users and others who access or use the
        Service.
      </p>
      <p>
        By accessing or using the Service You agree to be bound by these Terms
        and Conditions. If You disagree with any part of these Terms and
        Conditions, then You may not access the Service.
      </p>
      <p>
        You represent that you are over the age of 18. The Company does not
        permit those under 18 to use the Service. You agree to comply with all
        applicable laws and regulations.
      </p>
      <p></p>
      <p></p>
      <p>Terms of Use</p>
      <p>You acknowledge and consent to the following</p>
      <p>
        We use your personal information to take and handle orders, deliver
        products and services, process payments, and communicate with you about
        orders, products and services, and promotional offers.
      </p>
      <p>
        We use your personal information to provide functionality, analyses
        performance, fix errors, and improve usability and effectiveness of the
        TWASAL AWASAL Services.
      </p>
      <p>
        We use your personal information to recommend features, products, and
        services that might be of interest to you, identify your preferences,
        and personalize your experience with TWASAL AWASAL Services.
      </p>
      <p>
        When you use our voice, image and camera services, we process your voice
        input, images, videos, and other personal information to respond to your
        requests, provide the requested service to you, and improve our
        services.
      </p>
      <p>
        In certain cases, we collect and use your personal information to comply
        with laws.
      </p>
      <p>
        We use your personal information to communicate with you in relation to
        our Services via different channels (e.g., by phone, email, chat).
      </p>
      <p>
        We use your personal information to display interest-based ads for
        features, products, and services that might be of interest to you.
      </p>
      <p>
        We process personal information to prevent and detect fraud and abuse in
        order to protect the security of our customers and drivers. We may also
        use scoring methods to assess and manage credit risks.
      </p>
      <p>
        We use cookies and similar tools to enhance your delivery experience,
        provide our services, understand how customers use our services so we
        can make improvements, and display ads. Approved third parties also use
        these tools in connection with our display of ads.
      </p>
      <p>
        Purposes for which we seek your consent. We may also ask for your
        consent to process your personal information for a specific purpose that
        we communicate to you. When you consent to our processing your personal
        information for a specified purpose, you may withdraw your consent at
        any time, and we will stop processing of your data for that purpose.
      </p>
      <p>
        We may employ other companies and individuals to perform functions on
        our behalf. Examples include fulfilling orders for products or services,
        delivering packages, sending postal mail and e-mail, removing repetitive
        information from customer lists, analyzing data, providing marketing
        assistance, providing search results and links (including paid listings
        and links), processing payments, transmitting content, assessing and
        managing credit risk, and providing customer service. These third-party
        service providers have access to personal information needed to perform
        their functions but may not use it for other purposes. Further, they
        must process the personal information in accordance with this agreement
        and as permitted by applicable data protection laws.
      </p>
      <p></p>
      <p>
        Business transfers: As we continue to develop our business, we might
        sell, assign or buy other businesses or services. In such transactions,
        customer information generally is one of the transferred business assets
        but remains subject to the promises made in any pre-existing Privacy
        terms and conditions (unless, of course, the customer consents
        otherwise). Also, in the unlikely event that or substantially all of our
        assets are acquired, customer information will of course be one of the
        transferred assets.
      </p>
      <p>
        Confidentiality: You may be in receipt of information that it or they
        would not otherwise receive, and such information is to remain the
        proprietary right of Appswiz and deemed confidential and protected
        accordingly pursuant to the applicable laws
      </p>
      <p></p>
      <h3>Drivers</h3>
      <p>
        Your access to and use of the Service is conditioned on Your acceptance
        of and compliance with these Terms and Conditions. These Terms and
        Conditions apply to all Drivers.
      </p>
      <p>
        By accessing or using the Service You agree to be bound by these Terms
        and Conditions. If You disagree with any part of these Terms and
        Conditions, then You may not be legible proceed with the Driver Delivery
        job, hence not allowed to proceed with contract at the head office.
      </p>
      <p>
        You represent that you are over the age of 18. The Company does not
        permit those under 18 to use the Driver Application.
      </p>
      <p></p>
      <h1>Placing Booking for Services</h1>
      <p>
        By placing a Booking for our delivery through the Service, You warrant
        that You are legally capable of entering into binding contracts.
      </p>
      <h3>USERS</h3>
      <h3>Your Information</h3>
      <p>
        If You wish to place a Booking for Delivery available on the Service,
        You may be asked to supply certain information relevant to Your Booking
        including, without limitation, Your name, Your email, Your phone number,
        Your credit card number, the expiration date of Your credit card, Your
        billing address, and Your shipping information.
      </p>
      <p>
        You represent and warrant that: (i) You have the legal right to use any
        credit or debit card(s) or other payment method(s) in connection with
        any Booking; and that (ii) the information You supply to us is true,
        correct, and complete.
      </p>
      <p>
        By submitting such information, You grant us the right to provide the
        information to payment processing third parties for purposes of
        facilitating the completion of Your Booking.
      </p>
      <p>
        You shall ensure that services considers the following “Unacceptable
        Package “ if
      </p>
      <p>
        • it contains complete firearms, ammunition, explosives, explosive
        devices or test pieces, air guns, replica or imitation firearms;
        counterfeit goods; cash; bullion (of any precious metal); live animals,
        prohibited animal parts or remains, such as ivory; human remains or
        ashes; loose precious or semi-precious stones; cannabis or its
        derivatives; or illegal goods, such as narcotics or other illegal drugs,
        hazardous material, dangerous goods, prohibited or restricted articles
        under applicable law
      </p>
      <p>
        • it contains any other item which cannot be carried safely or legally,
      </p>
      <p>
        • the address is incorrect or not properly marked or its packaging is
        defective or inadequate to ensure safe transportation with ordinary care
        in handling, we will not responsible for the contents
      </p>
      <h3>Booking Cancellation</h3>
      <p>
        We reserve the right to refuse or cancel Your Booking at any time for
        certain reasons including but not limited to:
      </p>
      <p>Service availability</p>
      <p>Errors in the description or prices for Delivery Services.</p>
      <p>Errors in Your Booking.</p>
      <p>
        We reserve the right to refuse or cancel Your Booking if fraud or an
        unauthorized or illegal transaction is suspected.
      </p>
      <p>
        We will not accept responsibility for any server down time, server
        delayed, response time, network issues, or any other issues caused by
        interruption or intermittent issues of the underlying hosting service
        provider.
      </p>
      <p>Your Booking Cancellation Rights</p>
      <p>
        Any Service you purchase can only be returned in accordance with these
        Terms and Conditions and Our Returns Policy.
      </p>
      <p>
        Our Returns Policy forms a part of these Terms and Conditions. Please
        read our Returns Policy to learn more about your right to cancel Your
        Booking.
      </p>
      <p>
        Your right to cancel a Booking only applies to all Service that are not
        yet collected by Drivers. Other services such of booking breakdown and
        booking services can be applicable to cancel on arrival.
      </p>
      <p>
        We will reimburse You no later than 14 days from the day on which We
        received the booking. We will use TWASAL AWASAL e-wallet to add accredit
        for Your account, and for future Delivery Bookings, and You will not
        incur any fees for such compensations.
      </p>
      <p>
        You will not have any right to cancel a Booking for the Delivery of any
        of the following Services:
      </p>
      <p>
        The Delivery of Packages that was already collected and picked up by
        drivers.
      </p>
      <p>
        The Delivery of Packages which according to their nature are not
        suitable to be returned, deteriorate rapidly or where the date of expiry
        is over.
      </p>
      <p>
        The Delivery of Packages which are not suitable for return due to health
        protection or hygiene reasons and were unsealed after delivery.
      </p>
      <p>
        The Delivery of Packages which are, after delivery, according to their
        nature, inseparably mixed with other items.
      </p>
      <p>
        The delivery of services, such as breakdown of vehicles, after arriving
        location and starting the implementing the services.
      </p>
      <h3>Availability, Errors and Inaccuracies</h3>
      <p>
        We are constantly updating Our offerings of the Service. The delivery
        available on Our Service may be mispriced, described inaccurately, or
        unavailable, and We may experience delays in updating information
        regarding our deliveries on the Service and in Our advertising on other
        websites.
      </p>
      <p>
        We cannot and do not guarantee the accuracy or completeness of any
        information, including prices, specifications, availability, and
        services. We reserve the right to change or update information and to
        correct errors, inaccuracies, or omissions at any time without prior
        notice.
      </p>
      <p>
        We will not accept liability for any damages for loss of business or
        loss of profits arising from breach of this agreement or breach of any
        stated or perceived warranty and such exclusion of liability is to be
        read and understood as applying directly or indirectly with any party
        obtaining the Services.
      </p>
      <p>
        Driver will make every reasonable effort to deliver the package in a
        timely manner. Company is not liable for any damages or loss caused by
        delay, and shall entirely be the responsibility of the Driver
      </p>
      <p></p>
      <p></p>
      <p></p>
      <h3>Prices Policy</h3>
      <p>
        The Company reserves the right to revise its prices at any time prior to
        accepting a Booking.
      </p>
      <p>
        The prices quoted may be revised by the Company subsequent to accepting
        a Booking in the event of any occurrence affecting delivery caused by
        government action, variation in customs duties, increase on oil prices,
        increased shipping charges, and any other matter beyond the control of
        the Company. In that event, You will have the right to cancel Your
        Booking.
      </p>
      <h3>Payments</h3>
      <p>
        All Delivery Services Booked and Paid for are subject to a one-time
        payment. Payment can be made through various payment methods we have
        available, such as Visa, MasterCard, using existing credit on Your
        account e-wallet, or online payment methods (PayPal, for example).
      </p>
      <p>
        Payment cards (credit cards or debit cards) are subject to validation
        checks and authorization by Your card issuer. If we do not receive the
        required authorization, We will not be liable for any delay or
        non-delivery of Your Booking.
      </p>
      <h3>Drivers</h3>
      <h3>Your Information</h3>
      <p>
        If You wish to apply for Driver account on the Service, You may be asked
        to supply certain information relevant to Your personal information,
        without limitation, Your name, resident card, driving license, Good
        conduct and behavior certificate, Your email, Your phone number, Your
        credit card number, the expiration date of Your credit card, Your
        billing address, and proceed upon approval to head office for interview
        and contract signing.
      </p>
      <p>
        You represent and warrant that: (i) You have the legal right to work as
        driver through our services Application(s) to behave in ethical manners
        during duty(s) in engaging with any Booking client; and that (ii) the
        information You supply to us is true, correct, and complete.
      </p>
      <p>
        By submitting such information, You grant us the right to provide the
        information to third parties for purposes of facilitating the completion
        of Your delivery services, and legit to proceed with interview at the
        head office.
      </p>
      <p></p>
      <h3>Not- Accept a Booking Notification</h3>
      <p>
        You reserve the right to not- Accept User Booking at any time for
        certain reasons including but not limited to:
      </p>
      <p>Driver availability</p>
      <p>Driver Ability to accept a new User Booking</p>
      <p>Errors in the description or prices for Delivery Services.</p>
      <p>Errors in Booking.</p>
      <p>
        We reserve the right to refuse or cancel Booking if fraud or an
        unauthorized or illegal transaction is suspected
      </p>
      <h3>Driver/s Booking Cancellation Rights</h3>
      <p>
        Any Service Driver/s Accept can only be Cancelled in accordance with
        these Terms and Conditions and Our Returns Policy.
      </p>
      <p>
        Our Cancellation Policy forms a part of these Terms and Conditions.
        Please read our Cancellation Policy to learn more about your right to
        cancel User’s Booking.
      </p>
      <p>
        Driver/s right to cancel a Booking only applies to all Service that are
        not yet collected. Other services such of booking breakdown and booking
        services can be applicable to cancel on arrival.
      </p>
      <p>
        The act of cancellation from the User We will be reimbursed as in the
        User section of Booking Cancellation; however, Drivers will not
        reconcile at their weekly payments. You will not incur any compensations
        from User Cancellation.
      </p>
      <p>
        You will have right to cancel a Booking for the Delivery of any of the
        following Services:
      </p>
      <p>
        Circumstances out of control, such as an event of breakdown before
        reaching the pickup point.
      </p>
      <p>
        The Delivery of Packages that was not collected and picked up by
        drivers.
      </p>
      <p>
        The Delivery of Packages which according to their nature are not
        suitable to be delivered.
      </p>
      <p>The Delivery of Packages contains illegal content or substances.</p>
      <p>
        The Delivery of other services, such as breakdown of vehicles, after
        arriving location and starting the implementing the services.
      </p>
      <p>Availability, Errors, and Inaccuracies</p>
      <p>
        We are constantly updating Our offerings of the Service. The delivery
        available on Our Service may be mispriced, described inaccurately, or
        unavailable, and We may experience delays in updating information
        regarding our deliveries on the Service and in Our advertising on other
        websites.
      </p>
      <p>
        We cannot and do not guarantee the accuracy or completeness of any
        information, including prices, specifications, availability, and
        services. We reserve the right to change or update information and to
        correct errors, inaccuracies, or omissions at any time without prior
        notice.
      </p>
      <p>
        The failure to deliver the booked Package will hold the full
        responsibility unto the driver, as per the contract with the assigned
        driver on the job, the company holds no responsibility for whatsoever
        any missing items of delivered packages.
      </p>
      <h3>Compensation</h3>
      <p>
        Driver/s Compensation are subject to our contract at TWASAL AWASAL head
        quarter, subsequent to one week of duty, and after review and
        evaluation.
      </p>
      <p>
        Compensation is subject to validation checks and authorization by TWASAL
        AWASAL accounting Team. If we do not receive the required authorization,
        We will not be legible to issue for any payments.
      </p>
      <p></p>
      <h1>Subscriptions</h1>
      <h3>Subscription period</h3>
      <p>
        The Service or some parts of the Service are available only with a paid
        Subscription. You will be billed in advance on a recurring and periodic
        basis (such as daily, weekly, monthly or annually), depending on the
        type of Subscription plan you select when Booking the Subscription.
      </p>
      <p>
        At the end of each period, Your Subscription will automatically renew
        under the exact same conditions unless You cancel it, or the Company
        cancels it.
      </p>
      <h3>Subscription cancellations</h3>
      <p>
        You may cancel Your Subscription renewal either through Your Account
        settings page or by contacting the Company. You will not receive a
        refund for the fees You already paid for Your current Subscription
        period and You will be able to access the Service until the end of Your
        current Subscription period.
      </p>
      <p>
        If the Subscription has been made through an In-app Purchase, You can
        cancel the renewal of Your Subscription with the Application Store.
      </p>
      <h3>Billing</h3>
      <p>
        You shall provide the Company with accurate and complete billing
        information including full name, address, state, zip code, telephone
        number, and a valid payment method information.
      </p>
      <p>
        Should automatic billing fail to occur for any reason, the Company will
        issue an electronic invoice indicating that you must proceed manually,
        within a certain deadline date, with the full payment corresponding to
        the billing period as indicated on the invoice.
      </p>
      <p>
        If the Subscription has been made through an In-app Purchase, all
        billing is handled by the Application Store and is governed by the
        Application Store&#x27;s own terms and conditions.
      </p>
      <h3>Fee Changes</h3>
      <p>
        The Company, in its sole discretion and at any time, may modify the
        Subscription fees. Any Subscription fee change will become effective at
        the end of the then-current Subscription period.
      </p>
      <p>
        The Company will provide You with reasonable prior notice of any change
        in Subscription fees to give You an opportunity to terminate Your
        Subscription before such change becomes effective.
      </p>
      <p>
        Your continued use of the Service after the Subscription fee change
        comes into effect constitutes Your agreement to pay the modified
        Subscription fee amount.
      </p>
      <h3>Refunds</h3>
      <p>
        Except when required by law, paid Subscription fees are non-refundable.
      </p>
      <p>
        Certain refund requests for Subscriptions may be considered by the
        Company on a case-by-case basis and granted at the sole discretion of
        the Company.
      </p>
      <p>
        If the Subscription has been made through an In-app purchase, the
        Application Store&#x27;s refund policy will apply. If You wish to
        request a refund, You may do so by contacting the Application Store
        directly.
      </p>
      <h1>In-app Purchases</h1>
      <p>
        The Application may include In-app Purchases that allow you to buy
        products, services or Subscriptions.
      </p>
      <p>
        More information about how you may be able to manage In-app Purchases
        using your Device may be set out in the Application Store&#x27;s own
        terms and conditions or in your Device&#x27;s Help settings.
      </p>
      <p>
        In-app Purchases can only be consumed within the Application. If you
        make a In-app Purchase, that In-app Purchase cannot be cancelled after
        you have initiated its download. In-app Purchases cannot be redeemed for
        cash or other consideration or otherwise transferred.
      </p>
      <p>
        If any In-app Purchase is not successfully downloaded or does not work
        once it has been successfully downloaded, we will, after becoming aware
        of the fault or being notified to the fault by You, investigate the
        reason for the fault. We will act reasonably in deciding whether to
        provide You with a replacement In-app Purchase or issue You with a patch
        to repair the fault. In no event will We charge You to replace or repair
        the In-app Purchase. In the unlikely event that we are unable to replace
        or repair the relevant In-app Purchase or are unable to do so within a
        reasonable period of time and without significant inconvenience to You,
        We will authorize the Application Store to refund You an amount up to
        the cost of the relevant In-app Purchase. Alternatively, if You wish to
        request a refund, You may do so by contacting the Application Store
        directly.
      </p>
      <p>
        The company will not be responsible in any way for any defect in the
        materials transported by the delivery party, whether it was done by
        individuals or companies, and accordingly, communication takes place
        from both the transport and receiving parties, due to the company&#x27;s
        commitment to the privacy of transporting the goods.
      </p>
      <p></p>
      <p>Store pickup policies:</p>
      <p>
        Delivering the shipment to the representative and presenting it to him
        before delivery to verify its condition with the representative&#x27;s
        signature to confirm correct receipt.
      </p>
      <p>
        The delivery person delivers the shipment to the other store with its
        status confirmed by an operation officer.
      </p>
      <p>
        The delivery person checks the order before it goes out and if the order
        is left unchecked, it means that the order is in good condition, and
        this entails a full liability on the part of the delivery person if
        anything happens.
      </p>
      <p></p>
      <p>
        TWASAL AWASAL is not responsible for applications that come out without
        a document showing:
      </p>
      <p>The name of the recipient</p>
      <p>
        The name of the sender according to the name given to the company upon
        contracting
      </p>
      <p>order number</p>
      <p>Recipient&#x27;s phone number</p>
      <p>Sender&#x27;s phone number</p>
      <p>Region and detailed address of the order</p>
      <p></p>
      <p></p>
      <p></p>
      <p>
        You acknowledge and agree that all billing and transaction processes are
        handled by the Application Store from where you downloaded the
        Application and are governed by that Application Store&#x27;s own terms
        and conditions.
      </p>
      <p>
        If you have any payment related issues with In-app Purchases, then you
        need to contact the Application Store directly.
      </p>
      <h1>Promotions</h1>
      <p>
        Any Promotions made available through the Service may be governed by
        rules that are separate from these Terms.
      </p>
      <p>
        If You participate in any Promotions, please review the applicable rules
        as well as our Privacy policy. If the rules for a Promotion conflict
        with these Terms, the Promotion rules will apply.
      </p>
      <h1>User Accounts</h1>
      <p>
        When You create an account with Us, You must provide Us information that
        is accurate, complete, and current at all times. Failure to do so
        constitutes a breach of the Terms, which may result in immediate
        termination of Your account on Our Service.
      </p>
      <p>
        You are responsible for safeguarding the password that You use to access
        the Service and for any activities or actions under Your password,
        whether Your password is with Our Service or a Third-Party Social Media
        Service.
      </p>
      <p>
        You agree not to disclose Your password to any third party. You must
        notify Us immediately upon becoming aware of any breach of security or
        unauthorized use of Your account.
      </p>
      <p>
        You may not use as a username the name of another person or entity or
        that is not lawfully available for use, a name or trademark that is
        subject to any rights of another person or entity other than You without
        appropriate authorization, or a name that is otherwise offensive, vulgar
        or obscene.
      </p>
      <p></p>
      <p></p>
      <h1>Driver Accounts</h1>
      <p>
        When You create a Driver account with Us, You must provide Us
        information that is accurate, complete, and current at all times, Driver
        will be valid and active after signing a contract by visiting our HR
        office, Failure to do so constitutes a breach of the Terms, which may
        result in immediate termination of Your application to request for
        account on Our Service.
      </p>
      <p>
        You are responsible for safeguarding the password that You use to access
        the driver account and for any activities or actions under Your
        password, whether Your password is with Our Service or a Third-Party
        Social Media Service.
      </p>
      <p>
        You agree not to disclose Your password to any third party. You must
        notify Us immediately upon becoming aware of any breach of security or
        unauthorized use of Your Driver account.
      </p>
      <p>
        You may not use as a username the name of another person or entity or
        that is not lawfully available for use, a name or trademark that is
        subject to any rights of another person or entity other than You without
        appropriate authorization, or a name that is otherwise offensive, vulgar
        or obscene.
      </p>
      <p></p>
      <p></p>
      <h1>Content</h1>
      <h3>Your Right to Post review Content</h3>
      <p>
        Our Service allows You to post Review Content. You are responsible for
        the Content that You post about the Service, including its legality,
        reliability, and appropriateness.
      </p>
      <p>
        By posting Content about the Service, You grant Us the right and license
        to use, modify, publicly perform, publicly display, reproduce, and
        distribute such Content on and through the Service. You retain any and
        all of Your rights to any Content You submit, post or display on or
        through the Service and You are responsible for protecting those rights.
        You agree that this license includes the right for Us to make Your
        Content available to other users of the Service, who may also use Your
        Content subject to these Terms.
      </p>
      <p>
        You represent and warrant that: (i) the Content is Yours (You own it) or
        You have the right to use it and grant Us the rights and license as
        provided in these Terms, and (ii) the posting of Your Content on or
        through the Service does not violate the privacy rights, publicity
        rights, copyrights, contract rights or any other rights of any person.
      </p>
      <p></p>
      <h3>Review Content Restrictions</h3>
      <p>
        The Company is not responsible for the content of the Service&#x27;s
        users. You expressly understand and agree that You are solely
        responsible for the Content and for all activity that occurs under your
        account, whether done so by You or any third person using Your account.
      </p>
      <p>
        You may not transmit any Content that is unlawful, offensive, upsetting,
        intended to disgust, threatening, libelous, defamatory, obscene or
        otherwise objectionable. Examples of such objectionable Content include,
        but are not limited to, the following:
      </p>
      <p>Unlawful or promoting unlawful activity.</p>
      <p>
        Defamatory, discriminatory, or mean-spirited content, including
        references or commentary about religion, race, sexual orientation,
        gender, national/ethnic origin, or other targeted groups.
      </p>
      <p>
        Spam, machine – or randomly – generated, constituting unauthorized or
        unsolicited advertising, chain letters, any other form of unauthorized
        solicitation, or any form of lottery or gambling.
      </p>
      <p>
        Containing or installing any viruses, worms, malware, trojan horses, or
        other content that is designed or intended to disrupt, damage, or limit
        the functioning of any software, hardware or telecommunications
        equipment or to damage or obtain unauthorized access to any data or
        other information of a third person.
      </p>
      <p>
        Infringing on any proprietary rights of any party, including patent,
        trademark, trade secret, copyright, right of publicity or other rights.
      </p>
      <p>
        Impersonating any person or entity including the Company and its
        employees or representatives.
      </p>
      <p>Violating the privacy of any third person.</p>
      <p>False information and features.</p>
      <p>
        The Company reserves the right, but not the obligation, to, in its sole
        discretion, determine whether or not any review Content is appropriate
        and complies with this Terms, refuse or remove this Content. The Company
        further reserves the right to make formatting and edits and change the
        manner of any Content. The Company can also limit or revoke the use of
        the Service if You post such objectionable Content. As the Company
        cannot control all content posted by users and/or third parties on the
        Service, you agree to use the Service at your own risk. You understand
        that by using the Service You may be exposed to content that You may
        find offensive, indecent, incorrect or objectionable, and You agree that
        under no circumstances will the Company be liable in any way for any
        content, including any errors or omissions in any content, or any loss
        or damage of any kind incurred as a result of your use of any content.
      </p>
      <h3>Content Backups</h3>
      <p>
        Although regular backups of Content are performed, the Company does not
        guarantee there will be no loss or corruption of data.
      </p>
      <p>
        Corrupt or invalid backup points may be caused by, without limitation,
        Content that is corrupted prior to being backed up or that changes
        during the time a backup is performed.
      </p>
      <p>
        The Company will provide support and attempt to troubleshoot any known
        or discovered issues that may affect the backups of Content. But You
        acknowledge that the Company has no liability related to the integrity
        of Content or the failure to successfully restore Content to a usable
        state.
      </p>
      <p>
        You agree to maintain a complete and accurate copy of any Content in a
        location independent of the Service.
      </p>
      <h1>TWASAL AWASAL Intellectual Property</h1>
      <p>
        The Service and its original content (excluding Content provided by You
        or other users), features and functionality are and will remain the
        exclusive property of the Company and its licensors.
      </p>
      <p>
        The Service is protected by copyright, trademark, and other laws of both
        the Country and foreign countries.
      </p>
      <p>
        Our trademarks and trade dress may not be used in connection with any
        product or service without the prior written consent of the Company.
      </p>
      <h1>Copyright Policy</h1>
      <h3>Intellectual Property Infringement</h3>
      <p>
        We respect the intellectual property rights of others. It is Our policy
        to respond to any claim that Content posted on the Service infringes a
        copyright or other intellectual property infringement of any person.
      </p>
      <p>
        If You are a copyright owner, or authorized on behalf of one, and You
        believe that the copyrighted work has been copied in a way that
        constitutes copyright infringement that is taking place through the
        Service, You must submit Your notice in writing to the attention of our
        copyright agent via email at info@twasalawasal.com and include in Your
        notice a detailed description of the alleged infringement.
      </p>
      <p>
        You may be held accountable for damages (including costs and
        attorneys&#x27; fees) for misrepresenting that any Content is infringing
        Your copyright.
      </p>
      <p>Copyright Infringement Claims</p>
      <p>
        You may submit a notification pursuant to Oman Copyright Act by
        providing our Copyright Agent with the following information in writing
        for further detail):
      </p>
      <p>
        An electronic or physical signature of the person authorized to act on
        behalf of the owner of the copyright&#x27;s interest.
      </p>
      <p>
        A description of the copyrighted work that You claim has been infringed,
        including the URL (i.e., web page address) of the location where the
        copyrighted work exists or a copy of the copyrighted work.
      </p>
      <p>
        Identification of the URL or other specific location on the Service
        where the material that You claim is infringing is located.
      </p>
      <p>Your address, telephone number, and email address.</p>
      <p>
        A statement by You that You have a good faith belief that the disputed
        use is not authorized by the copyright owner, its agent, or the law.
      </p>
      <p>
        A statement by You, made under penalty of perjury, that the above
        information in Your notice is accurate and that You are the copyright
        owner or authorized to act on the copyright owner&#x27;s behalf.
      </p>
      <p>
        You can contact our copyright agent via email at info@twasalawasal.com.
        Upon receipt of a notification, the Company will take whatever action,
        in its sole discretion, it deems appropriate, including removal of the
        challenged content from the Service.
      </p>
      <p></p>
      <h1>Links to Other Websites</h1>
      <p>
        Our Service may contain links to third-party web sites or services that
        are not owned or controlled by the Company.
      </p>
      <p>
        The Company has no control over, and assumes no responsibility for, the
        content, privacy policies, or practices of any third-party web sites or
        services. You further acknowledge and agree that the Company shall not
        be responsible or liable, directly or indirectly, for any damage or loss
        caused or alleged to be caused by or in connection with the use of or
        reliance on any such content, goods or services available on or through
        any such web sites or services.
      </p>
      <p>
        We strongly advise You to read the terms and conditions and privacy
        policies of any third-party web sites or services that You visit.
      </p>
      <h1>Termination</h1>
      <h3>USERS</h3>
      <p>
        We may terminate or suspend Your Account immediately, without prior
        notice or liability, for any reason whatsoever, including without
        limitation if You breach these Terms and Conditions.
      </p>
      <p>
        Upon termination, Your right to use the Service will cease immediately.
        If You wish to terminate Your Account, You may simply discontinue using
        the Service.
      </p>
      <h3>Drivers</h3>
      <p>
        We may terminate or suspend Your Driver Account immediately, without
        prior notice or liability, for any reason whatsoever, including without
        limitation if You breach these Terms and Conditions, and after the
        following:
      </p>
      <p>Dishonesty.</p>
      <p>Failed to commit on time and delivery rout.</p>
      <p>Sexual harassment.</p>
      <p>Poor reviews by users.</p>
      <p>Protected and neat packages.</p>
      <p>Comply the confidentiality.</p>
      <p>
        Upon termination, Your right to use the Service will cease immediately.
        If You wish to terminate Your Account, You may simply discontinue using
        the Service, after notifying the company at head office.
      </p>
      <h1>Limitation of Liability</h1>
      <p>
        Notwithstanding any damages that You might incur, the entire liability
        of such events are on the Driver/s responsibility, under provision of
        this Terms and Your exclusive remedy for all of the foregoing shall be
        limited to the amount of the package delivered and actually paid by You
        through the Service.
      </p>
      <p>
        To the maximum extent permitted by applicable law, in no event shall the
        Company or its suppliers be liable for any special, incidental,
        indirect, or consequential damages whatsoever (including, but not
        limited to, damages for loss of profits, loss of data or other
        information, for business interruption, for personal injury, loss of
        privacy arising out of or in any way related to the use of or inability
        to use the Service, third-party software and/or third-party hardware
        used with the Service, or otherwise in connection with any provision of
        this Terms), even if the Company or any supplier has been advised of the
        possibility of such damages and even if the remedy fails of its
        essential purpose.
      </p>
      <p>
        Some states do not allow the exclusion of implied warranties or
        limitation of liability for incidental or consequential damages, which
        means that some of the above limitations may not apply. In these states,
        each party&#x27;s liability will be limited to the greatest extent
        permitted by law.
      </p>
      <p>Force Majeure</p>
      <p>
        Company is not liable for any loss or damage arising out of
        circumstances beyond company’s control or arising out of any “Force
        Majeure” instances - e.g. earthquake, cyclone, storm, flood, fog, war,
        plane crash, embargo, riot, epidemic, pandemic, civil commotion, or
        industrial action.
      </p>
      <p>&quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer</p>
      <p>
        The Service is provided to You &quot;AS IS&quot; and &quot;AS
        AVAILABLE&quot; and with all faults and defects without warranty of any
        kind. To the maximum extent permitted under applicable law, the Company,
        on its own behalf and on behalf of its Affiliates and its and their
        respective licensors and service providers, expressly disclaims all
        warranties, whether express, implied, statutory or otherwise, with
        respect to the Service, including all implied warranties of
        merchantability, fitness for a particular purpose, title and
        non-infringement, and warranties that may arise out of course of
        dealing, course of performance, usage or trade practice. Without
        limitation to the foregoing, the Company provides no warranty or
        undertaking, and makes no representation of any kind that the Service
        will meet Your requirements, achieve any intended results, be compatible
        or work with any other software, applications, systems, or services,
        operate without interruption, meet any performance or reliability
        standards or be error free or that any errors or defects can or will be
        corrected.
      </p>
      <p>
        Without limiting the foregoing, neither the Company nor any of the
        company&#x27;s provider makes any representation or warranty of any
        kind, express or implied: (i) as to the operation or availability of the
        Service, or the information, content, and materials or products included
        thereon; (ii) that the Service will be uninterrupted or error-free;
        (iii) as to the accuracy, reliability, or currency of any information or
        content provided through the Service; or (iv) that the Service, its
        servers, the content, or e-mails sent from or on behalf of the Company
        are free of viruses, scripts, trojan horses, worms, malware, timebombs
        or other harmful components.
      </p>
      <p>
        It is not allowing the exclusion of certain types of warranties or
        limitations on applicable statutory rights of a consumer, so some or all
        of the above exclusions and limitations may not apply to You. But in
        such a case the exclusions and limitations set forth in this section
        shall be applied to the greatest extent enforceable under applicable law
        in the Sultanate of Oman.
      </p>
      <h1>Governing Law</h1>
      <p>
        The laws of the Sultanate of Oman, excluding its conflicts of law rules,
        shall govern this Terms and Your use of the Service. Your use of the
        Application may also be subject to other local, state, national, or
        international laws.
      </p>
      <h1>Disputes Resolution</h1>
      <p>
        If You have any concern or dispute about the Service, You agree to first
        try to resolve the dispute informally by contacting the Company.
      </p>
      <h1>For Gulf Cooperation Council (GCC) Users</h1>
      <p>
        If You are a Gulf consumer, you will benefit from any mandatory
        provisions of the law of the country in which you are resident in.
      </p>
      <h1>The Sultanate of Oman Government End Use Provisions</h1>
      <p>
        If You are an Oman government end user, our Service is a
        &quot;Commercial Item&quot; as that term is Oman&#x27;s Personal Data
        Protection Law (PDPL).
      </p>
      <h1>Sultanate of Oman Legal Compliance</h1>
      <p>
        You represent and warrant that (i) You are not located in a country that
        is subject to the Sultanate of Oman government embargo, or that has been
        designated by the Sultanate of Oman government as a &quot;terrorist
        supporting&quot; country, and (ii) You are not listed on any Sultanate
        of Oman government list of prohibited or restricted parties.
      </p>
      <h1>Severability and Waiver</h1>
      <h3>Severability</h3>
      <p>
        If any provision of these Terms is held to be unenforceable or invalid,
        such provision will be changed and interpreted to accomplish the
        objectives of such provision to the greatest extent possible under
        applicable law and the remaining provisions will continue in full force
        and effect.
      </p>
      <h3>Waiver</h3>
      <p>
        Except as provided herein, the failure to exercise a right or to require
        performance of an obligation under these Terms shall not affect a
        party&#x27;s ability to exercise such right or require such performance
        at any time thereafter nor shall the waiver of a breach constitute a
        waiver of any subsequent breach.
      </p>
      <h1>Translation Interpretation</h1>
      <p>
        These Terms and Conditions may have been translated if We have made them
        available to You on our Service. You agree that original Arabic text
        shall prevail in the case of a dispute.
      </p>
      <h1>Changes to These Terms and Conditions</h1>
      <p>
        We reserve the right, at Our sole discretion, to modify or replace these
        Terms at any time. If a revision is material, We will make reasonable
        efforts to provide at least 30 days&#x27; notice prior to any new terms
        taking effect. What constitutes a material change will be determined at
        Our sole discretion.
      </p>
      <p>
        By continuing to access or use Our Service after those revisions become
        effective, You agree to be bound by the revised terms. If You do not
        agree to the new terms, in whole or in part, please stop using the
        website and the Service.
      </p>
      <h1>Contact Us</h1>
      <p>
        If you have any questions about these Terms and Conditions, You can
        contact us:
      </p>
      <p>By email: info@twasalawasal.com</p>
    </div>
  );
}
