/*eslint-disable*/
import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import { Info, AccountBox, House } from "@material-ui/icons";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Select, MenuItem } from "@material-ui/core";
import moment from "moment/min/moment-with-locales";
import classNames from "classnames";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";
import Hidden from "@material-ui/core/Hidden";
import { FirebaseContext } from "common";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const { api } = useContext(FirebaseContext);
  const { signOut } = api;
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);
  const settings = useSelector((state) => state.settingsdata.settings);
  const languagedata = useSelector((state) => state.languagedata);
  const { i18n, t } = useTranslation();
  const [loggedIn, setLoggedIn] = useState(false);
  let history = useHistory();

  const dispatch = useDispatch();
  const LogOut = () => {
    dispatch(signOut());
  };

  const [langSelection, setLangSelection] = useState();
  const [multiLanguage, setMultiLanguage] = useState();

  const handleLanguageSelect = (event) => {
    i18n.addResourceBundle(multiLanguage[event.target.value].langLocale, "translations", multiLanguage[event.target.value].keyValuePairs);
    i18n.changeLanguage(multiLanguage[event.target.value].langLocale);
    setLangSelection(event.target.value);
    moment.locale(multiLanguage[event.target.value].dateLocale);
    document.body.classList.remove('ltr');
    document.body.classList.remove('rtl');
    document.body.classList.add(i18n.dir());
  };

  useEffect(() => {
    if (languagedata.langlist) {
      for (const key of Object.keys(languagedata.langlist)) {
        if (languagedata.langlist[key].langLocale === i18n.language) {
          setLangSelection(key);
        }
      }
      setMultiLanguage(languagedata.langlist);
    }
  }, [languagedata.langlist]);

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [auth.info]);

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const anchorRef = React.useRef(null);
  const anchorRef2 = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleToggle2 = () => {
    setOpen2((prevOpen2) => !prevOpen2);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleClose2 = (event) => {
    if (anchorRef2.current && anchorRef2.current.contains(event.target)) {
      return;
    }
    setOpen2(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  function handleListKeyDown2(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen2(false);
    } else if (event.key === "Escape") {
      setOpen2(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  const prevOpen2 = React.useRef(open2);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    if (prevOpen2.current === true && open2 === false) {
      anchorRef2.current.focus();
    }
    prevOpen2.current = open2;
  }, [open2]);

  function AccountDrop(loggedIn) {
    if (loggedIn.loggedIn) {
      return (
        <ListItem className={classNames(classes.listItem, "NavUser")}>
          <Button ref={anchorRef} id='composition-button'  onClick={handleToggle} className={"headerUserBlock"}>
            <span className={"btnImg"}>
              <img src={require("../../assets/img/user.svg").default} alt='User' />
            </span>
            <span className={"btnLabel"}>{t("myaccount")} </span>
          </Button>
          {open ? (
            <Paper className='topDropDown'>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} id='composition-menu' aria-labelledby='composition-button' onKeyDown={handleListKeyDown}>
                <MenuItem
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/bookings");
                  }}>
                  {t("booking_title")}
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/profile");
                  }}>
                  {t("profile")}
                </MenuItem>
                <MenuItem onClick={LogOut}> {t("logout")}</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
          ) : null}
        </ListItem>
      );
    } else {
      return (
        <ListItem className={classNames(classes.listItem, "NavItem")}>
          <Button
            color='transparent'
            className={classNames(classes.navLink, "NavLogin")}
            onClick={(e) => {
              e.preventDefault();
              history.push("/login");
            }}>
            {t("login_signup")}
          </Button>
        </ListItem>
      );
    }
  }

  function RegisterDrop(loggedIn) {
    if (!loggedIn.loggedIn) {
      return (
        <ListItem className={classNames(classes.listItem, "NavItem")}>
          <Button className={classNames(classes.navLink, "NavRegister")} ref={anchorRef2} id='composition-button2' onClick={handleToggle2}>
            {t("register")}
          </Button>
          {open2 ? (
            <Paper className='topDropDown'>
              <ClickAwayListener onClickAway={handleClose2}>
                <MenuList autoFocusItem={open} id='composition-menu' aria-labelledby='composition-button' onKeyDown={handleListKeyDown2}>
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      // window.location.assign(
                      //   "https://tawasal-awasal-v-1-5.web.app/register"
                      // );
                      history.push("/register");
                    }}>
                    {t("register_rider")}
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      // window.location.assign(
                      //   "https://tawasal-awasal-v-1-5.web.app/register-driver"
                      // );
                      history.push("/register-driver");
                    }}>
                    {t("register_driver")}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          ) : null}
        </ListItem>
      );
    } else {
      return null;
    }
  }

  return (
    <List className={classNames(classes.list)}>
      <ListItem className={classNames(classes.listItem, "NavItem")}>
        <Button
          color='transparent'
          className={classes.navLink}
          onClick={(e) => {
            e.preventDefault();
            history.push("/");
          }}>
          {t("home")}
        </Button>
      </ListItem>
      {/* <ListItem className={classNames(classes.listItem, "NavItem")}>
          <Button
            color="transparent"
            className={classes.navLink}
            onClick={(e) => {
              e.preventDefault();
              history.push("/");
            }}
          >
            {t("services")}
          </Button>
        </ListItem> */}
      {/* <ListItem className={classNames(classes.listItem, "NavItem")}>
          <Button
            color="transparent"
            className={classes.navLink}
            onClick={(e) => {
              e.preventDefault();
              history.push("/");
            }}
          >
            Quick Delivery
          </Button>
        </ListItem> */}
      {/* <ListItem className={classNames(classes.listItem, "NavItem")}>
          <Button
            color="transparent"
            className={classes.navLink}
            onClick={(e) => {
              e.preventDefault();
              history.push("/");
            }}
          >
            Track Order
          </Button>
        </ListItem> */}

      <ListItem className={classNames(classes.listItem, "NavItem")}>
        <Button
          color='transparent'
          className={classes.navLink}
          onClick={(e) => {
            e.preventDefault();
            history.push("/about-us");
          }}>
          {t("about_us")}
        </Button>
      </ListItem>
      <AccountDrop loggedIn={loggedIn} />
      <RegisterDrop loggedIn={loggedIn} />
      <Hidden mdDown>
        <ListItem className={classes.listItem + " NavItemLng"}>
          {multiLanguage ? (
            <Select className={"NavLng"} id='booking-type-native1' value={langSelection} onChange={handleLanguageSelect}>
              {Object.keys(multiLanguage).map((key) => (
                <MenuItem key={key} value={key} className={"NavLngItem"}>
                  {multiLanguage[key].langName}
                </MenuItem>
              ))}
            </Select>
          ) : null}
        </ListItem>
      </Hidden>
      <Hidden mdUp>
        <ListItem className={classes.listItem + " NavItem LanguageMenuDropDown"}>
          {multiLanguage ? (
            <Select className={"navLink"} id='booking-type-native1' value={langSelection} onChange={handleLanguageSelect}>
              {Object.keys(multiLanguage).map((key) => (
                <MenuItem key={key} value={key} className={"NavLngItem"}>
                  {multiLanguage[key].langName}
                </MenuItem>
              ))}
            </Select>
          ) : null}
        </ListItem>
      </Hidden>
    </List>
  );
}
