import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import image from "assets/img/background.jpg";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import AlertDialog from "../components/AlertDialog";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
/*global google*/
import { FirebaseContext } from "common";
import { useTranslation } from "react-i18next";
import CircularLoading from "../components/CircularLoading";
import classNames from "classnames";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const { api, authRef, RecaptchaVerifier } = useContext(FirebaseContext);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    clearLoginError,
    mainSignUp,
    mobileSignIn,
    checkUserExists,
    validateReferer,
    signIn,
    countries,
    signOut,
  } = api;

  const auth = useSelector((state) => state.auth);
  const settings = useSelector((state) => state.settingsdata.settings);
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    customerType: "individual",
    firstName: "",
    company: "",
    email: "",
    mobile: "",
    usertype: "rider",
    referralId: "",
    location: { lat: 23.5809211, lng: 58.3199721 },
    otp: "",
  });

  //dispatch(signOut());

  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [countryCode, setCountryCode] = useState();
  const [mobileWithoutCountry, setMobileWithoutCountry] = useState("");
  const [verifiedOtp, setVerifiedOtp] = useState(false);
  const [resendOtp, setResendOtp] = useState(false);
  const [capatchaReady, setCapatchaReady] = React.useState(false);
  const [verificationId, setverificationId] = useState(null);
  const [load, setLoad] = useState(false);

  // useEffect(() => {
  //   if (state.location == null) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) =>
  //         setState({
  //           ...state,
  //           location: {
  //             lat: position.coords.latitude,
  //             lng: position.coords.longitude,
  //           },
  //         }),
  //       (error) => console.log(error)
  //     );
  //   }
  // });

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) =>
        setState({
          ...state,
          location: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        }),
      (error) => console.log(error)
    );
  };

  useEffect(() => {
    if (!capatchaReady) {
      window.recaptchaVerifier = new RecaptchaVerifier("sign-in-button", {
        size: "invisible",
        callback: function (response) {
          setCapatchaReady(true);
        },
      });
    }
  }, [capatchaReady, RecaptchaVerifier]);

  useEffect(() => {
    if (auth.info) {
      if (auth.info.profile) {
        let role = auth.info.profile.usertype;
        if (
          role === "admin" ||
          role === "fleetadmin" ||
          role === "financeadmin"
        ) {
          props.history.push("/dashboard");
        } else if (role === "driver") {
          props.history.push("/bookings");
        } else {
          props.history.push("/");
        }
      }
    }
  }, []);

  useEffect(() => {
    if (settings) {
      for (let i = 0; i < countries.length; i++) {
        if (countries[i].label === settings.country) {
          setCountryCode(countries[i]);
        }
      }
    }
  }, [settings, countries]);

  const classes = useStyles();
  const { ...rest } = props;

  const onInputChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
  };

  const handleChangeRadio = (event) => {
    setState({ ...state, customerType: event.target.value });
  };

  const validateMobile = () => {
    let mobileValid = true;
    if (mobileWithoutCountry.length < 6) {
      mobileValid = false;
      setCommonAlert({ open: true, msg: t("mobile_no_blank_error") });
    } else if (mobileWithoutCountry.charAt(0) === "0") {
      mobileValid = false;
      setCommonAlert({ open: true, msg: t("no_zero_mobile_number") });
    } else if (
      mobileWithoutCountry.includes("+") ||
      mobileWithoutCountry.includes(" ") ||
      mobileWithoutCountry.includes("-")
    ) {
      mobileValid = false;
      setCommonAlert({ open: true, msg: t("mobile_no_blank_error") });
    } else if (mobileWithoutCountry.length !== 8) {
      mobileValid = false;
      setCommonAlert({ open: true, msg: t("mobile_no_valid_oman") });
    }
    return mobileValid;
  };

  const validateInputs = () => {
    //eslint-disable-next-line
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      state.customerType === "company" &&
      !(/\S/.test(state.company) && state.company.length > 0)
    ) {
      setCommonAlert({ open: true, msg: t("proper_input_company") });
      return false;
    } else if (!/\S/.test(state.firstName) && state.firstName.length > 0) {
      setCommonAlert({ open: true, msg: t("proper_input_name") });
      return false;
    } else if (!re.test(state.email)) {
      setCommonAlert({ open: true, msg: t("proper_email") });
      return false;
    } else if (!validateMobile()) {
      return false;
    } else if (
      state.location === null ||
      state.location.lat === 0 ||
      state.location.lng === 0
    ) {
      setCommonAlert({ open: true, msg: t("error_location") });
      return false;
    } else if (!verifiedOtp) {
      setCommonAlert({ open: true, msg: t("error_otp") });
      return false;
    }
    return true;
  };

  const requestOtp = (e) => {
    e.preventDefault();
    if (validateMobile()) {
      setLoad(true);
      const phoneNumber = state.mobile;
      const appVerifier = window.recaptchaVerifier;
      authRef
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((res) => {
          setverificationId(res);
          setLoad(false);
          setTimeout(() => {
            if (!verifiedOtp) setResendOtp(true);
          }, 30000);
        })
        .catch((error) => {
          setCommonAlert({ open: true, msg: error.message });
          setLoad(false);
        });
    }
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    const otp = state.otp;
    setLoad(true);
    if (otp.length === 0) {
      setCommonAlert({ open: true, msg: "Please enter the otp." });
    } else {
      const final = verificationId;
      if (final === null) {
        setCommonAlert({ open: true, msg: "Please try Again" });
      } else {
        final
          .confirm(otp)
          .then((result) => {
            setVerifiedOtp(true);
            setLoad(false);
            setCommonAlert({ open: true, msg: "OTP Verified Successfully." });
          })
          .catch((error) => {
            setLoad(false);
            setCommonAlert({ open: true, msg: error.message });
            setResendOtp(true);
          });
      }
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();
    if (validateInputs()) {
      setLoad(true);
      checkUserExists(state).then((res) => {
        if (res.users && res.users.length > 0) {
          setCommonAlert({ open: true, msg: t("user_exists") });
          setLoad(false);
        } else if (res.error) {
          setCommonAlert({ open: true, msg: t("email_or_mobile_issue") });
          setLoad(false);
        } else {
          if (state.referralId && state.referralId.length > 0) {
            validateReferer(state.referralId)
              .then((referralInfo) => {
                if (referralInfo.uid) {
                  mainSignUp({
                    ...state,
                    signupViaReferral: referralInfo.uid,
                  }).then((res) => {
                    if (res.uid) {
                      setCommonAlert({
                        open: true,
                        msg: t("account_create_successfully"),
                      });
                      setLoad(false);
                      //dispatch(mobileSignIn(verificationId.verificationId, state.otp));
                      dispatch(signOut());
                      setTimeout(() => {
                        props.history.push("/");
                      }, 5000);
                    } else {
                      if (res.error.code === "auth/invalid-phone-number") {
                        setCommonAlert({
                          open: true,
                          msg: t("invalid_mobile_number"),
                        });
                      } else {
                        setCommonAlert({ open: true, msg: t("reg_error") });
                      }
                      setLoad(false);
                    }
                  });
                } else {
                  setCommonAlert({ open: true, msg: t("referer_not_found") });
                  setLoad(false);
                }
              })
              .catch((error) => {
                setCommonAlert({ open: true, msg: t("referer_not_found") });
                setLoad(false);
              });
          } else {
            mainSignUp(state).then((res) => {
              if (res.uid) {
                setCommonAlert({
                  open: true,
                  msg: t("account_create_successfully"),
                });
                setLoad(false);
                // dispatch(mobileSignIn(verificationId.verificationId, state.otp));
                dispatch(signOut());
                setTimeout(() => {
                  props.history.push("/");
                }, 5000);
              } else {
                if (res.error.code === "auth/invalid-phone-number") {
                  setCommonAlert({
                    open: true,
                    msg: t("invalid_mobile_number"),
                  });
                } else if (
                  res.error.code === "auth/phone-number-already-exists"
                ) {
                  setCommonAlert({ open: true, msg: res.error.message });
                } else {
                  setCommonAlert({ open: true, msg: t("reg_error") });
                }
                setLoad(false);
              }
            });
          }
        }
      });
    }
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
    if (auth.error.flag) {
      dispatch(clearLoginError());
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    props.history.push("/login");
  };

  const onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    setState({ ...state, location: { lat: latLng.lat(), lng: latLng.lng() } });
  };

  const MapWithAMarker = withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={16}
      defaultCenter={props.location}
      mapElement={<div style={{ height: `200px` }} />}
    >
      <Marker
        position={props.location}
        draggable={true}
        onDragEnd={onMarkerDragEnd}
      />
    </GoogleMap>
  ));

  return (
    <div>
      <Header
        absolute
        color="transparent"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div id="sign-in-button" />
        {load === true ? <CircularLoading /> : null}
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <Card className={"registerForm"}>
                <form className={classes.form}>
                  <Paper
                    square
                    className={classes.root}
                    style={{
                      paddingTop: 20,
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Tabs
                      value={0}
                      variant="fullWidth"
                      indicatorColor="primary"
                      textColor="inherit"
                      aria-label="switch login type"
                    >
                      <Tab
                        disabled={true}
                        label={t("register_rider_title")}
                        aria-label="email"
                      />
                    </Tabs>
                  </Paper>

                  <CardBody>
                    <div className={"regFormRadio"}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          {t("customerType")}
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-label="customerType"
                          name="row-radio-buttons-group"
                          value={state.customerType}
                          onChange={handleChangeRadio}
                        >
                          <FormControlLabel
                            value="individual"
                            control={<Radio />}
                            label={t("customerTypeIndividual")}
                          />
                          <FormControlLabel
                            value="company"
                            control={<Radio />}
                            label={t("customerTypeCompany")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    {state.customerType === "company" ? (
                      <CustomInput
                        labelText={t("companyName")}
                        id="companyName"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{ type: "text", required: true }}
                        onChange={onInputChange}
                        value={state.companyName}
                      />
                    ) : null}
                    <CustomInput
                      labelText={t("name")}
                      id="firstName"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{ type: "text", required: true }}
                      onChange={onInputChange}
                      value={state.firstName}
                    />
                    <CustomInput
                      labelText={t("email")}
                      id="email"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{ type: "email", required: true }}
                      onChange={onInputChange}
                      value={state.email}
                    />
                    <CustomInput
                      labelText={t("mobile")}
                      id="mobile"
                      formControlProps={{
                        fullWidth: true,
                        className: "mobile-outer",
                      }}
                      inputProps={{
                        type: "tel",
                        required: true,
                        disabled: verifiedOtp ? true : false,
                      }}
                      value={mobileWithoutCountry}
                      onChange={(event) => {
                        setMobileWithoutCountry(event.target.value);
                        let formattedNum = event.target.value.replace(/ /g, "");
                        formattedNum = "+968" + formattedNum.replace(/-/g, "");
                        setState({ ...state, mobile: formattedNum });
                      }}
                    />
                    <CustomInput
                      labelText={t("referralId")}
                      id="referralId"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{ type: "text", required: true }}
                      onChange={onInputChange}
                      value={state.referralId}
                    />
                    <div style={{ marginBottom: "20px" }}>
                      <MapWithAMarker
                        containerElement={<div className={"registerMap"} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        location={state.location}
                      />
                    </div>
                    <Button
                      className={classNames(
                        classes.mapLocationGrabber,
                        "bg-color"
                      )}
                      onClick={getLocation}
                    >
                      {t("getLocation")}
                    </Button>
                    {verificationId ? (
                      <CustomInput
                        labelText={t("otp")}
                        id="otp"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          type: "number",
                          required: true,
                          disabled: verifiedOtp ? true : false,
                        }}
                        onChange={onInputChange}
                        value={state.otp}
                      />
                    ) : null}
                  </CardBody>
                  <CardFooter
                    className={classNames(classes.cardFooter, "regFormFooter")}
                  >
                    {verificationId ? (
                      verifiedOtp ? (
                        <Button
                          className={classNames(
                            classes.normalButton,
                            "bg-color"
                          )}
                          simple
                          color="primary"
                          size="sm"
                          onClick={handleRegister}
                        >
                          {t("register")}
                        </Button>
                      ) : (
                        <div>
                          <Button
                            className={classNames(
                              classes.normalButton,
                              "bg-color"
                            )}
                            simple
                            color="primary"
                            size="sm"
                            onClick={verifyOtp}
                          >
                            {t("verifyOtp")}
                          </Button>
                          {resendOtp ? (
                            <Button
                              className={classNames(
                                classes.normalButton,
                                "bg-color"
                              )}
                              simple
                              color="primary"
                              size="sm"
                              onClick={requestOtp}
                            >
                              {t("requestOtp")}
                            </Button>
                          ) : null}
                        </div>
                      )
                    ) : (
                      <Button
                        className={classNames(classes.normalButton, "bg-color")}
                        simple
                        color="primary"
                        size="sm"
                        onClick={requestOtp}
                      >
                        {t("requestOtp")}
                      </Button>
                    )}
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
        <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
          {commonAlert.msg}
        </AlertDialog>
      </div>
    </div>
  );
}
