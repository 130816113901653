import React, { useState, useEffect, useContext, useRef } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import FooterMain from "components/Footer/FooterMain.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import {
  Paper,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Modal,
  Grid,
  Typography,
  ListItemText,
} from "@material-ui/core";
import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import WhatWeDo from "./Sections/WhatWeDo.js";
import MadeEasy from "./Sections/MadeEasy.js";
import Commitment from "./Sections/Commitment.js";
import SectionDownload from "./Sections/SectionDownload.js";
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from "../components/AlertDialog";
import { FirebaseContext } from "common";
import { colors } from "../components/Theme/WebTheme";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const dashboardRoutes = [];

const useStyles = makeStyles((theme) => ({
  ...styles,
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  rtlMenuItem: {
    textAlign: "right",
  },
  inputRtl: {
    "& label": {
      right: 25,
      left: "auto",
    },
    "& legend": {
      textAlign: "right",
      marginRight: 18,
    },
  },
}));

const icons = {
  payment: require("../assets/img/payment-icons/payment-logo.png").default,
};

export default function LandingPage(props) {
  const { api } = useContext(FirebaseContext);
  const {
    getEstimate,
    clearEstimate,
    addBooking,
    clearBooking,
    MinutesPassed,
    GetDateString,
    GetDistance,
  } = api;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const { ...rest } = props;
  const cartypes = useSelector((state) => state.cartypes.cars);
  const estimatedata = useSelector((state) => state.estimatedata);
  const bookingdata = useSelector((state) => state.bookingdata);
  const userdata = useSelector((state) => state.usersdata);
  const settings = useSelector((state) => state.settingsdata.settings);
  const providers = useSelector((state) => state.paymentmethods.providers);
  const [carType, setCarType] = useState();
  const [pickupAddress, setPickupAddress] = useState(null);
  const [dropAddress, setDropAddress] = useState(null);
  const [optionModalStatus, setOptionModalStatus] = useState(false);
  const [estimateModalStatus, setEstimateModalStatus] = useState(false);
  const [paymentModalStatus, setPaymentModalStatus] = useState(false);
  const [estimateRequested, setEstimateRequested] = useState(false);
  const [selectedCarDetails, setSelectedCarDetails] = useState(null);
  const auth = useSelector((state) => state.auth);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [bookingType, setBookingType] = useState("Book Later");
  const [role, setRole] = useState(null);
  const [selectedDate, setSelectedDate] = React.useState(GetDateString());
  const rootRef = useRef(null);
  const [tempRoute, setTempRoute] = useState();
  const [selectedProvider, setSelectedProvider] = useState();
  const [selectedProviderIndex, setSelectedProviderIndex] = useState(0);
  const [drivers, setDrivers] = useState([]);
  const [rideTo, setRideTo] = useState("Other");

  const [instructionData, setInstructionData] = useState({
    deliveryPerson: "",
    deliveryPersonPhone: "",
    pickUpInstructions: "",
    deliveryInstructions: "",
    parcelTypeIndex: 0,
    optionIndex: 0,
    parcelTypeSelected: null,
    optionSelected: null,
  });

  const handleChange = (e) => {
    if (e.target.name === "parcelTypeIndex") {
      setInstructionData({
        ...instructionData,
        parcelTypeIndex: parseInt(e.target.value),
        parcelTypeSelected: selectedCarDetails.parcelTypes[e.target.value],
      });
    } else if (e.target.name === "optionIndex") {
      setInstructionData({
        ...instructionData,
        optionIndex: parseInt(e.target.value),
        optionSelected: selectedCarDetails.options[e.target.value],
      });
    } else if (e.target.name === "selectedProviderIndex") {
      setSelectedProviderIndex(parseInt(e.target.value));
      setSelectedProvider(providers[parseInt(e.target.value)]);
    } else {
      setInstructionData({
        ...instructionData,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    if (userdata.users) {
      let arrDrivers = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        if (
          user.usertype &&
          user.usertype === "driver" &&
          user.approved === true &&
          user.driverActiveStatus === true &&
          user.location
        ) {
          arrDrivers.push({
            uid: user.id,
            location: user.location,
          });
        }
      }
      setDrivers(arrDrivers);
    }
  }, [userdata.users]);

  const handleCarSelect = (event) => {
    setCarType(event.target.value);
    let carDetails = null;
    for (let i = 0; i < cartypes.length; i++) {
      if (cartypes[i].name === event.target.value) {
        carDetails = cartypes[i];
        let instObj = { ...instructionData };
        if (Array.isArray(cartypes[i].parcelTypes)) {
          instObj.parcelTypeSelected = cartypes[i].parcelTypes[0];
          instObj.parcelTypeIndex = 0;
        }
        if (Array.isArray(cartypes[i].options)) {
          instObj.optionSelected = cartypes[i].options[0];
          instObj.optionIndex = 0;
        }
        setInstructionData(instObj);
      }
    }
    setSelectedCarDetails(carDetails);
  };

  // const handleBookTypeSelect = (event) => {
  //     setBookingType(event.target.value);
  //     if(bookingType==='Book Later'){
  //         setSelectedDate(GetDateString());
  //     }
  // };

  const onDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleRideTo = (event) => {
    setRideTo(event.target.value);
    let arpt = {
      description: "Muscat International Airport, Muscat, Oman",
      matched_substrings: [{ length: 6, offset: 0 }],
      place_id: "ChIJR5CSsAP-jT4RF9l9K0nK5yo",
      reference: "ChIJR5CSsAP-jT4RF9l9K0nK5yo",
      structured_formatting: {
        main_text: "Muscat International Airport",
        main_text_matched_substrings: [{ length: 6, offset: 0 }],
        secondary_text: "Muscat, Oman",
      },
      terms: [
        { offset: 0, value: "Muscat International Airport" },
        { offset: 30, value: "Muscat" },
        { offset: 38, value: "Oman" },
      ],
      types: ["airport", "point_of_interest", "establishment"],
      coords: { lat: 23.6014022, lng: 58.28997249999999 },
      placeDetails: {
        address_components: [
          {
            long_name: "Muscat International Airport",
            short_name: "Muscat International Airport",
            types: ["airport", "establishment", "point_of_interest"],
          },
          {
            long_name: "Muscat",
            short_name: "Muscat",
            types: ["locality", "political"],
          },
          {
            long_name: "Muscat Governorate",
            short_name: "Muscat Governorate",
            types: ["administrative_area_level_1", "political"],
          },
          {
            long_name: "Oman",
            short_name: "OM",
            types: ["country", "political"],
          },
        ],
        formatted_address: "Muscat International Airport, Muscat, Oman",
        geometry: {
          location: { lat: 23.6014022, lng: 58.28997249999999 },
          location_type: "GEOMETRIC_CENTER",
          viewport: {
            south: 23.6000531697085,
            west: 58.2886226697085,
            north: 23.60275113029151,
            east: 58.2913206302915,
          },
        },
        place_id: "ChIJR5CSsAP-jT4RF9l9K0nK5yo",
        plus_code: {
          compound_code: "J72Q+HX Muscat, Oman",
          global_code: "7HMWJ72Q+HX",
        },
        types: ["airport", "establishment", "point_of_interest"],
      },
    };
    if (event.target.value == "Airport") {
      setDropAddress(arpt);
    } else {
      // setDropAddress(null);
    }
    setCarType(t("select_car"));
  };

  useEffect(() => {
    if (t) {
      setCarType(t("select_car"));
    }
  }, [t]);

  useEffect(() => {
    if (providers) {
      setSelectedProvider(providers[0]);
    }
  }, [providers]);

  useEffect(() => {
    if (estimatedata.estimate && estimateRequested) {
      setEstimateModalStatus(true);
    }
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile.usertype);
    }
  }, [estimatedata.estimate, auth.info, estimateRequested]);

  useEffect(() => {
    if (
      bookingdata.booking &&
      bookingdata.booking.mainData.status === "PAYMENT_PENDING"
    ) {
      setPaymentModalStatus(true);
    }
  }, [bookingdata.booking]);

  const [phoneAlert, setPhoneAlert] = useState({ open: false, msg: "" });

  const handlePhoneAlertClose = (e) => {
    e.preventDefault();
    setPhoneAlert({ open: false, msg: "" });
  };

  const handlePhoneAlertGo = (e) => {
    e.preventDefault();
    props.history.push("/profile");
  };

  useEffect(() => {
    setSelectedCarDetails(null);
    CarSelect();
  }, [cartypes]);

  const CarSelect = () => {
    if (cartypes) {
      let carFIlteredList = [];
      for (let i = 0; i < cartypes.length; i++) {
        // console.log(cartypes[i]);
        // console.log(cartypes[i]['ridesfor'].length);
        if (cartypes[i]["ridesfor"].length) {
          if (cartypes[i]["ridesfor"].indexOf(rideTo) !== -1) {
            carFIlteredList.push(cartypes[i]);
          }
        } else {
          carFIlteredList.push(cartypes[i]);
        }
      }
      return (
        <FormControl style={{ width: "100%" }}>
          <Select
            id="car-type-native"
            value={carType}
            onChange={handleCarSelect}
            className={
              carType === t("select_car") ? classes.inputdimmed : classes.input
            }
          >
            <MenuItem
              value={t("select_car")}
              key={t("select_car")}
              className={isRTL === "rtl" ? classes.rtlMenuItem : ""}
            >
              <ListItemText
                className={isRTL === "rtl" ? classes.rtlMenuItem : ""}
                primary={t("select_car")}
                primaryTypographyProps={{
                  align: isRTL === "rtl" ? "right" : "left",
                }}
              />
            </MenuItem>
            {carFIlteredList.map((car) => (
              <MenuItem key={car.name} value={car.name}>
                <img
                  src={car.image}
                  className={classes.carphoto}
                  alt="car types"
                />
                {car.desc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return null;
    }
  };
  const handleGetOptions = (e) => {
    e.preventDefault();
    setEstimateRequested(true);
    if (auth.info) {
      if (auth.info.profile.usertype === "rider") {
        if (
          auth.info.profile.email === " " ||
          auth.info.profile.firstName === " "
        ) {
          setCommonAlert({ open: true, msg: t("profile_incomplete") });
        } else {
          if (pickupAddress && dropAddress && selectedCarDetails) {
            const directionService = new window.google.maps.DirectionsService();
            directionService.route(
              {
                origin: new window.google.maps.LatLng(
                  pickupAddress.coords.lat,
                  pickupAddress.coords.lng
                ),
                destination: new window.google.maps.LatLng(
                  dropAddress.coords.lat,
                  dropAddress.coords.lng
                ),
                travelMode: window.google.maps.TravelMode.DRIVING,
              },
              (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                  const route = {
                    distance_in_km:
                      result.routes[0].legs[0].distance.value / 1000,
                    time_in_secs: result.routes[0].legs[0].duration.value,
                    polylinePoints: result.routes[0].overview_polyline,
                  };
                  setTempRoute(route);
                  if (bookingType === "Book Now") {
                    if (
                      Array.isArray(selectedCarDetails.options) ||
                      Array.isArray(selectedCarDetails.parcelTypes)
                    ) {
                      setOptionModalStatus(true);
                    } else {
                      let estimateRequest = {
                        pickup: pickupAddress,
                        drop: dropAddress,
                        carDetails: selectedCarDetails,
                        instructionData: instructionData,
                        routeDetails: route,
                      };
                      dispatch(getEstimate(estimateRequest));
                    }
                  } else {
                    if (bookingType === "Book Later" && selectedDate) {
                      if (MinutesPassed(selectedDate) >= 15) {
                        if (
                          Array.isArray(selectedCarDetails.options) ||
                          Array.isArray(selectedCarDetails.parcelTypes)
                        ) {
                          setOptionModalStatus(true);
                        } else {
                          let estimateRequest = {
                            pickup: pickupAddress,
                            drop: dropAddress,
                            carDetails: selectedCarDetails,
                            instructionData: instructionData,
                            routeDetails: route,
                          };
                          dispatch(getEstimate(estimateRequest));
                        }
                      } else {
                        setCommonAlert({
                          open: true,
                          msg: t("past_booking_error"),
                        });
                      }
                    } else {
                      setCommonAlert({ open: true, msg: t("select_proper") });
                    }
                  }
                } else {
                  setCommonAlert({
                    open: true,
                    msg: t("place_to_coords_error"),
                  });
                }
              }
            );
          } else {
            setCommonAlert({ open: true, msg: t("select_proper") });
          }
        }
      } else {
        setCommonAlert({ open: true, msg: t("user_issue_contact_admin") });
      }
    } else {
      setCommonAlert({ open: true, msg: t("must_login") });
    }
  };

  const handleGetEstimate = (e) => {
    e.preventDefault();
    setOptionModalStatus(false);
    let estimateRequest = {
      pickup: pickupAddress,
      drop: dropAddress,
      carDetails: selectedCarDetails,
      instructionData: instructionData,
      routeDetails: tempRoute,
    };
    dispatch(getEstimate(estimateRequest));
  };

  const confirmBooking = (e) => {
    e.preventDefault();
    let found = false;
    if (bookingType === "Book Now") {
      for (let i = 0; i < drivers.length; i++) {
        const driver = drivers[i];
        let distance = GetDistance(
          pickupAddress.coords.lat,
          pickupAddress.coords.lng,
          driver.location.lat,
          driver.location.lng
        );
        if (settings.convert_to_mile) {
          distance = distance / 1.609344;
        }
        if (
          distance <
          (settings && settings.driverRadius ? settings.driverRadius : 10)
        ) {
          found = true;
          break;
        }
      }
    }
    if ((found && bookingType === "Book Now") || bookingType === "Book Later") {
      const regx1 =
        /([0-9\s-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
      if (
        regx1.test(instructionData.deliveryPersonPhone) &&
        instructionData.deliveryPersonPhone &&
        instructionData.deliveryPersonPhone.length === 8
      ) {
        setEstimateModalStatus(false);

        const paymentPacket = {
          payment_mode: "card",
          customer_paid: estimatedata.estimate.estimateFare,
          discount_amount: 0,
          usedWalletMoney: 0,
          cardPaymentAmount: estimatedata.estimate.estimateFare,
          cashPaymentAmount: 0,
          payableAmount: estimatedata.estimate.estimateFare,
          promo_applied: false,
          promo_details: null,
        };

        let bookingObject = {
          pickup: pickupAddress,
          drop: dropAddress,
          carDetails: selectedCarDetails,
          userDetails: auth.info,
          estimate: estimatedata.estimate,
          instructionData: instructionData,
          paymentPacket: paymentPacket,
          tripdate:
            bookingType === "Book Later"
              ? new Date(selectedDate).getTime()
              : new Date().getTime(),
          bookLater: bookingType === "Book Later" ? true : false,
          settings: settings,
          booking_type_admin: false,
        };
        dispatch(addBooking(bookingObject));
      } else {
        setCommonAlert({ open: true, msg: t("deliveryDetailMissing") });
      }
    } else {
      setCommonAlert({ open: true, msg: t("no_driver_found_alert_messege") });
    }
  };
  const handleOptionModalClose = (e) => {
    e.preventDefault();
    setOptionModalStatus(false);
  };

  const handleEstimateModalClose = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    dispatch(clearEstimate());
    setEstimateRequested(false);
  };

  const handleEstimateErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearEstimate());
    setEstimateRequested(false);
  };

  const handleBookingAlertClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    dispatch(clearEstimate());
    props.history.push("/bookings");
  };

  const handleBookingErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    setEstimateRequested(false);
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  const handlePaymentModalClose = (e) => {
    setTimeout(() => {
      setPaymentModalStatus(false);
      dispatch(clearBooking());
      dispatch(clearEstimate());
    }, 1500);
  };

  return (
    <div style={{ backgroundColor: colors.LandingPage_Background }}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 50,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        filter
        image={require("assets/img/background.jpg").default}
        className={"parallaxCover"}
      >
        {(cartypes && !role) ||
        (cartypes && (role === "rider" || role === "admin")) ? (
          <div
            className={classes.container}
            style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
          >
            <div className={"sliderHeading"}>
              <GridContainer spacing={2}>
                <GridItem xs={12}>
                  <h1 className={classes.title}>{t("book_your_cab")}</h1>
                  <img src={require("assets/img/curve.png").default} alt="" />
                </GridItem>
              </GridContainer>
            </div>
            <div className={"deliveryForm"}>
              <GridContainer spacing={2}>
                <GridItem xs={12} sm={12} md={10} lg={10}>
                  <GridContainer spacing={2}>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <Paper>
                        <GoogleMapsAutoComplete
                          placeholder={t("pickup_location")}
                          variant={"filled"}
                          value={pickupAddress}
                          onChange={(value) => {
                            setPickupAddress(value);
                          }}
                        />
                      </Paper>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <FormControl style={{ width: "100%" }}>
                        <Select
                          id="ride-type-native"
                          value={rideTo}
                          onChange={handleRideTo}
                          className={classes.inputdimmed}
                        >
                          <MenuItem key={t("Airport")} value={t("Airport")}>
                            {t("airport_drop_off")}
                          </MenuItem>
                          <MenuItem
                            value={t("Other")}
                            key={t("Other Ride")}
                            className={
                              isRTL === "rtl" ? classes.rtlMenuItem : ""
                            }
                          >
                            <ListItemText
                              className={
                                isRTL === "rtl" ? classes.rtlMenuItem : ""
                              }
                              primary={t("other_location")}
                              primaryTypographyProps={{
                                align: isRTL === "rtl" ? "right" : "left",
                              }}
                            />
                          </MenuItem>
                          <MenuItem
                            value={t("Recovery")}
                            key={t("Breakdown / Recovery")}
                          >
                            {t("break_down_recovery")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      {rideTo == "Other" || rideTo == "Recovery" ? (
                        <Paper>
                          <GoogleMapsAutoComplete
                            currentLocation={true}
                            placeholder={t("drop_location")}
                            variant={"filled"}
                            value={dropAddress}
                            onChange={(value) => {
                              // console.log(JSON.stringify(value));
                              setDropAddress(value);
                            }}
                          />
                        </Paper>
                      ) : rideTo == "Airport" ? (
                        <h4 className={"form-label"}>{t("airport_muscat")}</h4>
                      ) : (
                        ""
                      )}
                    </GridItem>
                  </GridContainer>
                  <GridContainer spacing={2}>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CarSelect />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        id="datetime-local"
                        // label={t("booking_date_time")}
                        type="datetime-local"
                        variant="filled"
                        fullWidth
                        className={classes.commonInputStyle}
                        InputProps={{
                          className: classes.input,
                        }}
                        value={selectedDate}
                        onChange={onDateChange}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={2} lg={2}>
                  <Button
                    rel="noopener noreferrer"
                    className={classNames(classes.items, "btnBook")}
                    onClick={handleGetOptions}
                    // style={{ height: bookingType === "Book Later" ? 76 : 52 }}
                  >
                    {t("book_here")}
                  </Button>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        ) : (
          <div className={classes.container}>
            <GridContainer spacing={2}>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <br />
                <h1
                  style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
                  className={classes.title}
                >
                  {t("landing_slogan")}
                </h1>
              </GridItem>
            </GridContainer>
          </div>
        )}
      </Parallax>
      <div className={classNames("WhatWeDoSec")}>
        <div className={classes.container}>
          <WhatWeDo />
        </div>
      </div>
      <div className={classNames("MadeEasySec mx-0")}>
        <div className={classes.container}>
          <MadeEasy />
        </div>
      </div>
      <div className={classNames("CommitmentSec")}>
        <div className={classes.container}>
          <Commitment />
        </div>
      </div>
      <div className={classNames("DownloadsSec")}>
        <div className={classes.container}>
          <SectionDownload />
        </div>
      </div>
      <footer className={classNames("FooterSec")}>
        <div className={classes.container}>
          <FooterMain />
        </div>
      </footer>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={paymentModalStatus}
        onClose={handlePaymentModalClose}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid container spacing={2} className={classes.paper}>
          {providers &&
          selectedProvider &&
          bookingdata &&
          bookingdata.booking ? (
            <form action={selectedProvider.link} method="POST">
              <input
                type="hidden"
                name="order_id"
                value={bookingdata.booking.booking_id}
              />
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">{t("payment")}</FormLabel>
                  <RadioGroup
                    name="selectedProviderIndex"
                    value={selectedProviderIndex}
                    onChange={handleChange}
                  >
                    {providers.map((provider, index) => (
                      <FormControlLabel
                        key={provider.name}
                        value={index}
                        control={<Radio />}
                        label={
                          <img
                            style={{ height: 24, margin: 7 }}
                            src={icons[provider.name]}
                            alt={provider.name}
                          />
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button
                  onClick={handlePaymentModalClose}
                  variant="contained"
                  color="primary"
                >
                  {t("cancel")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ marginLeft: 10 }}
                  onClick={handlePaymentModalClose}
                >
                  {t("paynow_button")}
                </Button>
              </Grid>
            </form>
          ) : null}
        </Grid>
      </Modal>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={optionModalStatus}
        onClose={handleOptionModalClose}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid container spacing={2} className={classes.paper}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {selectedCarDetails && selectedCarDetails.parcelTypes ? (
              <FormControl component="fieldset">
                <FormLabel component="legend">{t("parcel_types")}</FormLabel>
                <RadioGroup
                  name="parcelTypeIndex"
                  value={instructionData.parcelTypeIndex}
                  onChange={handleChange}
                >
                  {selectedCarDetails.parcelTypes.map((element, index) => (
                    <FormControlLabel
                      key={element.description}
                      style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                      value={index}
                      control={<Radio />}
                      label={
                        settings.swipe_symbol === false
                          ? settings.symbol +
                            " " +
                            element.amount +
                            " - " +
                            element.description
                          : element.amount +
                            " " +
                            settings.symbol +
                            " - " +
                            element.description
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {selectedCarDetails && selectedCarDetails.options ? (
              <FormControl component="fieldset">
                <FormLabel component="legend">{t("options")}</FormLabel>
                <RadioGroup
                  name="optionIndex"
                  value={instructionData.optionIndex}
                  onChange={handleChange}
                >
                  {selectedCarDetails.options.map((element, index) => (
                    <FormControlLabel
                      key={element.description}
                      style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                      value={index}
                      control={<Radio />}
                      label={
                        settings.swipe_symbol === false
                          ? settings.symbol +
                            " " +
                            element.amount +
                            " - " +
                            element.description
                          : element.amount +
                            " " +
                            settings.symbol +
                            " - " +
                            element.description
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            <Button
              onClick={handleOptionModalClose}
              variant="contained"
              color="primary"
            >
              {t("cancel")}
            </Button>
            <Button
              onClick={handleGetEstimate}
              variant="contained"
              color="primary"
              style={{ marginLeft: 10 }}
            >
              {t("get_estimate")}
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={estimateModalStatus}
        onClose={handleEstimateModalClose}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
        >
          <Typography
            component="h2"
            variant="h5"
            style={{ marginTop: 15, color: "#000" }}
          >
            {t("delivery_information")}
          </Typography>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="deliveryPerson"
              label={t("deliveryPerson")}
              name="deliveryPerson"
              autoComplete="deliveryPerson"
              onChange={handleChange}
              value={instructionData.deliveryPerson}
              autoFocus
              className={isRTL === "rtl" ? classes.inputRtl : null}
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="deliveryPersonPhone"
              label={t("deliveryPersonPhone")}
              name="deliveryPersonPhone"
              autoComplete="deliveryPersonPhone"
              onChange={handleChange}
              value={instructionData.deliveryPersonPhone}
              className={
                isRTL === "rtl" ? [classes.inputRtl, classes.rightRty] : null
              }
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="pickUpInstructions"
              label={t("pickUpInstructions")}
              name="pickUpInstructions"
              autoComplete="pickUpInstructions"
              onChange={handleChange}
              value={instructionData.pickUpInstructions}
              className={isRTL === "rtl" ? classes.inputRtl : null}
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="deliveryInstructions"
              label={t("deliveryInstructions")}
              name="deliveryInstructions"
              autoComplete="deliveryInstructions"
              onChange={handleChange}
              value={instructionData.deliveryInstructions}
              className={isRTL === "rtl" ? classes.inputRtl : null}
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {settings && settings.swipe_symbol === false ? (
              <Typography color={"primary"} style={{ fontSize: 30 }}>
                {t("total")} - {settings ? settings.symbol : null}{" "}
                {estimatedata.estimate
                  ? estimatedata.estimate.estimateFare
                  : null}
              </Typography>
            ) : (
              <Typography color={"primary"} style={{ fontSize: 30 }}>
                {t("total")} -{" "}
                {estimatedata.estimate
                  ? estimatedata.estimate.estimateFare
                  : null}{" "}
                {settings ? settings.symbol : null}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            <Button
              onClick={handleEstimateModalClose}
              variant="contained"
              color="primary"
            >
              {t("cancel")}
            </Button>
            <Button
              onClick={confirmBooking}
              variant="contained"
              color="primary"
              style={{ marginLeft: 10 }}
            >
              {t("book_now")}
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <AlertDialog
        open={bookingdata.error.flag}
        onClose={handleBookingErrorClose}
      >
        {bookingdata.error.msg}
      </AlertDialog>
      <AlertDialog
        open={estimatedata.error.flag}
        onClose={handleEstimateErrorClose}
      >
        {estimatedata.error.msg}
      </AlertDialog>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>

      <Dialog
        open={phoneAlert.open}
        onClose={handlePhoneAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("phone_no_update")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePhoneAlertClose} color="primary">
            {t("cancel")}
          </Button>
          <Button onClick={handlePhoneAlertGo} color="primary">
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
