/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import styles from "assets/jss/material-kit-react/views/componentsSections/downloadStyle.js";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import product1 from "assets/img/package-1.jpg";
import product2 from "assets/img/package-2.jpg";
import product3 from "assets/img/package-3.jpg";

const useStyles = makeStyles(styles);

export default function SectionDownload() {
  const settings = useSelector(state => state.settingsdata.settings);
  const classes = useStyles();
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';
  return (
    <div className={classes.xxx}>
      <GridContainer>
        <GridItem xs={12} className={"WhatWeHd"}>
          <h6>{t('what_we_do')}</h6>
          <h2>{t('types_of_packages')}</h2>
        </GridItem>
      </GridContainer>
      <div className={"content-body"}>
        <GridContainer spacing={2}>
          <GridItem xs={12} sm={12} md={4}>
            <div className={"WhatWeItem"}>
              <div className={"WhatWeImg"}>
                <img src={product1} alt="package" />
              </div>
              <div className={"WhatWeOverlay"}>
                <h3 style={{textAlign: isRTL ? "right" : "left" }}>{t('express_delivery')}</h3>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <div className={"WhatWeItem"}>
              <div className={"WhatWeImg"}>
                <img src={product2} alt="package" />
              </div>
              <div className={"WhatWeOverlay"}>
                <h3 style={{textAlign: isRTL ? "right" : "left" }}>{t('normal_delivery')}</h3>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <div className={"WhatWeItem"}>
              <div className={"WhatWeImg"}>
                <img src={product3} alt="package" />
              </div>
              <div className={"WhatWeOverlay"}>
                <h3 style={{textAlign: isRTL ? "right" : "left" }}>{t('airport_delivery')}</h3>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
