import React,{ useState, useEffect, useContext, useRef } from 'react';
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import TermsEn from "components/TermsEn";
import TermsAr from "components/TermsAr";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function TermsConditions(props) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const settings = useSelector((state) => state.settingsdata.settings);
  const { ...rest } = props;
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Header
        color='transparent'
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 50,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/header-back.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <h1 style={{ textAlign: isRTL === "rtl" ? "right" : "left", fontSize:"1.8rem", fontWeight: 700, position: "relative", marginTop: "30px",paddingTop:"30px", minHeight: "32px", color: "#383838", textDecoration: "none" }}>{t("terms_conditions")}</h1>
          { isRTL === "rtl" ? <div className={'description'} style={{ textAlign: "right", direction:'rtl' }}> <TermsAr/> </div> :  <div className={'description'} style={{ textAlign: "left" }}> <TermsEn/>  </div> }
        </div>
      </div>
      <Footer />
    </div>
  );
}
