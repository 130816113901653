import React, { useState, useContext, useEffect } from "react";
import Compress from "compress.js";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomDateTime from "components/CustomInput/CustomDateTime.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import image from "assets/img/background.jpg";
import { useSelector, useDispatch } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
// import EmailIcon from "@material-ui/icons/Email";
import AlertDialog from "../components/AlertDialog";
// import CountrySelect from '../components/CountrySelect';
import { FirebaseContext } from "common";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
// import { cardHeader } from "assets/jss/material-kit-react";
import CardHeader from "components/Card/CardHeader";
import CircularLoading from "../components/CircularLoading";
// import { FlashOnRounded } from "@material-ui/icons";
// import { func } from 'prop-types';

import PrivacyEn from "components/PrivacyEn";
import PrivacyAr from "components/PrivacyAr";
import ListItemText from "@material-ui/core/ListItemText";
const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const { api, authRef, RecaptchaVerifier, storage } =
    useContext(FirebaseContext);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    clearLoginError,
    mainSignUp,
    mobileSignIn,
    checkUserExists,
    validateReferer,
    signOut,
  } = api;
  const auth = useSelector((state) => state.auth);
  const cartypes = useSelector((state) => state.cartypes.cars);
  const settings = useSelector((state) => state.settingsdata.settings);
  const dispatch = useDispatch();
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [mobileWithoutCountry, setMobileWithoutCountry] = useState("");
  const [verifiedOtp, setVerifiedOtp] = useState(false);
  const [resendOtp, setResendOtp] = useState(false);
  const [capatchaReady, setCapatchaReady] = React.useState(false);
  const [verificationId, setverificationId] = useState(null);
  const [load, setLoad] = useState(false);
  const [agreedTerms, setAgreedTerms] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  let d = new Date();
  const today_date =
    d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
  const [state, setState] = useState({
    firstName: "",
    middleName: "",
    thirdName: "",
    familyName: "",
    email: "",
    mobile: "",
    idCard: "",
    idCardExpiry: "",
    licenseNumber: "",
    licenseExpiry: "",
    carType: "",
    vehicleMake: "",
    vehicleModel: "",
    vehicleManufacture: "",
    vehicleNumber: "",
    vehicleExpiry: "",
    bankName: "",
    bankBranch: "",
    bankAccountNo: "",
    // firstName: "Akhil",
    // middleName: "A",
    // thirdName: "Joseph",
    // lastName: "Alooparambil",
    // email: "akhiljospeh619@gmail.com",
    // mobile: "93800147",
    // idCard: "1234567890",
    // idCardExpiry: "2022-10-01",
    // licenseNumber: "1234567890",
    // licenseExpiry: "2022-10-01",
    // carType: "",
    // vehicleMake: "Maruthi",
    // vehicleModel: "Sedan",
    // vehicleManufacture: "Maruthi",
    // vehicleNumber: "20202123",
    // vehicleExpiry: "2022-10-01",
    bankAccoutScreenshot: null,
    registrationImage: null,
    registrationBackImage: null,
    licenseImage: null,
    licenseBackImage: null,
    idCardImage: null,
    idCardBackImage: null,
    userImage: null,
    ropClearanceCertificate: null,
    usertype: "driver",
    referralId: "",
    otp: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!capatchaReady) {
      window.recaptchaVerifier = new RecaptchaVerifier("sign-in-button", {
        size: "invisible",
        callback: function (response) {
          setCapatchaReady(true);
        },
      });
    }
  }, [auth.info, capatchaReady, RecaptchaVerifier]);

  useEffect(() => {
    if (auth.info) {
      if (auth.info.profile) {
        let role = auth.info.profile.usertype;
        if (
          role === "admin" ||
          role === "fleetadmin" ||
          role === "financeadmin"
        ) {
          props.history.push("/dashboard");
        } else if (role === "driver") {
          props.history.push("/bookings");
        } else {
          props.history.push("/");
        }
      }
    }
  }, []);
  const classes = useStyles();
  const { ...rest } = props;
  useEffect(() => {
    setLoad(false);
  }, [
    state.bankAccoutScreenshot,
    state.registrationImage,
    state.registrationBackImage,
    state.licenseImage,
    state.licenseBackImage,
    state.idCardImage,
    state.idCardBackImage,
    state.userImage,
    state.ropClearanceCertificate,
  ]);
  const onInputChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
  };
  const onIdExpiryChange = (date) => {
    date = new Date(date);
    let nDate = [
      date.getDate().toString().padStart(2, "0"),
      (date.getMonth() + 1).toString().padStart(2, "0"),
      date.getFullYear(),
    ].join("/");
    setState({ ...state, idCardExpiry: nDate });
  };
  const onLicenseExpiryChange = (date) => {
    date = new Date(date);
    let nDate = [
      date.getDate().toString().padStart(2, "0"),
      (date.getMonth() + 1).toString().padStart(2, "0"),
      date.getFullYear(),
    ].join("/");
    setState({ ...state, licenseExpiry: nDate });
  };
  const onVehicleExpiryChange = (date) => {
    date = new Date(date);
    let nDate = [
      date.getDate().toString().padStart(2, "0"),
      (date.getMonth() + 1).toString().padStart(2, "0"),
      date.getFullYear(),
    ].join("/");
    setState({ ...state, vehicleExpiry: nDate });
  };
  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const resizeFile = async (file) => {
    const compress = new Compress();
    const resizedImage = await compress.compress([file], {
      size: 0.5 /* the max size in MB, defaults to 2MB*/,
      quality: 8 /* the quality of the image, max is 1,*/,
      width: 1024,
      resize: true /* defaults to true, set false if you do not want to resize the image width and height*/,
    });
    const img = resizedImage[0];
    const base64str = img.data;
    const imgExt = img.ext;
    const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt);
    return resizedFiile;
  };
  const handleUpload = async (file, state_var) => {
    console.log(`johnp ${state_var}`);
    if (file && state_var) {
      setLoad(true);
      let prefix =
        d.getHours() +
        "_" +
        d.getMinutes() +
        "_" +
        d.getSeconds() +
        "_" +
        makeid(5);
      const compressed_file = await resizeFile(file);
      const ref = storage.ref(
        `/${state_var}/${today_date}/${prefix}_${file.name}`
      );
      const uploadTask = ref.put(compressed_file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          /* Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded*/
          /* const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;*/
        },
        (error) => {
          switch (error.code) {
            case "storage/unauthorized":
              setCommonAlert({ open: true, msg: t("file_upload_failed") });
              break;
            case "storage/canceled":
              setCommonAlert({ open: true, msg: t("file_upload_failed") });
              break;
            case "storage/unknown":
              setCommonAlert({ open: true, msg: t("file_upload_failed") });
              break;
            default:
              setCommonAlert({ open: true, msg: t("file_upload_failed") });
              break;
          }
          switch (state_var) {
            case "registrationImage":
              setState({ ...state, registrationImage: "" });
              break;
            case "registrationBackImage":
              setState({ ...state, registrationBackImage: "" });
              break;
            case "licenseImage":
              setState({ ...state, licenseImage: "" });
              break;
            case "licenseBackImage":
              setState({ ...state, licenseBackImage: "" });
              break;
            case "idCardImage":
              setState({ ...state, idCardImage: "" });
              break;
            case "idCardBackImage":
              setState({ ...state, idCardBackImage: "" });
              break;
            case "userImage":
              setState({ ...state, userImage: "" });
              break;
            case "ropClearanceCertificate":
              setState({ ...state, ropClearanceCertificate: "" });
              break;
            case "bankAccoutScreenshot":
              setState({ ...state, bankAccoutScreenshot: "" });
              break;
            default:
              break;
          }
        },
        () => {
          ref.getDownloadURL().then((url) => {
            switch (state_var) {
              case "registrationImage":
                setState({ ...state, registrationImage: url });
                break;
              case "registrationBackImage":
                setState({ ...state, registrationBackImage: url });
                break;
              case "licenseImage":
                setState({ ...state, licenseImage: url });
                break;
              case "licenseBackImage":
                setState({ ...state, licenseBackImage: url });
                break;
              case "idCardImage":
                setState({ ...state, idCardImage: url });
                break;
              case "idCardBackImage":
                setState({ ...state, idCardBackImage: url });
                break;
              case "userImage":
                setState({ ...state, userImage: url });
                break;
              case "ropClearanceCertificate":
                setState({ ...state, ropClearanceCertificate: url });
                break;
              case "bankAccoutScreenshot":
                setState({ ...state, bankAccoutScreenshot: url });
                break;
              default:
                break;
            }
          });
        }
      );
    } else {
      setCommonAlert({ open: true, msg: t("file_upload_failed") });
    }
  };

  const removeUpload = (state_var) => {
    if (state_var) {
      setLoad(true);
      switch (state_var) {
        case "registrationImage":
          setState({ ...state, registrationImage: "" });
          break;
        case "bankAccoutScreenshot":
          setState({ ...state, bankAccoutScreenshot: "" });
          break;
        case "registrationBackImage":
          setState({ ...state, registrationBackImage: "" });
          break;
        case "licenseImage":
          setState({ ...state, licenseImage: "" });
          break;
        case "licenseBackImage":
          setState({ ...state, licenseBackImage: "" });
          break;
        case "idCardImage":
          setState({ ...state, idCardImage: "" });
          break;
        case "idCardBackImage":
          setState({ ...state, idCardBackImage: "" });
          break;
        case "userImage":
          setState({ ...state, userImage: "" });
          break;
        case "ropClearanceCertificate":
          setState({ ...state, ropClearanceCertificate: "" });
          break;
        default:
          break;
      }
      setLoad(false);
    }
  };
  const validateMobile = () => {
    let mobileValid = true;
    if (mobileWithoutCountry.length < 6) {
      mobileValid = false;
      setCommonAlert({ open: true, msg: t("mobile_no_blank_error") });
    } else if (mobileWithoutCountry.charAt(0) === "0") {
      mobileValid = false;
      setCommonAlert({ open: true, msg: t("no_zero_mobile_number") });
    } else if (
      mobileWithoutCountry.includes("+") ||
      mobileWithoutCountry.includes(" ") ||
      mobileWithoutCountry.includes("-")
    ) {
      mobileValid = false;
      setCommonAlert({ open: true, msg: t("mobile_no_special_characters") });
    } else if (mobileWithoutCountry.length !== 8) {
      mobileValid = false;
      setCommonAlert({ open: true, msg: t("mobile_no_valid_oman") });
    }
    return mobileValid;
  };
  const validateInputs = () => {
    //eslint-disable-next-line
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      state.firstName.length === 0 ||
      !(/\S/.test(state.firstName) && state.firstName.length > 0)
    ) {
      setCommonAlert({ open: true, msg: t("error_firstName") });
      return false;
    } else if (!/\S/.test(state.middleName) && state.middleName.length > 0) {
      setCommonAlert({ open: true, msg: t("error_middleName") });
      return false;
    } else if (!/\S/.test(state.thirdName) && state.thirdName.length > 0) {
      setCommonAlert({ open: true, msg: t("error_thirdName") });
      return false;
    } else if (!(/\S/.test(state.familyName) && state.familyName.length > 0)) {
      setCommonAlert({ open: true, msg: t("error_familyName") });
      return false;
    } else if (!re.test(state.email)) {
      setCommonAlert({ open: true, msg: t("proper_email") });
      return false;
    } else if (!validateMobile()) {
      return false;
    } else if (state.idCard === null || state.idCard.length === 0) {
      setCommonAlert({ open: true, msg: t("error_idCard") });
      return false;
    } else if (state.idCardExpiry === null || state.idCardExpiry.length === 0) {
      setCommonAlert({ open: true, msg: t("error_idCardExpiry") });
      return false;
    } else if (
      state.licenseNumber === null ||
      state.licenseNumber.length === 0
    ) {
      setCommonAlert({ open: true, msg: t("error_licenseNumber") });
      return false;
    } else if (
      state.licenseExpiry === null ||
      state.licenseExpiry.length === 0
    ) {
      setCommonAlert({ open: true, msg: t("error_licenseExpiry") });
      return false;
    } else if (state.carType === null || state.carType.length === 0) {
      setCommonAlert({ open: true, msg: t("error_carType") });
      return false;
    } else if (state.vehicleMake === null || state.vehicleMake.length === 0) {
      setCommonAlert({ open: true, msg: t("error_vehicleMake") });
      return false;
    } else if (state.vehicleModel === null || state.vehicleModel.length === 0) {
      setCommonAlert({ open: true, msg: t("error_vehicleModel") });
      return false;
    } else if (
      state.vehicleManufacture === null ||
      state.vehicleManufacture.length === 0
    ) {
      setCommonAlert({ open: true, msg: t("error_vehicleManufacture") });
      return false;
    } else if (
      state.vehicleNumber === null ||
      state.vehicleNumber.length === 0
    ) {
      setCommonAlert({ open: true, msg: t("error_vehicleNumber") });
      return false;
    } else if (
      state.vehicleExpiry === null ||
      state.vehicleExpiry.length === 0
    ) {
      setCommonAlert({ open: true, msg: t("error_vehicleExpiry") });
      return false;
    } else if (state.bankName === null || state.bankName.length === 0) {
      setCommonAlert({ open: true, msg: t("bank_name_cannot_be_blank") });
      return false;
    } else if (state.bankBranch === null || state.bankBranch.length === 0) {
      setCommonAlert({ open: true, msg: t("bank_branch_cannot_be_blank") });
      return false;
    } else if (
      state.bankAccountNo === null ||
      state.bankAccountNo.length === 0
    ) {
      setCommonAlert({ open: true, msg: t("bank_account_no_cannot_be_blank") });
      return false;
    }
    return true;
  };
  const validateUploads = () => {
    if (
      state.registrationImage === null ||
      state.registrationImage.length === 0
    ) {
      setCommonAlert({ open: true, msg: t("registrationImage_blank_message") });
      return false;
    } else if (
      state.bankAccoutScreenshot === null ||
      state.bankAccoutScreenshot.length === 0
    ) {
      setCommonAlert({
        open: true,
        msg: t("please_upload_bank_account_screenshot"),
      });
      return false;
    } else if (
      state.registrationBackImage === null ||
      state.registrationBackImage.length === 0
    ) {
      setCommonAlert({
        open: true,
        msg: t("registrationBackImage_blank_message"),
      });
      return false;
    } else if (state.licenseImage === null || state.licenseImage.length === 0) {
      setCommonAlert({ open: true, msg: t("licenseImage_blank_message") });
      return false;
    } else if (
      state.licenseBackImage === null ||
      state.licenseBackImage.length === 0
    ) {
      setCommonAlert({ open: true, msg: t("licenseBackImage_blank_message") });
      return false;
    } else if (state.idCardImage === null || state.idCardImage.length === 0) {
      setCommonAlert({ open: true, msg: t("idCardImage_blank_message") });
      return false;
    } else if (
      state.idCardBackImage === null ||
      state.idCardBackImage.length === 0
    ) {
      setCommonAlert({ open: true, msg: t("idCardBackImage_blank_message") });
      return false;
    } else if (state.userImage === null || state.userImage.length === 0) {
      setCommonAlert({ open: true, msg: t("userImage_blank_message") });
      return false;
    } else if (
      state.ropClearanceCertificate === null ||
      state.ropClearanceCertificate.length === 0
    ) {
      setCommonAlert({
        open: true,
        msg: t("ropClearanceCertificate_blank_message"),
      });
      return false;
    } else if (agreedTerms === false) {
      setCommonAlert({
        open: true,
        msg: t("Please Agree to the terms and Conditions"),
      });
      return false;
    }
    return true;
  };
  const requestOtp = (e) => {
    e.preventDefault();
    if (validateMobile()) {
      setLoad(true);
      const phoneNumber = state.mobile;
      const appVerifier = window.recaptchaVerifier;
      authRef
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((res) => {
          setverificationId(res);
          setLoad(false);
          setTimeout(() => {
            if (!verifiedOtp) setResendOtp(true);
          }, 30000);
        })
        .catch((error) => {
          setCommonAlert({ open: true, msg: error.message });
          setLoad(false);
        });
    }
  };
  const verifyOtp = (e) => {
    e.preventDefault();
    const otp = state.otp;
    setLoad(true);
    if (otp.length === 0) {
      setCommonAlert({ open: true, msg: "Please enter the otp." });
      setLoad(false);
    } else {
      const final = verificationId;
      if (final === null) {
        setCommonAlert({ open: true, msg: "Please try Again" });
        setLoad(false);
      } else {
        final
          .confirm(otp)
          .then((result) => {
            /*console.log(result);*/
            setVerifiedOtp(true);
            setCommonAlert({ open: true, msg: "OTP Verified Successfully." });
            setLoad(false);
          })
          .catch((error) => {
            setCommonAlert({ open: true, msg: "Invalid OTP" });
            setLoad(false);
            setResendOtp(true);
          });
      }
    }
  };
  const handleRegister = (e) => {
    e.preventDefault();
    //console.log(state);
    if (validateInputs()) {
      if (validateUploads()) {
        setLoad(true);
        checkUserExists(state).then((res) => {
          if (res.users && res.users.length > 0) {
            setCommonAlert({ open: true, msg: t("user_exists") });
            setLoad(false);
            setverificationId(false);
          } else if (res.error) {
            setCommonAlert({ open: true, msg: t("email_or_mobile_issue") });
            setLoad(false);
          } else {
            if (state.referralId && state.referralId.length > 0) {
              validateReferer(state.referralId)
                .then((referralInfo) => {
                  if (referralInfo.uid) {
                    mainSignUp({
                      ...state,
                      signupViaReferral: referralInfo.uid,
                    }).then((res) => {
                      if (res.uid) {
                        setCommonAlert({
                          open: true,
                          msg: t("account_create_successfully"),
                        });
                        setLoad(false);
                        // dispatch(mobileSignIn(verificationId.verificationId, state.otp));
                        dispatch(signOut());
                        setTimeout(() => {
                          props.history.push("/success");
                        }, 5000);
                      } else {
                        if (res.error.code === "auth/invalid-phone-number") {
                          setCommonAlert({
                            open: true,
                            msg: t("invalid_mobile_number"),
                          });
                          setverificationId(false);
                        } else {
                          setCommonAlert({ open: true, msg: t("reg_error") });
                        }
                        setLoad(false);
                      }
                    });
                  } else {
                    setCommonAlert({ open: true, msg: t("referer_not_found") });
                    setLoad(false);
                  }
                })
                .catch((error) => {
                  setCommonAlert({ open: true, msg: t("referer_not_found") });
                  setLoad(false);
                });
            } else {
              mainSignUp(state).then((res) => {
                if (res.uid) {
                  setCommonAlert({
                    open: true,
                    msg: t("account_create_successfully"),
                  });
                  setLoad(false);
                  // dispatch(mobileSignIn(verificationId.verificationId, state.otp));
                  dispatch(signOut());
                  setTimeout(() => {
                    props.history.push("/");
                  }, 5000);
                } else {
                  if (res.error.code === "auth/invalid-phone-number") {
                    setCommonAlert({
                      open: true,
                      msg: t("invalid_mobile_number"),
                    });
                    setverificationId(false);
                  } else if (
                    res.error.code === "auth/phone-number-already-exists"
                  ) {
                    setCommonAlert({ open: true, msg: res.error.message });
                    setverificationId(false);
                  } else {
                    setCommonAlert({ open: true, msg: t("reg_error") });
                  }
                  setLoad(false);
                }
              });
            }
          }
        });
      }
    }
  };
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
    if (auth.error.flag) {
      dispatch(clearLoginError());
    }
  };
  const handleBack = (e) => {
    e.preventDefault();
    props.history.push("/login");
  };
  const handleAgreeChange = (event) => {
    setAgreedTerms(event.target.checked);
  };
  const handleShowTerms = () => {
    setShowTerms(true);
  };
  const handleHideTerms = (event) => {
    setShowTerms(false);
  };
  function ShowButton(type) {
    if (type.type === "requestOtp") {
      return (
        <Button
          className={classNames(classes.normalButton, "bg-color")}
          simple
          color="primary"
          size="lg"
          onClick={requestOtp}
        >
          {t("requestOtp")}
        </Button>
      );
    } else if (type.type === "reRequestOtp") {
      return (
        <Button
          className={classNames(classes.normalButton, "bg-color")}
          simple
          color="primary"
          size="lg"
          onClick={requestOtp}
        >
          {t("requestOtp")}
        </Button>
      );
    } else if (type.type === "verifyOtp") {
      return (
        <Button
          className={classNames(classes.normalButton, "bg-color")}
          simple
          color="primary"
          size="lg"
          onClick={verifyOtp}
        >
          {t("verifyOtp")}
        </Button>
      );
    } else if (type.type === "register") {
      return (
        <Button
          className={classNames(classes.normalButton, "bg-color")}
          simple
          color="primary"
          size="lg"
          onClick={handleRegister}
        >
          {t("register_button")}
        </Button>
      );
    }
  }
  return (
    <div>
      <Header
        absolute
        color="transparent"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div id="sign-in-button" />
        {load === true ? <CircularLoading /> : null}
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card className={"registerForm"}>
                <form className={classes.form}>
                  <CardHeader className={"regFormHeader"}>
                    <h1 className={classes.title}>
                      {" "}
                      {t("register_driver_title")}{" "}
                    </h1>
                  </CardHeader>
                  <CardBody className={"regFormBody"}>
                    <div dir={isRTL}>
                      <GridContainer spacing={2}>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <CustomInput
                            labelText={t("firstName")}
                            id="firstName"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{ type: "text", required: true }}
                            onChange={onInputChange}
                            value={state.firstName}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <CustomInput
                            labelText={t("middleName")}
                            id="middleName"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{ type: "text", required: true }}
                            onChange={onInputChange}
                            value={state.middleName}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer spacing={2}>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <CustomInput
                            labelText={t("thirdName")}
                            id="thirdName"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{ type: "text", required: true }}
                            onChange={onInputChange}
                            value={state.thirdName}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <CustomInput
                            labelText={t("familyName")}
                            id="familyName"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{ type: "text", required: true }}
                            onChange={onInputChange}
                            value={state.familyName}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer spacing={2}>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <CustomInput
                            labelText={t("email")}
                            id="email"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{ type: "email", required: true }}
                            onChange={onInputChange}
                            value={state.email}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <CustomInput
                            labelText={t("mobile")}
                            id="mobile"
                            formControlProps={{
                              fullWidth: true,
                              className: "mobile-outer",
                            }}
                            inputProps={{
                              type: "tel",
                              required: true,
                              disabled: verifiedOtp ? true : false,
                            }}
                            onChange={(event) => {
                              setMobileWithoutCountry(event.target.value);
                              let formattedNum = event.target.value.replace(
                                / /g,
                                ""
                              );
                              formattedNum =
                                "+968" + formattedNum.replace(/-/g, "");
                              setState({ ...state, mobile: formattedNum });
                              setverificationId(null);
                              setVerifiedOtp(false);
                            }}
                            value={mobileWithoutCountry}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer spacing={2}>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <CustomInput
                            labelText={t("idCard")}
                            id="idCard"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{ type: "text", required: true }}
                            onChange={onInputChange}
                            value={state.idCard}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <CustomDateTime
                            labelText={t("idCardExpiry")}
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                            closeOnSelect={true}
                            onChange={onIdExpiryChange}
                            value={state.idCardExpiry}
                            id="idCardExpiry"
                            formControlProps={{
                              fullWidth: true,
                              className: "date-outer",
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer spacing={2}>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <CustomInput
                            labelText={t("licenseNumber")}
                            id="licenseNumber"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{ type: "text", required: true }}
                            onChange={onInputChange}
                            value={state.licenseNumber}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <CustomDateTime
                            labelText={t("licenseExpiry")}
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                            closeOnSelect={true}
                            onChange={onLicenseExpiryChange}
                            value={state.licenseExpiry}
                            id="licenseExpiry"
                            formControlProps={{
                              fullWidth: true,
                              className: "date-outer",
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer spacing={2}>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          {cartypes ? (
                            <FormControl fullWidth>
                              <InputLabel
                                id="car-type-select"
                                className={
                                  isRTL === "rtl" ? classes.rtlInputLabel : ""
                                }
                              >
                                {" "}
                                {t("carType")}{" "}
                              </InputLabel>
                              <Select
                                label={t("carType")}
                                labelId="car-type-select"
                                id="carType"
                                onChange={(e) => {
                                  setState({
                                    ...state,
                                    carType: e.target.value,
                                  });
                                }}
                                value={state.carType}
                              >
                                <MenuItem
                                  value={t("select_car")}
                                  key={t("select_car")}
                                  className={
                                    isRTL === "rtl" ? classes.rtlMenuItem : ""
                                  }
                                >
                                  <ListItemText
                                    className={
                                      isRTL === "rtl" ? classes.rtlMenuItem : ""
                                    }
                                    primary={t("select_car")}
                                    primaryTypographyProps={{
                                      align: isRTL === "rtl" ? "right" : "left",
                                    }}
                                  />
                                </MenuItem>
                                {cartypes.map((car) => (
                                  <MenuItem
                                    key={car.name}
                                    value={car.name}
                                    className={
                                      isRTL === "rtl" ? classes.rtlMenuItem : ""
                                    }
                                  >
                                    <img
                                      src={car.image}
                                      className={classes.carphoto}
                                      alt="car types"
                                    />{" "}
                                    <ListItemText
                                      className={
                                        isRTL === "rtl"
                                          ? classes.rtlMenuItem
                                          : ""
                                      }
                                      primary={car.desc}
                                      primaryTypographyProps={{
                                        align:
                                          isRTL === "rtl" ? "right" : "left",
                                      }}
                                    />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : null}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <CustomInput
                            labelText={t("vehicleMake")}
                            id="vehicleMake"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{ type: "text", required: true }}
                            onChange={onInputChange}
                            value={state.vehicleMake}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer spacing={2}>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <CustomInput
                            labelText={t("vehicleModel")}
                            id="vehicleModel"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{ type: "text", required: true }}
                            onChange={onInputChange}
                            value={state.vehicleModel}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <CustomInput
                            labelText={t("vehicleManufacture")}
                            id="vehicleManufacture"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{ type: "text", required: true }}
                            onChange={onInputChange}
                            value={state.vehicleManufacture}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer spacing={2}>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <CustomInput
                            labelText={t("vehicleNumber")}
                            id="vehicleNumber"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{ type: "text", required: true }}
                            onChange={onInputChange}
                            value={state.vehicleNumber}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <CustomDateTime
                            labelText={t("vehicleExpiry")}
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                            closeOnSelect={true}
                            onChange={onVehicleExpiryChange}
                            value={state.vehicleExpiry}
                            id="vehicleExpiry"
                            formControlProps={{
                              fullWidth: true,
                              className: "date-outer",
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer spacing={2}>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <CustomInput
                            labelText={t("bankName")}
                            id="bankName"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{ type: "text", required: true }}
                            onChange={onInputChange}
                            value={state.bankName}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <CustomInput
                            labelText={t("bankBranch")}
                            id="bankBranch"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{ type: "text", required: true }}
                            onChange={onInputChange}
                            value={state.bankBranch}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <CustomInput
                            labelText={t("bankAccountNo")}
                            id="bankAccountNo"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{ type: "text", required: true }}
                            onChange={onInputChange}
                            value={state.bankAccountNo}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer spacing={2}>
                        <GridItem xs={12}>
                          <InputLabel
                            id="bankAccoutScreenshot"
                            className={
                              isRTL === "rtl" ? classes.rtlMenuItem : ""
                            }
                          >
                            {" "}
                            {t("bankAccoutScreenshot")}{" "}
                          </InputLabel>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <FormControl
                            fullWidth
                            className={classNames("inputUpload")}
                          >
                            <input
                              id="bankAccoutScreenshotFileUpload"
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                handleUpload(
                                  e.target.files[0],
                                  "bankAccoutScreenshot"
                                );
                              }}
                            />
                            {state.bankAccoutScreenshot === "" ||
                            state.bankAccoutScreenshot === null ||
                            state.bankAccoutScreenshot === undefined ? (
                              <InputLabel htmlFor="bankAccoutScreenshotFileUpload">
                                <div className="selectImage">
                                  <i
                                    class="fa fa-camera"
                                    aria-hidden="true"
                                  ></i>
                                  <p>{t("frontside")}</p>
                                </div>
                              </InputLabel>
                            ) : (
                              <InputLabel>
                                <div className="previewImage">
                                  <span
                                    onClick={(e) => {
                                      removeUpload("bankAccoutScreenshot");
                                    }}
                                  >
                                    <i
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <img
                                    src={state.bankAccoutScreenshot}
                                    alt=""
                                  />
                                </div>
                              </InputLabel>
                            )}
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <br />
                      <br />
                      <GridContainer spacing={2}>
                        <GridItem xs={12}>
                          <InputLabel
                            id="vehicleRegistrationImage"
                            className={
                              isRTL === "rtl" ? classes.rtlMenuItem : ""
                            }
                          >
                            {" "}
                            {t("vehicleRegistrationImage")}{" "}
                          </InputLabel>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <FormControl
                            fullWidth
                            className={classNames("inputUpload")}
                          >
                            <input
                              id="fileUpload1"
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                handleUpload(
                                  e.target.files[0],
                                  "registrationImage"
                                );
                              }}
                            />
                            {state.registrationImage === "" ||
                            state.registrationImage === null ||
                            state.registrationImage === undefined ? (
                              <InputLabel htmlFor="fileUpload1">
                                <div className="selectImage">
                                  <i
                                    class="fa fa-camera"
                                    aria-hidden="true"
                                  ></i>
                                  <p>{t("frontside")}</p>
                                </div>
                              </InputLabel>
                            ) : (
                              <InputLabel>
                                <div className="previewImage">
                                  <span
                                    onClick={(e) => {
                                      removeUpload("registrationImage");
                                    }}
                                  >
                                    <i
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <img src={state.registrationImage} alt="" />
                                </div>
                              </InputLabel>
                            )}
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <FormControl
                            fullWidth
                            className={classNames("inputUpload")}
                          >
                            <input
                              id="fileUpload2"
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                handleUpload(
                                  e.target.files[0],
                                  "registrationBackImage"
                                );
                              }}
                            />
                            {state.registrationBackImage === "" ||
                            state.registrationBackImage === null ||
                            state.registrationBackImage === undefined ? (
                              <InputLabel htmlFor="fileUpload2">
                                <div className="selectImage">
                                  <i
                                    class="fa fa-camera"
                                    aria-hidden="true"
                                  ></i>
                                  <p>{t("backside")}</p>
                                </div>
                              </InputLabel>
                            ) : (
                              <InputLabel>
                                <div className="previewImage">
                                  <span
                                    onClick={(e) => {
                                      removeUpload("registrationBackImage");
                                    }}
                                  >
                                    <i
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <img
                                    src={state.registrationBackImage}
                                    alt=""
                                  />
                                </div>
                              </InputLabel>
                            )}
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <br />
                      <br />
                      <GridContainer spacing={2}>
                        <GridItem xs={12}>
                          <InputLabel
                            id="drivingLicenseImage"
                            className={
                              isRTL === "rtl" ? classes.rtlMenuItem : ""
                            }
                          >
                            {" "}
                            {t("drivingLicenseImage")}{" "}
                          </InputLabel>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <FormControl
                            fullWidth
                            className={classNames("inputUpload")}
                          >
                            <input
                              id="fileUpload3"
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                handleUpload(e.target.files[0], "licenseImage");
                              }}
                            />
                            {state.licenseImage === "" ||
                            state.licenseImage === null ||
                            state.licenseImage === undefined ? (
                              <InputLabel htmlFor="fileUpload3">
                                <div className="selectImage">
                                  <i
                                    class="fa fa-camera"
                                    aria-hidden="true"
                                  ></i>
                                  <p>{t("frontside")}</p>
                                </div>
                              </InputLabel>
                            ) : (
                              <InputLabel>
                                <div className="previewImage">
                                  <span
                                    onClick={(e) => {
                                      removeUpload("licenseImage");
                                    }}
                                  >
                                    <i
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <img src={state.licenseImage} alt="" />
                                </div>
                              </InputLabel>
                            )}
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <FormControl
                            fullWidth
                            className={classNames("inputUpload")}
                          >
                            <input
                              id="fileUpload4"
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                handleUpload(
                                  e.target.files[0],
                                  "licenseBackImage"
                                );
                              }}
                            />
                            {state.licenseBackImage === "" ||
                            state.licenseBackImage === null ||
                            state.licenseBackImage === undefined ? (
                              <InputLabel htmlFor="fileUpload4">
                                <div className="selectImage">
                                  <i
                                    class="fa fa-camera"
                                    aria-hidden="true"
                                  ></i>
                                  <p>{t("backside")}</p>
                                </div>
                              </InputLabel>
                            ) : (
                              <InputLabel>
                                <div className="previewImage">
                                  <span
                                    onClick={(e) => {
                                      removeUpload("licenseBackImage");
                                    }}
                                  >
                                    <i
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <img src={state.licenseBackImage} alt="" />
                                </div>
                              </InputLabel>
                            )}
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <br />
                      <br />
                      <GridContainer spacing={2}>
                        <GridItem xs={12}>
                          <InputLabel
                            id="idCardImage"
                            className={
                              isRTL === "rtl" ? classes.rtlMenuItem : ""
                            }
                          >
                            {" "}
                            {t("idCardImage")}{" "}
                          </InputLabel>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <FormControl
                            fullWidth
                            className={classNames("inputUpload")}
                          >
                            <input
                              id="fileUpload5"
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                handleUpload(e.target.files[0], "idCardImage");
                              }}
                            />
                            {state.idCardImage === "" ||
                            state.idCardImage === null ||
                            state.idCardImage === undefined ? (
                              <InputLabel htmlFor="fileUpload5">
                                <div className="selectImage">
                                  <i
                                    class="fa fa-camera"
                                    aria-hidden="true"
                                  ></i>
                                  <p>{t("frontside")}</p>
                                </div>
                              </InputLabel>
                            ) : (
                              <InputLabel>
                                <div className="previewImage">
                                  <span
                                    onClick={(e) => {
                                      removeUpload("idCardImage");
                                    }}
                                  >
                                    <i
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <img src={state.idCardImage} alt="" />
                                </div>
                              </InputLabel>
                            )}
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <FormControl
                            fullWidth
                            className={classNames("inputUpload")}
                          >
                            <input
                              id="fileUpload6"
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                handleUpload(
                                  e.target.files[0],
                                  "idCardBackImage"
                                );
                              }}
                            />
                            {state.idCardBackImage === "" ||
                            state.idCardBackImage === null ||
                            state.idCardBackImage === undefined ? (
                              <InputLabel htmlFor="fileUpload6">
                                <div className="selectImage">
                                  <i
                                    class="fa fa-camera"
                                    aria-hidden="true"
                                  ></i>
                                  <p>{t("backside")}</p>
                                </div>
                              </InputLabel>
                            ) : (
                              <InputLabel>
                                <div className="previewImage">
                                  <span
                                    onClick={(e) => {
                                      removeUpload("idCardBackImage");
                                    }}
                                  >
                                    <i
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <img src={state.idCardBackImage} alt="" />
                                </div>
                              </InputLabel>
                            )}
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer spacing={2}>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <InputLabel
                            id="userImage"
                            style={{ padding: 8 }}
                            className={
                              isRTL === "rtl" ? classes.rtlMenuItem : ""
                            }
                          >
                            {" "}
                            {t("userImage")}{" "}
                          </InputLabel>
                          <FormControl
                            fullWidth
                            className={classNames("inputUpload")}
                          >
                            <input
                              id="fileUpload7"
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                handleUpload(e.target.files[0], "userImage");
                              }}
                            />
                            {state.userImage === "" ||
                            state.userImage === null ||
                            state.userImage === undefined ? (
                              <InputLabel htmlFor="fileUpload7">
                                <div className="selectImage">
                                  <i
                                    class="fa fa-camera"
                                    aria-hidden="true"
                                  ></i>
                                  <p>{t("userImage")}</p>
                                </div>
                              </InputLabel>
                            ) : (
                              <InputLabel>
                                <div className="previewImage">
                                  <span
                                    onClick={(e) => {
                                      removeUpload("userImage");
                                    }}
                                  >
                                    <i
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <img src={state.userImage} alt="" />
                                </div>
                              </InputLabel>
                            )}
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <InputLabel
                            id="ropClearanceCertificate"
                            className={
                              isRTL === "rtl" ? classes.rtlMenuItem : ""
                            }
                            style={{ padding: 8 }}
                          >
                            {" "}
                            {t("ropClearanceCertificate")}{" "}
                          </InputLabel>
                          <FormControl
                            fullWidth
                            className={classNames("inputUpload")}
                          >
                            <input
                              id="fileUpload8"
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                handleUpload(
                                  e.target.files[0],
                                  "ropClearanceCertificate"
                                );
                              }}
                            />
                            {state.ropClearanceCertificate === "" ||
                            state.ropClearanceCertificate === null ||
                            state.ropClearanceCertificate === undefined ? (
                              <InputLabel htmlFor="fileUpload8">
                                <div className="selectImage">
                                  <i
                                    class="fa fa-camera"
                                    aria-hidden="true"
                                  ></i>
                                  <p>{t("ropClearanceCertificate")}</p>
                                </div>
                              </InputLabel>
                            ) : (
                              <InputLabel>
                                <div className="previewImage">
                                  <span
                                    onClick={(e) => {
                                      removeUpload("ropClearanceCertificate");
                                    }}
                                  >
                                    <i
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <img
                                    src={state.ropClearanceCertificate}
                                    alt=""
                                  />
                                </div>
                              </InputLabel>
                            )}
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer spacing={2}>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <CustomInput
                            labelText={t("referralId")}
                            id="referralId"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{ type: "text", required: true }}
                            onChange={onInputChange}
                            value={state.referralId}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          {verificationId ? (
                            <CustomInput
                              labelText={t("otp")}
                              id="otp"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                type: "number",
                                required: true,
                                disabled: verifiedOtp ? true : false,
                              }}
                              onChange={onInputChange}
                              value={state.otp}
                            />
                          ) : null}
                        </GridItem>
                      </GridContainer>
                      <GridContainer spacing={2}>
                        <GridItem xs={12} className={"termsBlock"}>
                          <FormControlLabel
                            value="agree"
                            control={
                              <Checkbox
                                checked={agreedTerms}
                                onChange={handleAgreeChange}
                                className={"agreeCheckbox"}
                              />
                            }
                            label={t("agree_to_terms")}
                            labelPlacement="end"
                          />
                          <Button
                            color="transparent"
                            className={"readMore"}
                            onClick={handleShowTerms}
                          >
                            {t("read_more")}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </CardBody>
                  <CardFooter
                    className={classNames(classes.cardFooter, "regFormFooter")}
                  >
                    {verificationId ? (
                      verifiedOtp ? (
                        <ShowButton type="register" />
                      ) : (
                        <div>
                          <ShowButton type="verifyOtp" />{" "}
                          {resendOtp ? (
                            <ShowButton type="reRequestOtp" />
                          ) : null}
                        </div>
                      )
                    ) : (
                      <ShowButton type="requestOtp" />
                    )}
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
        <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
          {commonAlert.msg}
        </AlertDialog>
      </div>
      {showTerms ? (
        <div className="popup">
          <div className="popup_inner">
            <h1
              style={{
                textAlign: isRTL === "rtl" ? "right" : "left",
                fontSize: "1.5rem",
                position: "relative",
                marginTop: "30px",
                minHeight: "32px",
                color: "#383838",
                textDecoration: "none",
              }}
            >
              {t("privacy_policy")}
            </h1>
            <button className={"closeButton"} onClick={handleHideTerms}>
              X
            </button>
            <section>
              {isRTL === "rtl" ? (
                <div
                  className={"description"}
                  style={{ textAlign: "right", direction: "rtl" }}
                >
                  {" "}
                  <PrivacyAr />{" "}
                </div>
              ) : (
                <div className={"description"} style={{ textAlign: "left" }}>
                  {" "}
                  <PrivacyEn />{" "}
                </div>
              )}
            </section>
          </div>
        </div>
      ) : null}
    </div>
  );
}
