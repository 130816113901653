import React, { useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Menu from "@material-ui/icons/Menu";
import styles from "assets/jss/material-kit-react/components/headerStyle.js";
import { useTranslation } from "react-i18next";
import { Select, MenuItem } from "@material-ui/core";
import { useSelector } from "react-redux";
import moment from "moment/min/moment-with-locales";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const { i18n } = useTranslation();
  const isRTL = i18n.dir();
  const languagedata = useSelector((state) => state.languagedata);
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [secondLogo, setSecondLogo] = React.useState(false);

  const [langSelection, setLangSelection] = useState();
  const [multiLanguage, setMultiLanguage] = useState();

  const handleLanguageSelect = (event) => {
    i18n.addResourceBundle(multiLanguage[event.target.value].langLocale, "translations", multiLanguage[event.target.value].keyValuePairs);
    i18n.changeLanguage(multiLanguage[event.target.value].langLocale);
    setLangSelection(event.target.value);
    moment.locale(multiLanguage[event.target.value].dateLocale);
    document.body.classList.remove('ltr');
    document.body.classList.remove('rtl');
    document.body.classList.add(i18n.dir());
  };

  useEffect(() => {
    if (languagedata.langlist) {
      for (const key of Object.keys(languagedata.langlist)) {
        if (languagedata.langlist[key].default) {
          setLangSelection(key);
        }
      }
      setMultiLanguage(languagedata.langlist);
    }
  }, [languagedata.langlist]);

  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body.getElementsByTagName("header")[0].classList.remove(classes[color]);
      document.body.getElementsByTagName("header")[0].classList.add(classes[changeColorOnScroll.color]);
      document.body.getElementsByTagName("header")[0].classList.add("NavbarFixed");
      setSecondLogo(true);
    } else {
      document.body.getElementsByTagName("header")[0].classList.add(classes[color]);
      document.body.getElementsByTagName("header")[0].classList.remove(classes[changeColorOnScroll.color]);
      document.body.getElementsByTagName("header")[0].classList.remove("NavbarFixed");
      setSecondLogo(false);
    }
  };
  const { color, rightLinks, leftLinks, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });
  const brandComponent = (
    <Button href='/' className={classes.title + (isRTL === "rtl" ? " logoRight" : "")}>
      {secondLogo ? <img src={require("../../assets/img/logo138x75black.png").default} alt='blackLogo' /> : <img src={require("../../assets/img/logo138x75white.png").default} alt='whiteLogo' />}
    </Button>
  );
  return (
    <AppBar className={classNames(appBarClasses, "NavbarMain")}>
      <Toolbar className={classes.container} style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}>
        {leftLinks !== undefined ? brandComponent : null}
        <div className={classes.flex}>
          {leftLinks !== undefined ? (
            <Hidden smDown implementation='css' style={{ flexDirection: "row" }}>
              {leftLinks}
            </Hidden>
          ) : (
            brandComponent
          )}
        </div>

        <div style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}>
          <Hidden smDown implementation='css'>
            {rightLinks}
          </Hidden>
        </div>
        <Hidden mdUp>
          {multiLanguage ? (
            <Select id='booking-type-native1' className={classes.input + " mobileLangSelector"} value={langSelection} onChange={handleLanguageSelect}>
              {Object.keys(multiLanguage).map((key) => (
                <MenuItem key={key} value={key}>
                  {multiLanguage[key].langName}
                </MenuItem>
              ))}
            </Select>
          ) : null}
          <IconButton color='inherit' aria-label='open drawer' onClick={handleDrawerToggle}>
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation='js'>
        <Drawer
          variant='temporary'
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}>
          <div className={classes.appResponsive + " mobileMenu"}>
            {leftLinks}
            {rightLinks}
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
}

Header.defaultProp = {
  color: "white",
};

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger", "transparent", "white", "rose", "dark"]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger", "transparent", "white", "rose", "dark", "header"]).isRequired,
  }),
};
