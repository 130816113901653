import React,{ useState,useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";

export default function DriverDistances() {

  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
    const columns =  [
        { title: t('booking_date'),field: 'tripdate', cellStyle:{paddingLeft: isRTL=== 'rtl'?40:null}},
        { title: t('driver_name'), field: 'driverName', cellStyle:{paddingRight: isRTL=== 'rtl'?'10%':null, textAlign:isRTL=== 'rtl'?'right': null}, headerStyle:{paddingRight: isRTL=== 'rtl'?'10%':null,textAlign:isRTL=== 'rtl'?'right': null}},
        { title: t('booking_count'), field: 'total_rides', cellStyle:{paddingLeft: isRTL=== 'rtl'?40:null}},
        { title: t('distance'), field: 'distance', cellStyle:{paddingLeft: isRTL=== 'rtl'?40:null}}
    ];

  const [data, setData] = useState([]);
  const driverdistancedata = useSelector(state => state.driverdistancedata);

  useEffect(()=>{
        if(driverdistancedata.totalDistance){
            setData(driverdistancedata.totalDistance);
        }
  },[driverdistancedata.totalDistance]);

  return (
    driverdistancedata.loading? <CircularLoading/>:
    <MaterialTable
      title={t('distance')}
      columns={columns}
      data={data}
      style={{direction:isRTL ==='rtl'?'rtl':'ltr'}}
      options={{
        exportButton: true,
        grouping: true,
      }}
      localization={{
        toolbar: {
          searchPlaceholder: (t('search')),
          exportTitle: (t('export')),
        },
        header: {
          actions: (t('actions'))
      },
      pagination: {
        labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
        labelRowsSelect: (t('rows')),
        firstTooltip: (t('first_page_tooltip')),
        previousTooltip: (t('previous_page_tooltip')),
        nextTooltip: (t('next_page_tooltip')),
        lastTooltip: (t('last_page_tooltip'))
      },
    }}
    />
  );
}
